import { Typography } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './styles.module.scss';
import apiClient from 'api';
import { type MfaResponse } from 'pages/auth/common/types';
import AuthPage from 'pages/auth/common/AuthPage';
import MfaForm from './MfaForm';

const Message = (props: { channel: string | null }) => {
    switch (props.channel) {
        case 'email':
            return <h3>We sent you an email.</h3>;
        case 'sms':
            return <h3>We sent you a text.</h3>;
    }
    return <h3>We sent you a one-time code.</h3>;
};

const MfaPage = () => {
    const navigate = useNavigate();
    const channel = localStorage.getItem('channel');

    function onLogin(data: MfaResponse) {
        apiClient.setToken(data.token.access_token);
        localStorage.removeItem('channel');
        navigate('/');
    }

    return (
        <AuthPage className={styles.page} title={'Get Verified'}>
            <Typography.Text>
                Please tell us your one-time code, along with your sign in password and click "Verify."
            </Typography.Text>
            <Message channel={channel} />
            <MfaForm onLogin={onLogin} />
            <div className="auth-help-text">
                Back to <a href="/login">Login?</a>
            </div>
        </AuthPage>
    );
};

export default MfaPage;
