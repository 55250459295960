import { useSelectFocusRef } from 'hooks/focus';
import React, { useEffect } from 'react';
import { Form, Select } from 'antd';

import type { MeetingType } from 'types';
import { selectOptions } from 'utils';
import { useMeetingTypes } from 'hooks/useMeetingTypes';

const MeetingTypeFormItem = (props: {
    meetingType?: MeetingType;
    setMeetingType: (meetingType: MeetingType) => void;
}) => {
    const ref = useSelectFocusRef();
    const { meetingType, setMeetingType } = props;
    const meetingTypes = useMeetingTypes();
    const options = selectOptions(meetingTypes);

    useEffect(() => {
        if (meetingTypes && meetingTypes.length === 1 && !meetingType) {
            setMeetingType(meetingTypes[0]);
        }
    }, [meetingType, setMeetingType, meetingTypes]);

    const onChange = (meetingTypeId: string) => {
        const meetingType = meetingTypes.find((meetingType) => meetingType.id === meetingTypeId);
        if (meetingType) {
            setMeetingType({ ...meetingType });
        }
    };

    return (
        <Form.Item className={'form-control'} label={'What type of meeting is this?'} colon={false} required>
            <Select
                ref={ref}
                placeholder="Please select"
                value={meetingType?.id}
                onChange={onChange}
                options={options}
                autoFocus={true}
            />
        </Form.Item>
    );
};

export default MeetingTypeFormItem;
