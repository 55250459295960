import { FieldType } from 'types';
import SettingsFieldIcon from './SettingsFieldIcon';
import styles from './styles.module.scss';

const SettingsFieldDisplay = (props: { type: FieldType }) => {
    return (
        <div className={styles.fieldDisplay}>
            <SettingsFieldIcon type={props.type} />
            {props.type}
        </div>
    );
};

export default SettingsFieldDisplay;
