import { useState } from 'react';
import { App } from 'antd';

import { Agreement, Team } from 'types';
import { AppDispatch, useAppDispatch } from 'store';

import { getDispatchError, hasDispatchError } from 'utils';

import { createAgreement } from 'features/agreementSlice';
import GeneralSteps from 'components/steps/common/GeneralSteps';

const CreateAgreementSteps = (props: {
    team?: Team;
    onSuccess: (agreement: Agreement) => void;
    onCancel: () => void;
}) => {
    const dispatch: AppDispatch = useAppDispatch();
    const { message } = App.useApp();

    const [agreement, setAgreement] = useState(() => {
        const agreement: Agreement = { type: 'agreement', status: 'active' } as Agreement;
        if (props.team) {
            agreement.team = { type: 'team', id: props.team.id };
        }
        return agreement;
    });
    const [loading, setLoading] = useState(false);

    async function onCreate() {
        setLoading(true);
        const newAgreement = {
            ...agreement,
        };
        if (props.team) {
            newAgreement.team = { id: props.team.id, type: 'team' };
        }

        const res = await dispatch(createAgreement(newAgreement as Agreement));
        setLoading(false);

        if (hasDispatchError(res)) {
            message.error(getDispatchError(res));
            return;
        }
        message.success('Your agreement was successfully created.');
        props.onSuccess(res.payload as Agreement);
    }

    /* Set team */
    const skip = props.team ? ['team'] : undefined;

    return (
        <GeneralSteps
            obj={agreement}
            setObj={setAgreement}
            onFinish={onCreate}
            onCancel={props.onCancel}
            loading={loading}
            skip={skip}
        />
    );
};

export default CreateAgreementSteps;
