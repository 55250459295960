import { Form, Input, type InputProps } from 'antd';
import { MailOutlined } from '@ant-design/icons';

const EmailFormItem = (props: { extra?: string; autoFocus?: InputProps['autoFocus'] }) => {
    return (
        <Form.Item
            name="email"
            rules={[
                {
                    required: true,
                    message: 'REQUIRED',
                },
            ]}
            extra={props.extra}
        >
            <Input prefix={<MailOutlined />} placeholder="Email" autoComplete={'email'} autoFocus={props.autoFocus} />
        </Form.Item>
    );
};

export default EmailFormItem;
