import type { MouseEvent, ReactNode } from 'react';
import type { GFDGNode } from './types';

const GroupedForceDirectedGraphNode = (props: {
    node: GFDGNode;
    onMouseMove?: (event: MouseEvent) => void;
    onClick?: (node: GFDGNode) => void;
    onContextMenu?: (node: GFDGNode, x: number, y: number) => void;
    onNodeContent?: (node: GFDGNode) => ReactNode;
}) => {
    const onClick = (event: MouseEvent) => {
        if (props.onClick) {
            event.preventDefault();
            event.stopPropagation();
            props.onClick(props.node);
        }
    };

    const onContextMenu = (event: MouseEvent) => {
        if (props.onContextMenu) {
            event.preventDefault();
            event.stopPropagation();
            props.onContextMenu?.(props.node, event.clientX, event.clientY);
        }
    };

    const onNodeContent = (node: GFDGNode) => {
        if (props.onNodeContent) {
            return props.onNodeContent(node);
        }
        return (
            <div
                style={{ height: `${node.height}px`, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                {props.node.id}
            </div>
        );
    };

    return props.node.width && props.node.height ? (
        <g
            style={{ cursor: props.onClick ? 'pointer' : 'default' }}
            onClick={onClick}
            onMouseMove={props.onMouseMove}
            onContextMenu={onContextMenu}
        >
            <foreignObject
                className={'node'}
                width={props.node.width}
                height={props.node.height}
                x={props.node.x - props.node.width / 2}
                y={props.node.y - props.node.height / 2}
            >
                {onNodeContent(props.node)}
            </foreignObject>
        </g>
    ) : null;
};

export default GroupedForceDirectedGraphNode;
