import CreateAgreementButton from 'components/buttons/CreateAgreementButton';
import React from 'react';
import { Link } from 'react-router-dom';

import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';

import { useAgreements } from 'hooks/useAgreements';
import { asDataSource } from 'utils';

const columns: ColumnsType = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text, { id, team }) => {
            return <Link to={`/teams/${team.id}#agreement:${id}`}>{text}</Link>;
        },
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
    /*
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: text => (
            <Tag color={statusColor(text)} key={'status'}>
                {text.toUpperCase()}
            </Tag>
        )
    }
     */
];

const AgreementsListPage = () => {
    const agreements = useAgreements();
    return (
        <>
            <Table columns={columns} dataSource={asDataSource(agreements)} pagination={false} />
            <CreateAgreementButton />
        </>
    );
};

export default AgreementsListPage;
