import type { Team } from 'types';
import { useDecisionCreatePermission } from 'hooks/permissions';
import CreateDecisionSteps from 'components/steps/CreateDecisionSteps';
import BaseCreateButton, { type CreateButtonType } from 'components/buttons/BaseCreateButton';
import Show from 'components/Show';

const CreateDecisionButton = (props: { team?: Team; type?: CreateButtonType }) => (
    <Show permission={useDecisionCreatePermission(props.team?.id)}>
        <BaseCreateButton text={'Create Decision'} type={props.type}>
            {(onSuccess, onCancel) => (
                <CreateDecisionSteps team={props.team} onSuccess={onSuccess} onCancel={onCancel} />
            )}
        </BaseCreateButton>
    </Show>
);

export default CreateDecisionButton;
