import ForceDirectedGraphLink from 'components/graph/fdg/ForceDirectedGraphLink';
import { asSource, asTarget } from 'components/graph/fdg/utils';
import ElementTooltip from 'components/tooltip/ElementTooltip';
import { forwardRef, PropsWithChildren, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { isDebug } from 'utils';

import { FDGData, FDGLink, FDGNode, FDGWorkerResponse } from './types';
import ForceDirectedGraphNode from './ForceDirectedGraphNode';

type FDGProps = PropsWithChildren<{
    width: number;
    height: number;
    data: { nodes: FDGNode[]; links: FDGLink[] };
    onClick?: (node: FDGNode) => void;
    onTooltipText?: (target: FDGNode | FDGLink) => ReactNode;
    onNodeContent?: (node: FDGNode) => ReactNode;
}>;

const RADIUS = 25;

const ForceDirectedGraph = forwardRef<HTMLDivElement, FDGProps>((props, ref) => {
    const svgRef = useRef<SVGSVGElement>(null);
    const [hover, setHover] = useState<{ target: FDGNode | FDGLink; element: Element } | null>(null);
    const [graphData, setGraphData] = useState<FDGData>();
    const isNode = !!(hover?.target as FDGNode)?.obj;

    const { nodes, links } = props.data;
    const width = 4 * props.width;
    const height = 4 * props.height;

    const simulator: Worker = useMemo(() => new Worker(new URL('./webworker.ts', import.meta.url)), []);

    /* Send the data to the webworker simulator */
    useEffect(() => {
        if (window.Worker) {
            const debug = isDebug('simulation');
            simulator.postMessage({ width, height, nodes, links, debug, avoidOverlaps: true });

            simulator.onmessage = (e: MessageEvent<FDGWorkerResponse>) => {
                if (!e.data.success) {
                    console.error('SIMULATION FAILED.');
                }
                if (debug || !e.data.success) {
                    console.log(
                        `simulation: elapsedTime=${e.data.elapsedTime}, tickCount=${e.data.tickCount}, alpha=${e.data.alpha.toFixed(4)}`,
                    );
                }
                setGraphData({ nodes: e.data.nodes, links: e.data.links });
            };
        }
    }, [simulator, width, height, nodes, links]);

    if (!graphData) return null;

    return (
        <div ref={ref}>
            <svg
                style={{ width: '100%', height: '100%' }}
                width={width}
                height={height}
                viewBox={`0 0 ${width} ${height}`}
                ref={svgRef}
                onMouseMove={() => setHover(null)}
            >
                <defs>
                    <marker
                        id="arrow"
                        orient="auto"
                        markerWidth="25"
                        markerHeight="16"
                        refY={8}
                        markerUnits="userSpaceOnUse"
                    >
                        <path d="M 0 0 L 25 8 L 0 16 Z" stroke="context-stroke" fill="context-stroke" />
                    </marker>
                    {/*
                    <marker id="dot" markerUnits="userSpaceOnUse">
                        <circle cx="3" cy="3" r="2" stroke="context-stroke" fill="context-stroke" />
                    </marker>
                    */}
                    <marker
                        id="dot"
                        viewBox="0 0 12 12"
                        refX="6"
                        refY="6"
                        markerWidth="12"
                        markerHeight="12"
                        markerUnits="userSpaceOnUse"
                    >
                        <circle cx="6" cy="6" r="6" fill="context-stroke" />
                    </marker>
                </defs>

                <g>
                    {graphData.links.map((link, index) => (
                        <ForceDirectedGraphLink key={index} link={link} radius={RADIUS} />
                    ))}
                </g>

                {/* Invisible overlay to make the link easier to click on. */}
                <g>
                    {graphData.links.map((link, index) => (
                        <line
                            key={index}
                            className={'link-overlay'}
                            x1={asSource(link).x}
                            y1={asSource(link).y}
                            x2={asTarget(link).x}
                            y2={asTarget(link).y}
                            stroke={'transparent'}
                            strokeWidth={16}
                            onMouseMove={(event) => {
                                event.stopPropagation();
                                const targetLink = hover?.target ? (hover.target as FDGLink) : undefined;
                                if (link?.source !== targetLink?.source && link?.target !== targetLink?.target) {
                                    setHover(event.target ? { target: link, element: event.target as Element } : null);
                                }
                            }}
                        />
                    ))}
                </g>

                <g>
                    {graphData.nodes.map((node, index) => (
                        <ForceDirectedGraphNode
                            key={index}
                            node={node}
                            radius={RADIUS}
                            onContent={props.onNodeContent}
                            onMouseMove={(event) => {
                                event.stopPropagation();
                                const targetId = hover?.target ? (hover.target as FDGNode).id : undefined;
                                if (node.obj?.id !== targetId) {
                                    setHover(event.target ? { target: node, element: event.target as Element } : null);
                                }
                            }}
                            onClick={props.onClick}
                        />
                    ))}
                </g>
                {props.children}
            </svg>
            {props.onTooltipText && hover && (
                <ElementTooltip element={hover.element} position={isNode ? 'top' : 'center'}>
                    {props.onTooltipText(hover.target)}
                </ElementTooltip>
            )}
        </div>
    );
});

export default ForceDirectedGraph;
