import React, { useState } from 'react';
import { Button, ButtonProps, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import type { Meeting, Team } from 'types';
import CreateEditModal from 'components/steps/common/CreateEditModal';
import CreateEditMeetingSteps from 'components/steps/meetings/CreateEditMeetingSteps';

const AddTeamMeetingButton = (props: {
    type?: ButtonProps['type'];
    shape?: ButtonProps['shape'];
    className?: string;
    team: Team;
    onCreate?: (meeting: Meeting) => void;
}) => {
    const [openModal, setOpenModal] = useState(false);
    const type = props.type ? props.type : 'link';

    const onCancel = () => setOpenModal(false);
    const onCreate = (meeting: Meeting) => {
        props.onCreate?.(meeting);
        setOpenModal(false);
    };

    return (
        <>
            <Tooltip title={'Add Meeting'}>
                <Button
                    className={props.className}
                    type={type}
                    shape={props.shape}
                    icon={<PlusOutlined />}
                    size={'small'}
                    onClick={() => setOpenModal(true)}
                />
            </Tooltip>
            <CreateEditModal title={'Create a team meeting'} open={openModal} onCancel={onCancel}>
                <CreateEditMeetingSteps team={props.team} onSuccess={onCreate} onCancel={onCancel} />
            </CreateEditModal>
        </>
    );
};

export default AddTeamMeetingButton;
