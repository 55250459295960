import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { Person, SliceStatus, Organization, ErrorDetail } from 'types';
import { CLEAR_ORG_ACTION, LOGOUT_ACTION, RootState } from 'store';
import apiClient from 'api';
import { getThunkRejectValue } from 'utils';

type PeopleState = {
    entities: Person[] | undefined;
    status: SliceStatus;
    error: string;
};

export const fetchPeople = createAsyncThunk<Person[]>('people/fetch', async (_, { getState }) => {
    const state = getState() as { org: { value: Organization } };
    return await apiClient.get(`/org/${state.org.value.id}/people`);
});

export const deletePerson = createAsyncThunk<
    Person,
    string,
    {
        rejectValue: ErrorDetail;
    }
>('person/delete', async (userId, thunkAPI) => {
    const state = thunkAPI.getState() as { org: { value: Organization } };
    try {
        return await apiClient.delete<Person>(`/org/${state.org.value.id}/people/${userId}`);
    } catch (e) {
        return thunkAPI.rejectWithValue(await getThunkRejectValue(e));
    }
});

export const createPerson = createAsyncThunk<
    Person,
    Person,
    {
        rejectValue: ErrorDetail;
    }
>('person/create', async (person, thunkAPI) => {
    const state = thunkAPI.getState() as { org: { value: Organization } };
    try {
        return await apiClient.post(`/org/${state.org.value.id}/people`, person);
    } catch (e) {
        return thunkAPI.rejectWithValue(e as ErrorDetail);
    }
});

type UpdatePerson = Partial<Person> & { id: string };
export const updatePerson = createAsyncThunk<
    Person,
    UpdatePerson,
    {
        rejectValue: ErrorDetail;
    }
>('person/update', async (data, thunkAPI) => {
    const state = thunkAPI.getState() as { org: { value: Organization } };
    try {
        return await apiClient.patch(`/org/${state.org.value.id}/people/${data.id}`, data);
    } catch (e) {
        return thunkAPI.rejectWithValue(e as ErrorDetail);
    }
});

const INITIAL_STATE = {
    entities: undefined,
    status: 'idle' as PeopleState['status'],
    error: '',
};

// noinspection JSUnusedGlobalSymbols
const personSlice = createSlice({
    name: 'people',
    initialState: INITIAL_STATE satisfies PeopleState as PeopleState,
    reducers: {
        clearError: (state) => {
            state.error = '';
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchPeople.pending, (state: PeopleState) => {
                state.status = 'pending';
            })
            .addCase(fetchPeople.fulfilled, (state: PeopleState, action: PayloadAction<Person[]>) => {
                state.status = 'succeeded';
                state.entities = action.payload as Person[];
            })
            .addCase(fetchPeople.rejected, (state: PeopleState, action) => {
                state.status = 'failed';
                //state.error = action.error.message;
                console.error(action);
            })
            .addCase(createPerson.fulfilled, (state: PeopleState, action: PayloadAction<Person | ErrorDetail>) => {
                state.entities = state.entities ? state.entities : [];
                state.entities.push(action.payload as Person);
            })
            .addCase(deletePerson.fulfilled, (state: PeopleState, action) => {
                state.entities = state.entities ? state.entities : [];
                state.entities = state.entities.filter((user: Person) => user.id !== action.payload.id);
            })
            .addCase(updatePerson.fulfilled, (state: PeopleState, action: PayloadAction<Person | ErrorDetail>) => {
                const person = action.payload as Person;
                state.entities = state.entities ? state.entities : [];

                const index = state.entities.findIndex((p) => p.id === person.id);
                state.entities[index] = person;
            })
            .addCase(CLEAR_ORG_ACTION, (state: PeopleState) => {
                Object.assign(state, INITIAL_STATE);
            })
            .addCase(LOGOUT_ACTION, (state: PeopleState) => {
                Object.assign(state, INITIAL_STATE);
            });
    },
});

export const selectPeople = (state: RootState) => state.people.entities;
export const selectPeopleStatus = (state: RootState) => state.people.status;

export default personSlice.reducer;
