import { CSSProperties } from 'react';

const TextStar = (props: {
    x: number;
    y: number;
    fill?: string;
    fontSize?: CSSProperties['fontSize'];
    fontStyle?: CSSProperties['fontStyle'];
}) => {
    const fontSize = props.fontSize ? props.fontSize : 40;
    return (
        <text
            x={props.x}
            y={props.y}
            fill={props.fill}
            fontSize={fontSize}
            fontStyle={props.fontStyle}
            textAnchor="middle"
            dominantBaseline="central"
        >
            ★
        </text>
    );
};

export default TextStar;
