import React, { useState } from 'react';
import { Typography } from 'antd';

import AuthPage from 'pages/auth/common/AuthPage';

import styles from './styles.module.scss';
import ForgotPasswordForm from './ForgotPasswordForm';

const ForgotPasswordPage = () => {
    const [email, setEmail] = useState('');

    function onFinish(email: string) {
        setEmail(email);
    }

    if (email) {
        return (
            <AuthPage className={styles.page} title={'Reset Email Sent'}>
                <Typography.Text className="reset-link">
                    Please check <span>{email}</span> for a message containing a reset link.
                </Typography.Text>
            </AuthPage>
        );
    }

    return (
        <AuthPage className={styles.page} title={'Change Password'}>
            <ForgotPasswordForm onFinish={onFinish} />
            <div className="auth-help-text">
                Back to <a href="/login">Login?</a>
            </div>
        </AuthPage>
    );
};

export default ForgotPasswordPage;
