import { ObjectLabel } from 'components/forms/common/labels';
import type { BaseObject } from 'types';
import { isDefined } from 'utils';

function getOptGroup(name: string) {
    switch (name) {
        case 'person':
            return 'people';
        case 'orgUnit':
            return 'org units';
    }
    return name + 's';
}

function getProp(name: string) {
    switch (name) {
        case 'orgUnit':
            return 'org_unit';
    }
    return name;
}

export function buildObjectOptions(objs: { [p: string]: BaseObject[] }, excluded: string[] = []) {
    const options: any[] = [];
    for (const prop in objs) {
        if (Object.prototype.hasOwnProperty.call(objs, prop)) {
            if (isDefined(objs[prop])) {
                const objOptions: any[] = [];
                objs[prop].forEach((obj) => {
                    if (!excluded.includes(obj.id)) {
                        objOptions.push({
                            label: <ObjectLabel obj={obj} />,
                            value: JSON.stringify({ id: obj.id, type: getProp(prop) }),
                        });
                    }
                });

                const optGroup = getOptGroup(prop);
                options.push({
                    label: optGroup,
                    title: optGroup,
                    options: objOptions,
                });
            }
        }
    }
    return options;
}
