import { Col } from 'antd';

import type { Decision, Team } from 'types';

import { useTeamDecisions } from 'hooks/useDecisions';

import DecisionCard from './DecisionCard';
import CreateDecisionButton from 'components/buttons/CreateDecisionButton';
import Tab from './Tab';

const DecisionsTab = (props: { team: Team; onClick?: (decision: Decision) => void }) => {
    const decisions = useTeamDecisions(props.team);
    return (
        <Tab extra={<CreateDecisionButton team={props.team} type={'icon'} />} empty={decisions.length === 0}>
            {decisions.map((decision) => (
                <Col span={12} key={decision.id}>
                    <DecisionCard decision={decision} onClick={() => props.onClick?.(decision)} />
                </Col>
            ))}
        </Tab>
    );
};

export default DecisionsTab;
