import * as d3 from 'd3';

import { isA } from 'utils';
import { type Datum } from 'pages/orgUnitTree/gtree/types';

const pathGenerator = (link: d3.HierarchyLink<Datum>): string => {
    return (
        d3
            .link(d3.curveBumpY)
            .x((d) => (d as any).x)
            .y((d) => (d as any).y)(link as any) || ''
    );
};

const GTreeLink = (props: { d: d3.HierarchyLink<Datum>; width: number; height: number }) => {
    const link = { source: { ...props.d.source }, target: { ...props.d.target } } as d3.HierarchyLink<Datum>;

    link.source.x = isA<number>(link.source.x) ? link.source.x : 0;
    link.source.y = isA<number>(link.source.y) ? link.source.y + props.height - 1 : 0;
    link.target.x = isA<number>(link.target.x) ? link.target.x : 0;
    link.target.y = isA<number>(link.target.y) ? link.target.y : 0;

    return <path fill={'none'} stroke={'#999'} strokeWidth={3} d={pathGenerator(link)} />;
};

export default GTreeLink;
