import { useNavigate } from 'react-router-dom';
import { App } from 'antd';

import type { CreateEditTemplate, ErrorDetail, ErrorDetailDispatch, Template } from 'types';
import { AppDispatch, useAppDispatch } from 'store';

import { createTemplate, updateTemplate } from 'features/templateSlice';
import SettingsCreateEditPage from 'pages/settings/common/SettingsCreateEditPage';
import TemplateForm from './edit/TemplateForm';
import { omit } from 'utils';

const CreateEditTemplatePage = (props: { template?: Template }) => {
    const navigate = useNavigate();
    const { message } = App.useApp();

    const dispatch: AppDispatch = useAppDispatch();
    const isEdit = !!props.template;

    function back() {
        navigate('/settings#templates');
    }

    async function onFinish(template: CreateEditTemplate) {
        const dispatchFunction = isEdit ? updateTemplate : createTemplate;

        template.template_fields.map((templateField, index) => {
            omit(templateField, 'key');
            templateField.order_by = index + 1;
            return templateField;
        });
        const res = await dispatch(dispatchFunction(template));
        if ((res as ErrorDetailDispatch<Template>).error) {
            message.error((res.payload as ErrorDetail).detail.toString());
            return;
        }
        back();
    }

    return (
        <SettingsCreateEditPage
            description={'A template allows you to define the information collected for objects in this organization.'}
        >
            <TemplateForm template={props.template} onFinish={onFinish} onCancel={back} />
        </SettingsCreateEditPage>
    );
};

export default CreateEditTemplatePage;
