import { useState } from 'react';
import { Drawer } from 'antd';

import type { BaseObject, ObjectType, TeamRef, TemplatedObject } from 'types';

import styles from './styles.module.scss';

import Show from 'components/Show';
import { useObjects } from 'hooks/useObjects';

import ObjectDrawerContents from './ObjectDrawerContents';
import ObjectDrawerButtons from './ObjectDrawerButtons';

function teamId(obj: BaseObject | undefined): string | undefined {
    if (obj) {
        switch (obj.type) {
            case 'team':
                return obj.id;
            case 'agreement':
            case 'decision':
            case 'objective':
            case 'risk':
            case 'role':
                return (obj as BaseObject & { team: TeamRef }).id;
        }
    }
    return undefined;
}

const ObjectDrawer = (props: { objectId: string; objectType: ObjectType; onClose: () => void }) => {
    const [edit, setEdit] = useState(false);

    const objects = useObjects([props.objectType]);
    const obj = objects[props.objectType].find((obj) => obj.id === props.objectId);

    const onClose = () => {
        setEdit(false);
        props?.onClose();
    };

    return (
        <Show permission={!!obj}>
            <Drawer
                onClick={(event) => event.stopPropagation()}
                className={styles.drawer}
                title={obj?.name}
                onClose={onClose}
                open={true}
                closable={false}
                extra={
                    <ObjectDrawerButtons
                        objectType={props.objectType}
                        teamId={teamId(obj)}
                        edit={edit}
                        setEdit={setEdit}
                        onClose={onClose}
                    />
                }
                destroyOnClose
            >
                <ObjectDrawerContents edit={edit} setEdit={setEdit} onClose={onClose} obj={obj as TemplatedObject} />
            </Drawer>
        </Show>
    );
};

export default ObjectDrawer;
