import type { FDGNode, FDGLink } from 'components/graph/fdg/types';
import TeamNodeTooltipContent from 'pages/teams/graph/tooltips/TeamNodeTooltipContent';

const TeamTooltipContent = (props: { target: FDGNode | FDGLink }) => {
    const isNode = !!(props.target as FDGNode).obj;
    return isNode ? (
        <TeamNodeTooltipContent obj={(props.target as FDGNode).obj} />
    ) : (
        <>{(props.target as FDGLink).label}</>
    );
};

export default TeamTooltipContent;
