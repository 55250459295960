import SettingsAction from 'pages/settings/common/SettingsAction';
import SettingsFieldDisplay from 'pages/settings/common/SettingsFieldDisplay';
import React, { useState } from 'react';

import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { Table } from 'antd';
import type { TableColumnsType } from 'antd';

import type {
    CreateEditTemplateCustomField,
    CreateEditTemplateField,
    CreateEditTemplateNativeField,
    Keyed,
} from 'types';
import { useFields } from 'hooks/fields';
import { fieldLabel, isCreateEditTemplateCustomField, isCreateEditTemplateNativeFieldRelationship, map } from 'utils';

import styles from './styles.module.scss';
import CreateTemplateFieldButton from './CreateTemplateFieldButton';
import CreateEditTemplateFieldModal from './CreateEditTemplateFieldModal';

type DataType = Keyed<CreateEditTemplateField>;

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
    'data-row-key': string;
}

const Row: React.FC<Readonly<RowProps>> = (props) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
        id: props['data-row-key'],
    });

    const style: React.CSSProperties = {
        ...props.style,
        transform: CSS.Translate.toString(transform),
        transition,
        cursor: 'move',
        ...(isDragging ? { position: 'relative' } : {}),
    };

    return <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
};

const templateFieldKey = (templateField: CreateEditTemplateField) => {
    if (templateField.id) {
        return templateField.id;
    }
    if ((templateField as CreateEditTemplateCustomField).field_id) {
        return (templateField as CreateEditTemplateCustomField).field_id;
    }
    return (templateField as CreateEditTemplateNativeField).native_field.id;
};

function toDataSource(templateFields: CreateEditTemplateField[]): DataType[] {
    return templateFields.map((templateField) => {
        (templateField as DataType).key = templateFieldKey(templateField);
        return templateField as DataType;
    });
}

const TemplateFieldsTable = (props: {
    templateFields: CreateEditTemplateField[];
    setTemplateFields: (templateFields: CreateEditTemplateField[]) => void;
}) => {
    const dataSource = toDataSource(props.templateFields);
    const fields = map(useFields());
    const [editKey, setEditKey] = useState('');
    const editIndex = editKey
        ? props.templateFields.findIndex((templateField) => {
              return templateFieldKey(templateField) === editKey;
          })
        : -1;

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                // https://docs.dndkit.com/api-documentation/sensors/pointer#activation-constraints
                distance: 1,
            },
        }),
    );

    const columns: TableColumnsType<DataType> = [
        {
            title: 'Name',
            dataIndex: 'name',
            render: (_, obj) => {
                if (isCreateEditTemplateCustomField(obj)) {
                    const field = fields[(obj as CreateEditTemplateCustomField).field_id];
                    return (
                        <span className={'text-nowrap'}>
                            {field.name}
                            {obj.required ? (
                                <span className={'text-danger'} aria-required={'true'}>
                                    {' '}
                                    *
                                </span>
                            ) : null}
                        </span>
                    );
                } else {
                    const nativeTemplateField = obj as CreateEditTemplateNativeField;
                    return (
                        <span className={'text-nowrap'}>
                            {fieldLabel(nativeTemplateField.native_field.name)}
                            {obj.required ? (
                                <span className={'text-danger'} aria-required={'true'}>
                                    {' '}
                                    *
                                </span>
                            ) : null}
                        </span>
                    );
                }
            },
        },
        {
            title: 'Type',
            dataIndex: 'type',
            render: (_, obj) => {
                if (isCreateEditTemplateCustomField(obj)) {
                    const field = fields[(obj as CreateEditTemplateCustomField).field_id];
                    //return <div className={`t-icon ${field.type}`}>{field.type}</div>;
                    return <SettingsFieldDisplay type={field.type} />;
                } else {
                    return <div>system</div>;
                }
            },
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            render: (_, obj) => {
                if (isCreateEditTemplateCustomField(obj)) {
                    return (
                        <span className={'actions text-nowrap'}>
                            <SettingsAction type={'success'} onClick={() => onEdit(obj.key)}>
                                Edit
                            </SettingsAction>
                            <SettingsAction type={'danger'} onClick={() => onDelete(obj.key)}>
                                Delete
                            </SettingsAction>
                        </span>
                    );
                }
                if (
                    isCreateEditTemplateNativeFieldRelationship(obj) &&
                    obj.native_field.name.toLowerCase() !== 'accountable_for'
                ) {
                    return (
                        <span className={'actions text-nowrap'}>
                            <SettingsAction type={'success'} onClick={() => onEdit(obj.key)}>
                                Edit
                            </SettingsAction>
                        </span>
                    );
                }
                return null;
            },
        },
    ];

    const onDragEnd = ({ active, over }: DragEndEvent) => {
        if (active.id !== over?.id) {
            const activeIndex = dataSource.findIndex((i: DataType) => i.key === active.id);
            const overIndex = dataSource.findIndex((i: DataType) => i.key === over?.id);
            props.setTemplateFields(arrayMove(dataSource, activeIndex, overIndex));
        }
    };

    const onEdit = (key: string) => setEditKey(key);
    const onCancel = () => setEditKey('');

    const onSave = (templateField: CreateEditTemplateField) => {
        const templateFields = [...props.templateFields];
        templateFields[editIndex] = templateField;
        props.setTemplateFields(templateFields);
        setEditKey('');
    };

    const onDelete = (key: string) => {
        const templateFields = dataSource.filter((field: DataType) => field.key !== key);
        props.setTemplateFields(templateFields);
    };

    function onFinish(templateField: CreateEditTemplateField) {
        const newFields = [...props.templateFields, templateField];
        props.setTemplateFields(newFields);
    }

    const excludedFieldIds = props.templateFields
        .filter((templateField) => (templateField as CreateEditTemplateCustomField).field_id)
        .map((templateField) => (templateField as CreateEditTemplateCustomField).field_id);
    return (
        <DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
            <SortableContext
                // rowKey array
                items={dataSource.map((i) => i.key)}
                strategy={verticalListSortingStrategy}
            >
                <Table<DataType>
                    className={styles.table}
                    components={{
                        body: { row: Row },
                    }}
                    rowKey="key"
                    columns={columns}
                    dataSource={dataSource}
                    pagination={false}
                    showHeader={false}
                />
                <div className={'gg-small'}>Note: The 'name' attribute is always be collected and shown first.</div>
                <CreateTemplateFieldButton onFinish={onFinish} excludedFieldIds={excludedFieldIds} />
                <CreateEditTemplateFieldModal
                    open={!!editKey}
                    templateField={props.templateFields[editIndex] as CreateEditTemplateCustomField}
                    onSave={onSave}
                    onCancel={onCancel}
                    excludedFieldIds={excludedFieldIds}
                />
            </SortableContext>
        </DndContext>
    );
};

export default TemplateFieldsTable;
