import { useState } from 'react';
import { App } from 'antd';

import { Objective, Team } from 'types';
import { AppDispatch, useAppDispatch } from 'store';

import { createObjective } from 'features/objectiveSlice';
import { getDispatchError, hasDispatchError } from 'utils';
import GeneralSteps from 'components/steps/common/GeneralSteps';

const CreateObjectiveSteps = (props: {
    team?: Team;
    onSuccess: (objective: Objective) => void;
    onCancel: () => void;
}) => {
    const { message } = App.useApp();
    const dispatch: AppDispatch = useAppDispatch();

    const [objective, setObjective] = useState(() => {
        const objective: Objective = { type: 'objective', status: 'active' } as Objective;
        if (props.team) {
            objective.team = { type: 'team', id: props.team.id };
        }
        return objective;
    });
    const [loading, setLoading] = useState(false);

    async function onCreate() {
        setLoading(true);
        const newObjective = {
            ...objective,
        };
        if (props.team) {
            newObjective.team = { id: props.team.id, type: 'team' };
        }

        const res = await dispatch(createObjective(newObjective as Objective));
        setLoading(false);

        if (hasDispatchError(res)) {
            message.error(getDispatchError(res));
            return;
        }

        message.success('Your objective was successfully created.');
        props.onSuccess(res.payload as Objective);
    }

    /* Set team */
    const skip = props.team ? ['team'] : undefined;

    return (
        <GeneralSteps
            obj={objective}
            setObj={setObjective}
            onFinish={onCreate}
            onCancel={props.onCancel}
            loading={loading}
            skip={skip}
        />
    );
};

export default CreateObjectiveSteps;
