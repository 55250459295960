import { type FDGNode } from 'components/graph/fdg/types';

export function adjustLinkPosition(x1: number, y1: number, x2: number, y2: number, r: number) {
    const dx = x2 - x1;
    const dy = y2 - y1;
    const dist = Math.sqrt(dx * dx + dy * dy);
    const offsetX = (dx / dist) * r;
    const offsetY = (dy / dist) * r;
    return {
        x1: x1 + offsetX,
        y1: y1 + offsetY,
        x2: x2 - offsetX,
        y2: y2 - offsetY,
    };
}

export function adjustLink(
    d: { source: FDGNode | string | number; target: FDGNode | string | number },
    r: number,
    markerSize: number,
) {
    r += markerSize;
    const source = asSource(d);
    const target = asTarget(d);
    return adjustLinkPosition(source.x, source.y, target.x, target.y, r);
}

export function asSource(d: { source: FDGNode | string | number }) {
    const source = d.source as FDGNode;
    return { x: source.x as number, y: source.y as number };
}

export function asTarget(d: { target: FDGNode | string | number }) {
    const target = d.target as FDGNode;
    return { x: target.x as number, y: target.y as number };
}
