import { useMemo } from 'react';
import * as d3 from 'd3';

import styles from './styles.module.scss';

type DataItem = {
    name?: string;
    value: number;
};

const DonutChart = (props: { size: number; fontSize?: string; data: DataItem[]; text?: string }) => {
    const radius = (props.size / 2) * 0.75;
    const innerRadius = radius * 0.8;
    const data = props.data;
    const fontSize = props.fontSize ? props.fontSize : `${radius}px`;

    const pie = useMemo(() => {
        const pieGenerator = d3.pie<any, DataItem>().value((d) => d.value);
        return pieGenerator(data);
    }, [data]);

    const arcs = useMemo(() => {
        const arcPathGenerator = d3.arc();
        return pie.map((p) =>
            arcPathGenerator({
                innerRadius: innerRadius,
                outerRadius: radius,
                startAngle: p.startAngle,
                endAngle: p.endAngle,
            }),
        );
    }, [radius, innerRadius, pie]);

    const colors = [styles.primary, 'rgba(0, 0, 0, 0.25)'];

    return (
        <svg width={props.size} height={props.size} style={{ display: 'inline-block' }}>
            <g transform={`translate(${props.size / 2}, ${props.size / 2})`}>
                {arcs.map((arc, i) => {
                    return <path key={i} d={arc || undefined} fill={colors[i]} />;
                })}
            </g>
            {!!props.text && (
                <text
                    x={'50%'}
                    y={props.size / 2 + 1}
                    fontSize={fontSize}
                    dominantBaseline="middle"
                    textAnchor="middle"
                >
                    {props.text}
                </text>
            )}
        </svg>
    );
};

export default DonutChart;
