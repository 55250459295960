import { Form, Select } from 'antd';
import { buildObjectOptions } from 'components/forms/common/options';
import { useSelectFocusRef } from 'hooks/focus';
import type { ObjectRef, OneToManyField, TemplateField } from 'types';
import { fieldLabel, getField } from 'utils';
import { useObjects } from 'hooks/useObjects';
import { filterOption } from './utils';

const OneToManyFormItem = (props: {
    templateField: TemplateField;
    objectRefs: ObjectRef[] | undefined;
    setObjectRefs: (refs: ObjectRef[]) => void;
    autoFocus?: boolean;
    excluded?: string[];
}) => {
    const ref = useSelectFocusRef(props.autoFocus);
    const field = getField(props.templateField) as OneToManyField;
    const objs = useObjects(field.config.allowed);

    const options = buildObjectOptions(objs, props.excluded ? props.excluded : []);
    const values = props.objectRefs
        ? props.objectRefs.map((objectRef) => JSON.stringify({ id: objectRef.id, type: objectRef.type }))
        : undefined;

    return (
        <Form.Item
            className={'form-control'}
            label={fieldLabel(field.name)}
            rules={[{ required: props.templateField.required, message: 'Select one or more values.' }]}
            name={field.name}
            initialValue={values}
        >
            <Select
                ref={ref}
                mode={'multiple'}
                options={options}
                onChange={(values) => {
                    props.setObjectRefs(values.map((value: string) => JSON.parse(value)));
                }}
                filterOption={filterOption}
                autoFocus={props.autoFocus}
            />
        </Form.Item>
    );
};

export default OneToManyFormItem;
