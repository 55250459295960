import { Button, Modal, theme } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { capitalCase } from 'change-case';

import { type ObjectType } from 'types';

const BaseDeleteButton = (props: { objectType: ObjectType; onConfirm?: () => void }) => {
    const capitalizedObjectType = capitalCase(props.objectType);

    const {
        token: { colorError },
    } = theme.useToken();

    return (
        <Button
            danger
            onClick={() => {
                Modal.confirm({
                    title: `Confirm deletion of ${props.objectType}`,
                    content: `Are you sure you want to permanently delete this ${props.objectType}?  This operation cannot be undone.`,
                    footer: (_, { OkBtn, CancelBtn }) => (
                        <>
                            <CancelBtn />
                            <OkBtn />
                        </>
                    ),
                    okText: `Delete ${capitalizedObjectType}`,
                    okButtonProps: { type: 'default', danger: true },
                    icon: <CloseCircleOutlined style={{ color: colorError }} />,
                    onOk: props.onConfirm,
                });
            }}
        >
            Delete {capitalizedObjectType}
        </Button>
    );
};

export default BaseDeleteButton;
