import { BaseObject, TemplateField } from 'types';
import { fieldLabel, getField, isDefined } from 'utils';
import DrawerDescription from './DrawerDescription';

const NumberFieldDrawerDescription = (props: {
    obj: BaseObject & { [p: string]: any };
    templateField: TemplateField;
}) => {
    const field = getField(props.templateField);

    const value = props.obj[field.name!];
    if (!isDefined(value)) {
        return null;
    }

    return <DrawerDescription title={fieldLabel(field.name)}>{value}</DrawerDescription>;
};

export default NumberFieldDrawerDescription;
