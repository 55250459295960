import { type CSSProperties } from 'react';
import { Form, Input } from 'antd';
import { LockOutlined } from '@ant-design/icons';

/*
// https://stackoverflow.com/a/26322921
function validatePassword(value: string): boolean {
    return (
        /[A-Z]/.test(value) &&
        /[a-z]/.test(value) &&
        /[0-9]/.test(value) &&
        /[^A-Za-z0-9]/.test(value) &&
        value.length >= 8
    );
}
 */

const PasswordFormItem = (props: { style?: CSSProperties }) => {
    return (
        <Form.Item
            style={props.style}
            name="password"
            rules={[
                {
                    required: true,
                    message: 'REQUIRED',
                },
            ]}
        >
            <Input prefix={<LockOutlined />} type="password" placeholder="Password" autoComplete={'current-password'} />
        </Form.Item>
    );
};

export default PasswordFormItem;
