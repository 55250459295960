import { Form } from 'antd';
import TemplateFieldStyleFormItems from 'pages/settings/templates/edit/TemplateFieldStyleFormItems';
import { CreateEditTemplateField, CreateEditTemplateNativeField } from 'types';

import styles from './styles.module.scss';
import SettingsButtonBar from 'pages/settings/common/SettingsButtonBar';

const TemplateNativeFieldForm = (props: {
    templateField: CreateEditTemplateNativeField;
    onFinish: (templateField: CreateEditTemplateField) => void;
    onCancel?: () => void;
    excludedFieldIds?: string[];
}) => {
    const [form] = Form.useForm();

    const onFinish = (values: CreateEditTemplateNativeField) => {
        props.onFinish({ ...props.templateField, ...values });
    };

    return (
        <Form<CreateEditTemplateNativeField>
            name="template-field"
            className={styles.form}
            initialValues={props.templateField}
            onFinish={onFinish}
            autoComplete="off"
            form={form}
        >
            <TemplateFieldStyleFormItems />
            <SettingsButtonBar onCancel={() => props.onCancel?.()} />
        </Form>
    );
};

export default TemplateNativeFieldForm;
