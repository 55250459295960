import { Col, Flex, Row, Space, Steps, type StepsProps, Typography } from 'antd';
import AddTeamMeetingButton from 'components/drawers/team/meetingTable/AddTeamMeetingButton';

import styles from './styles.module.scss';
import type { Meeting, Team } from 'types';
import TeamMeetingSelector from './TeamMeetingSelector';

const TeamMeetingsCardHeader = (props: {
    team: Team;
    meeting: Meeting;
    setMeeting: (meeting: Meeting) => void;
    meetings: Meeting[];
}) => {
    const items = props.meeting?.elements.map((element) => {
        return {
            title: element.title,
            description: element.duration,
            status: 'finish',
        } as StepsProps;
    });

    return (
        <Row gutter={[8, 8]} style={{ flexFlow: 'row' }}>
            <Col flex={'auto'}>
                <div className={styles.header}>
                    <Typography.Title className={styles.title} level={2}>
                        {props.meeting.name}
                    </Typography.Title>
                    {props.meeting.description ? (
                        <Typography.Text className={styles.lead}>{props.meeting.description}</Typography.Text>
                    ) : null}
                </div>
                <Flex>
                    {props.meeting.elements.length > 0 && (
                        <Steps className={styles.steps} size={'small'} items={items} progressDot type={'default'} />
                    )}
                </Flex>
            </Col>
            <Col style={{ flexBasis: 0 }}>
                <Space size={'small'}>
                    <TeamMeetingSelector
                        className={styles.selector}
                        meeting={props.meeting}
                        setMeeting={props.setMeeting}
                        meetings={props.meetings}
                    />
                    <AddTeamMeetingButton
                        shape={'circle'}
                        type={'primary'}
                        className={styles.button}
                        team={props.team}
                    />
                </Space>
            </Col>
        </Row>
    );
};

export default TeamMeetingsCardHeader;
