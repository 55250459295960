import type { MouseEvent } from 'react';
import type { FDGNode } from 'components/graph/fdg/types';

const ForceDirectedGraphNode = (props: {
    node: FDGNode;
    radius: number;
    onMouseMove?: (event: MouseEvent) => void;
    onContent?: (node: FDGNode) => void;
    onClick?: (node: FDGNode, element: SVGElement) => void;
}) => {
    const content = props.onContent?.(props.node);
    const r = props.node.radiusMultiple ? props.node.radiusMultiple * props.radius : props.radius;

    const onClick = (event: MouseEvent) => {
        if (props.onClick) {
            const element = event.target as SVGElement;
            props.onClick(props.node, element);
        }
    };

    const style = { cursor: props.onClick ? 'pointer' : 'default' };

    if (content) {
        return (
            <g onMouseMove={props.onMouseMove} onClick={onClick} style={style}>
                <circle
                    className={props.node.obj ? `node ${props.node.obj.type}` : undefined}
                    cx={props.node.x}
                    cy={props.node.y}
                    r={r}
                    fill={props.node.fill ? props.node.fill : undefined}
                    stroke={props.node.stroke ? props.node.stroke : undefined}
                    strokeWidth={4}
                />
                {content}
            </g>
        );
    }

    return (
        <circle
            className={props.node.obj ? `node ${props.node.obj.type}` : undefined}
            cx={props.node.x}
            cy={props.node.y}
            r={r}
            fill={props.node.fill ? props.node.fill : undefined}
            stroke={props.node.stroke ? props.node.stroke : undefined}
            strokeWidth={4}
            onMouseMove={props.onMouseMove}
            onClick={onClick}
            style={style}
        />
    );
};

export default ForceDirectedGraphNode;
