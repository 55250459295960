import type { Datum, GTreeData, GTreeGroupData } from 'pages/orgUnitTree/gtree/types';
import type { OrgUnit, Position } from 'types';

// Positions are nodes, org units are groups.
export function orgUnitTreeData(data: { positions: Position[]; orgUnits: OrgUnit[] }, includeGroups: boolean = true) {
    const datumMap = new Map<string, Datum>();
    const groupMap = new Map<string, GTreeGroupData>();
    const roots = [] as Datum[];

    for (const orgUnit of data.orgUnits) {
        groupMap.set(orgUnit.id, {
            id: orgUnit.id,
            obj: orgUnit,
            nodes: [],
            groups: [],
        });
    }

    for (const position of data.positions) {
        datumMap.set(position.id, { id: position.id, obj: position, parentId: null, children: [] } as Datum);

        const group = groupMap.get(position.org_unit.id);
        if (group) {
            group.nodes.push(position.id);
            groupMap.set(position.org_unit.id, group);
        }
    }

    for (const position of data.positions) {
        const datum = datumMap.get(position.id);
        if (datum) {
            const reportsTo = (datum.obj as Position).reports_to;
            if (reportsTo) {
                const parent = datumMap.get(reportsTo.id);
                if (parent) {
                    datum.parentId = parent.id;
                    parent.children.push(datum);
                }
            } else {
                roots.push(datum);
            }
        }
    }

    const groups = Array.from(groupMap.values());
    for (const group of groups) {
        const orgUnit = group.obj as OrgUnit;
        if (orgUnit.org_unit) {
            const parentGroup = groupMap.get(orgUnit.org_unit.id);
            if (parentGroup) {
                parentGroup.groups.push(group.id);
            }
        }
    }

    return { roots, groups: includeGroups ? groups : [] } as GTreeData;
}
