import apiClient from 'api';
import { fetchObjectives, selectObjectives, selectObjectiveStatus } from 'features/objectiveSlice';
import { fetchOrgUnits, selectOrgUnits, selectOrgUnitStatus } from 'features/orgUnitSlice';
import { fetchPositions, selectPositions, selectPositionStatus } from 'features/positionSlice';
import { useEffect } from 'react';

import type { BaseObject, ObjectType } from 'types';
import { useAppDispatch, useAppSelector } from 'store';

import { nameSort } from 'utils';
import { fetchPeople, selectPeople, selectPeopleStatus } from 'features/personSlice';
import { fetchZones, selectZones, selectZoneStatus } from 'features/zoneSlice';
import { fetchTeams, selectTeams, selectTeamStatus } from 'features/teamSlice';
import { fetchRoles, selectRoles, selectRoleStatus } from 'features/roleSlice';
import { fetchDecisions, selectDecisions, selectDecisionStatus } from 'features/decisionSlice';

// type Allowed = 'person' | 'zone' | 'team' | 'role' | 'decision' | 'objective' | 'position' | 'org_unit';

// Yuck
export function useObjects(allowed: ObjectType[]) {
    const dispatch = useAppDispatch();
    const token = apiClient.token;

    const people = useAppSelector(selectPeople) || [];
    const peopleStatus = useAppSelector(selectPeopleStatus);

    const zones = useAppSelector(selectZones);
    const zoneStatus = useAppSelector(selectZoneStatus);

    const teams = useAppSelector(selectTeams);
    const teamStatus = useAppSelector(selectTeamStatus);

    const roles = useAppSelector(selectRoles);
    const roleStatus = useAppSelector(selectRoleStatus);

    const decisions = useAppSelector(selectDecisions);
    const decisionStatus = useAppSelector(selectDecisionStatus);

    const objectives = useAppSelector(selectObjectives);
    const objectiveStatus = useAppSelector(selectObjectiveStatus);

    const positions = useAppSelector(selectPositions);
    const positionStatus = useAppSelector(selectPositionStatus);

    const orgUnits = useAppSelector(selectOrgUnits);
    const orgUnitStatus = useAppSelector(selectOrgUnitStatus);

    useEffect(() => {
        if (token && peopleStatus === 'idle' && allowed.includes('person')) {
            dispatch(fetchPeople());
        }
    }, [token, peopleStatus, dispatch, allowed]);

    useEffect(() => {
        if (token && zoneStatus === 'idle' && allowed.includes('zone')) {
            dispatch(fetchZones() as any);
        }
    }, [token, zoneStatus, dispatch, allowed]);

    useEffect(() => {
        if (token && teamStatus === 'idle' && allowed.includes('team')) {
            dispatch(fetchTeams() as any);
        }
    }, [token, teamStatus, dispatch, allowed]);

    useEffect(() => {
        if (token && roleStatus === 'idle' && allowed.includes('role')) {
            dispatch(fetchRoles());
        }
    }, [token, roleStatus, dispatch, allowed]);

    useEffect(() => {
        if (token && decisionStatus === 'idle' && allowed.includes('decision')) {
            dispatch(fetchDecisions());
        }
    }, [token, decisionStatus, dispatch, allowed]);

    useEffect(() => {
        if (token && objectiveStatus === 'idle' && allowed.includes('objective')) {
            dispatch(fetchObjectives());
        }
    }, [token, objectiveStatus, dispatch, allowed]);

    useEffect(() => {
        if (token && positionStatus === 'idle' && allowed.includes('position')) {
            dispatch(fetchPositions());
        }
    }, [token, positionStatus, dispatch, allowed]);

    useEffect(() => {
        if (token && orgUnitStatus === 'idle' && allowed.includes('org_unit')) {
            dispatch(fetchOrgUnits());
        }
    }, [token, orgUnitStatus, dispatch, allowed]);

    return {
        person: allowed.includes('person') ? [...people].sort(nameSort) : undefined,
        zone: allowed.includes('zone') ? [...zones].sort(nameSort) : undefined,
        team: allowed.includes('team') ? [...teams].sort(nameSort) : undefined,
        role: allowed.includes('role') ? [...roles].sort(nameSort) : undefined,
        decision: allowed.includes('decision') ? [...decisions].sort(nameSort) : undefined,
        objective: allowed.includes('objective') ? [...objectives].sort(nameSort) : undefined,
        position: allowed.includes('position') ? [...positions].sort(nameSort) : undefined,
        org_unit: allowed.includes('org_unit') ? [...orgUnits].sort(nameSort) : undefined,
    } as { [key: string]: BaseObject[] };
}

export function useObject<T extends BaseObject>(type: ObjectType, id: string) {
    const objs = useObjects([type]);
    return objs[type] ? objs[type].find<T>((x: BaseObject): x is T => x.id === id) : undefined;
}
