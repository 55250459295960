import { useObjectiveDeletePermission } from 'hooks/permissions';
import { useNavigate } from 'react-router-dom';
import { App } from 'antd';
import { Objective } from 'types';

import { getDispatchError, hasDispatchError, isDefined, isUndefined } from 'utils';

import { AppDispatch, useAppDispatch } from 'store';
import { deleteObjective } from 'features/objectiveSlice';
import BaseDeleteButton from 'components/buttons/BaseDeleteButton';
import Show from 'components/Show';

const DeleteObjectiveButton = (props: {
    objectiveId: string;
    onDelete?: (objective: Objective) => void;
    navigate?: string | boolean;
}) => {
    const { message } = App.useApp();
    const dispatch: AppDispatch = useAppDispatch();
    const navigate = useNavigate();

    async function onConfirm() {
        const res = await dispatch(deleteObjective(props.objectiveId));
        if (hasDispatchError(res)) {
            message.error(getDispatchError(res));
            return;
        }
        props.onDelete?.(res.payload as Objective);
        if (isDefined(props.navigate) && props.navigate) {
            navigate(props.navigate as string, { replace: true });
        } else if (isUndefined(props.navigate)) {
            navigate('/objectives', { replace: true });
        }
        message.success('Your objective was successfully deleted.');
    }

    return (
        <Show permission={useObjectiveDeletePermission(props.objectiveId)}>
            <BaseDeleteButton objectType={'objective'} onConfirm={onConfirm} />
        </Show>
    );
};

export default DeleteObjectiveButton;
