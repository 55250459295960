import { useUpdateMeetingAgendaItem } from 'hooks/meetings';
import { useState } from 'react';
import {
    closestCenter,
    DndContext,
    DragEndEvent,
    DragOverEvent,
    DragOverlay,
    DragStartEvent,
    PointerSensor,
    UniqueIdentifier,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import { App, Col, Row } from 'antd';

import type { AgendaItem, Meeting } from 'types';
import { getErrorMessage } from 'utils';

import { updateAgendaItem } from 'services/meetings';
import { useOrg } from 'hooks/useOrg';

import TeamMeetingColumn from './TeamMeetingColumn';
import { agendaItemsMapById, insertOrder } from './utils';
import AgendaItemCard from './AgendaItemCard';

const TeamMeetingDraggableContainer = (props: { meeting: Meeting }) => {
    const { message } = App.useApp();
    const updateMeetingAgendaItem = useUpdateMeetingAgendaItem(props.meeting);

    const org = useOrg();
    const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);

    const pointerSensor = useSensor(PointerSensor, {
        activationConstraint: {
            distance: 10,
        },
    });

    const sensors = useSensors(pointerSensor);

    if (!org) return null;

    /* These should be built in the provider */
    const itemsMapById = agendaItemsMapById(props.meeting);

    const update = async (agendaItem: AgendaItem) => {
        try {
            await updateAgendaItem(org, agendaItem.id, agendaItem);
        } catch (e) {
            message.error(getErrorMessage(e));
            /* reload page? refresh agenda items? */
        }
    };

    const onDragStart = (event: DragStartEvent) => {
        setActiveId(event.active.id);
    };

    const onDragEnd = async (event: DragEndEvent) => {
        /* Update one more time */
        onDragOver(event);

        const activeItem = itemsMapById[event.active.id];
        if (activeItem) {
            await update(activeItem);
        }
        setActiveId(null);
    };

    const onDragOver = (event: DragOverEvent) => {
        if (!event.over) return;

        const targetColumnId = event.over.data.current?.sortable?.containerId;

        const activeItem = itemsMapById[event.active.id];
        const overItem = itemsMapById[event.over.id];

        if (activeItem) {
            const columnId = targetColumnId ? targetColumnId : event.over!.id;
            const targetColumn = props.meeting.columns.find((c) => c.id === columnId);

            if (overItem) {
                // https://github.com/clauderic/dnd-kit/issues/1450

                // Half the height of the AgendaItemCard because we are using closestCenter.
                const insertBefore = event.delta.y < event.over.rect.height / 2;
                activeItem.n = insertOrder(targetColumn?.items || [], overItem, event.active.id, insertBefore);
                activeItem.meeting_column_id = overItem.meeting_column_id;
            } else {
                activeItem.n = 0;
                if (targetColumn) {
                    activeItem.meeting_column_id = targetColumn.id;
                }
            }
            itemsMapById[activeItem.id] = activeItem;
            updateMeetingAgendaItem(activeItem);
        }
    };

    return (
        <DndContext
            onDragStart={onDragStart}
            onDragOver={onDragOver}
            onDragEnd={onDragEnd}
            sensors={sensors}
            measuring={{
                droppable: {
                    //strategy: MeasuringStrategy.Always,
                },
            }}
            collisionDetection={closestCenter}
        >
            <Row gutter={16}>
                {props.meeting.columns.map((column) => (
                    <Col key={column.id} span={Math.floor(24 / props.meeting.columns.length)}>
                        <TeamMeetingColumn
                            id={column.id}
                            title={column.title}
                            meeting={props.meeting}
                            column={column}
                        />
                    </Col>
                ))}
            </Row>
            <DragOverlay>
                {activeId ? <AgendaItemCard meeting={props.meeting} agendaItem={itemsMapById[activeId]} /> : null}
            </DragOverlay>
        </DndContext>
    );
};

export default TeamMeetingDraggableContainer;
