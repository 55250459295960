import { useState } from 'react';
import { App } from 'antd';

import { Role, Team, PersonRef } from 'types';
import { AppDispatch, useAppDispatch } from 'store';

import { useCurrentUser } from 'hooks/useCurrentUser';
import { createRole } from 'features/roleSlice';
import GeneralSteps from 'components/steps/common/GeneralSteps';
import { getDispatchError, hasDispatchError } from 'utils';

const CreateRoleSteps = (props: { team?: Team; onSuccess: (role: Role) => void; onCancel: () => void }) => {
    const { message } = App.useApp();
    const dispatch: AppDispatch = useAppDispatch();

    const currentUser = useCurrentUser();
    const [role, setRole] = useState(() => {
        const role: Role = { type: 'role', status: 'active' } as Role;
        if (props.team) {
            role.team = { type: 'team', id: props.team.id };
        }
        return role;
    });
    const [loading, setLoading] = useState(false);

    function onCancel() {
        props.onCancel();
        setTimeout(() => {
            setRole({ type: 'role' } as Role);
        }, 0);
    }

    async function onCreate() {
        setLoading(true);
        const newRole = {
            ...role,
            owners: [{ id: currentUser?.id, type: 'person' } as PersonRef],
        };
        if (props.team) {
            newRole.team = { id: props.team.id, type: 'team' };
        }

        const res = await dispatch(createRole(newRole as Role));
        setLoading(false);

        if (hasDispatchError(res)) {
            message.error(getDispatchError(res));
            return;
        }
        message.success('Your role was successfully created.');
        props.onSuccess(res.payload as Role);
    }

    /* Set team */
    const skip = props.team ? ['team'] : undefined;

    return (
        <GeneralSteps
            obj={role}
            setObj={setRole}
            onFinish={onCreate}
            onCancel={onCancel}
            loading={loading}
            skip={skip}
        />
    );
};

export default CreateRoleSteps;
