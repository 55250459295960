import apiClient from 'api';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { fetchPeople, selectPeople, selectPeopleStatus } from 'features/personSlice';
import { nameSort } from 'utils';
import type { Person } from 'types';

export function usePeople(): Person[] | null {
    const dispatch = useAppDispatch();
    const people = useAppSelector(selectPeople);
    const peopleStatus = useAppSelector(selectPeopleStatus);
    const token = apiClient.token;

    useEffect(() => {
        if (token && peopleStatus === 'idle') {
            dispatch(fetchPeople());
        }
    }, [token, peopleStatus, dispatch]);

    return people ? [...people].sort(nameSort) : null;
}

// FIXME: use the real API.
export function usePerson(id: string | null | undefined): Person | undefined {
    const people = usePeople() || [];
    return id ? people.find((person) => person.id === id) : undefined;
}
