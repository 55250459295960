import { useState } from 'react';
import { App } from 'antd';

import { OrgUnit, PersonRef } from 'types';
import { AppDispatch, useAppDispatch } from 'store';

import { useCurrentUser } from 'hooks/useCurrentUser';
import { createOrgUnit } from 'features/orgUnitSlice';
import GeneralSteps from 'components/steps/common/GeneralSteps';
import { getDispatchError, hasDispatchError } from 'utils';

const CreateOrgUnitSteps = (props: {
    orgUnit?: OrgUnit;
    onSuccess: (orgUnit: OrgUnit) => void;
    onCancel: () => void;
}) => {
    const { message } = App.useApp();
    const dispatch: AppDispatch = useAppDispatch();

    const currentUser = useCurrentUser();
    const [orgUnit, setOrgUnit] = useState(() => {
        const orgUnit: OrgUnit = { type: 'org_unit' } as OrgUnit;
        if (props.orgUnit) {
            orgUnit.org_unit = { type: 'org_unit', id: props.orgUnit.id };
        }
        return orgUnit;
    });
    const [loading, setLoading] = useState(false);

    function onCancel() {
        props.onCancel();
        setTimeout(() => {
            setOrgUnit({ type: 'org_unit' } as OrgUnit);
        }, 0);
    }

    async function onCreate() {
        setLoading(true);
        const newOrgUnit = {
            ...orgUnit,
            owners: [{ id: currentUser?.id, type: 'person' } as PersonRef],
        };
        if (props.orgUnit) {
            orgUnit.org_unit = { type: 'org_unit', id: props.orgUnit.id };
        }

        const res = await dispatch(createOrgUnit(newOrgUnit as OrgUnit));
        setLoading(false);

        if (hasDispatchError(res)) {
            message.error(getDispatchError(res));
            return;
        }
        message.success('Your Org Unit was successfully created.');
        props.onSuccess(res.payload as OrgUnit);
    }

    /* Set org_unit */
    const skip = props.orgUnit ? ['org_unit'] : undefined;

    return (
        <GeneralSteps
            obj={orgUnit}
            setObj={setOrgUnit}
            onFinish={onCreate}
            onCancel={onCancel}
            loading={loading}
            skip={skip}
        />
    );
};

export default CreateOrgUnitSteps;
