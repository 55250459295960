import ElementTooltip from 'components/tooltip/ElementTooltip';
import { getDataBBox } from 'pages/orgUnit/gfdg/utils';
import {
    forwardRef,
    type PropsWithChildren,
    type ReactNode,
    type MouseEvent,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { isDebug } from 'utils';

import { GFDGData, GFDGLink, GFDGNode, GFDGGroup, GFDGWorkerResponse, GFDGConstraint } from './types';
import GroupedForceDirectedGraphNode from './GroupedForceDirectedGraphNode';
import GroupedForceDirectedGraphLink from './GroupedForceDirectedGraphLink';
import GroupedForceDirectedGraphGroup from './GroupedForceDirectedGraphGroup';

const PADDING = 32;

type GFDGProps = PropsWithChildren<{
    width: number;
    height: number;
    data: { nodes: GFDGNode[]; links: GFDGLink[]; groups: GFDGGroup[]; constraints?: GFDGConstraint[] };
    onClick?: (target: GFDGNode | GFDGGroup | null) => void;
    onContextMenu?: (target: GFDGNode | GFDGGroup | null, x: number, y: number) => void;
    onTooltipText?: (target: GFDGGroup) => ReactNode;
    onNodeContent?: (node: GFDGNode) => ReactNode;
    groupPadding?: number;
    idealLength?: number;
}>;

const GroupedForceDirectedGraph = forwardRef<HTMLDivElement, GFDGProps>((props, ref) => {
    const svgRef = useRef<SVGSVGElement>(null);
    const [hover, setHover] = useState<{ target: GFDGGroup; element: Element } | null>(null);
    const [graphData, setGraphData] = useState<GFDGData>();

    const { nodes, links, groups, constraints = [] } = props.data;
    const width = 4 * props.width;
    const height = 4 * props.height;
    const idealLength = props.idealLength ? props.idealLength : 100;

    const simulator: Worker = useMemo(() => new Worker(new URL('./webworker.ts', import.meta.url)), []);
    const boundingBox = graphData && graphData.nodes.length ? getDataBBox(graphData) : new DOMRect(0, 0, 0, 0);

    /* Send the data to the webworker simulator */
    useEffect(() => {
        if (window.Worker) {
            const debug = isDebug('simulation');
            simulator.postMessage({
                width,
                height,
                nodes,
                links,
                groups,
                constraints,
                debug,
                avoidOverlaps: true,
                idealLength,
            });

            simulator.onmessage = (e: MessageEvent<GFDGWorkerResponse>) => {
                if (!e.data.success) {
                    console.error('SIMULATION FAILED.');
                }
                if (debug || !e.data.success) {
                    console.log(
                        `simulation: elapsedTime=${e.data.elapsedTime}, tickCount=${e.data.tickCount}, alpha=${e.data.alpha.toFixed(4)}`,
                    );
                }
                setGraphData({ nodes: e.data.nodes, links: e.data.links, groups: e.data.groups });
            };
        }
    }, [simulator, width, height, nodes, links, groups, constraints, idealLength]);

    if (!graphData) return null;

    const onContextMenu = (event: MouseEvent) => {
        if (props.onContextMenu) {
            event.preventDefault();
            props.onContextMenu(null, event.clientX, event.clientY);
        }
    };

    return (
        <div ref={ref}>
            <svg
                style={{ width: '100%', height: '100%' }}
                width={width}
                height={height}
                viewBox={`${boundingBox.x - PADDING} ${boundingBox.y - PADDING} ${boundingBox.width + 2 * PADDING} ${boundingBox.height + 2 * PADDING}`}
                ref={svgRef}
                onMouseMove={() => setHover(null)}
                onContextMenu={onContextMenu}
            >
                <g>
                    {graphData.links.map((link, index) => (
                        <GroupedForceDirectedGraphLink key={index} link={link} />
                    ))}
                </g>

                <g>
                    {graphData.groups.map((group, index) => (
                        <GroupedForceDirectedGraphGroup
                            key={index}
                            group={group}
                            onClick={props.onClick}
                            onContextMenu={props.onContextMenu}
                            onMouseMove={(event) => {
                                event.stopPropagation();
                                const targetId = hover?.target ? (hover.target as GFDGGroup).obj.id : undefined;
                                if (group.obj?.id !== targetId) {
                                    setHover(
                                        event.target
                                            ? {
                                                  target: group,
                                                  element: event.target as Element,
                                              }
                                            : null,
                                    );
                                }
                            }}
                        />
                    ))}
                </g>

                <g>
                    {graphData.nodes.map((node, index) => (
                        <GroupedForceDirectedGraphNode
                            key={index}
                            node={node}
                            onClick={props.onClick}
                            onNodeContent={props.onNodeContent}
                            onContextMenu={props.onContextMenu}
                        />
                    ))}
                </g>

                {props.children}
            </svg>
            {props.onTooltipText && hover && (
                <ElementTooltip element={hover.element} position={'top'}>
                    {props.onTooltipText(hover.target)}
                </ElementTooltip>
            )}
        </div>
    );
});

export default GroupedForceDirectedGraph;
