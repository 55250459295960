import { Typography } from 'antd';
import { BaseObject, TemplateField } from 'types';
import { fieldLabel, getField } from 'utils';

import { parseLinkValue } from 'components/forms/common/utils';

import DrawerDescription from './DrawerDescription';

const LinkFieldDrawerDescription = (props: {
    obj: BaseObject & { [p: string]: any };
    templateField: TemplateField;
}) => {
    const field = getField(props.templateField);

    const value = props.obj[field.name!];
    if (!value) {
        return null;
    }

    /*
     * Don't use showdown for cases with the link text starts with a bracket,
     * plus we want to open links in a new tab.
     */
    const match = parseLinkValue(value);

    return (
        <DrawerDescription title={fieldLabel(field.name)}>
            <Typography.Link target={'_blank'} href={match.href}>
                {match.text ? match.text : match.href}
            </Typography.Link>
        </DrawerDescription>
    );
};

export default LinkFieldDrawerDescription;
