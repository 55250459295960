import { FDGLink } from 'components/graph/fdg/types';
import { adjustLink, asSource, asTarget } from 'components/graph/fdg/utils';

const LINE_COLOR = '#999';

const ForceDirectedGraphLink = (props: { link: FDGLink; radius: number }) => {
    const color = props.link.style?.color ? props.link.style.color : LINE_COLOR;

    const markerStart = props.link.marker === 'dot' ? `url(#${props.link.marker})` : undefined;
    const markerEnd = props.link.marker ? `url(#${props.link.marker})` : undefined;

    const strokeDasharray = props.link.style?.strokeDasharray ? props.link.style.strokeDasharray : undefined;
    const strokeWidth = props.link.style?.strokeWidth ? props.link.style.strokeWidth : 1;

    let markerSize = 0;
    if (props.link.marker === 'arrow') {
        markerSize += 25;
    } else if (props.link.marker === 'dot') {
        markerSize += 6;
    }

    // FIXME: probably don't need directed
    return (
        <line
            className={'link'}
            x1={
                props.link.marker === 'dot'
                    ? adjustLink(props.link, props.radius, markerSize).x1
                    : asSource(props.link).x
            }
            y1={
                props.link.marker === 'dot'
                    ? adjustLink(props.link, props.radius, markerSize).y1
                    : asSource(props.link).y
            }
            x2={markerEnd ? adjustLink(props.link, props.radius, markerSize).x2 : asTarget(props.link).x}
            y2={markerEnd ? adjustLink(props.link, props.radius, markerSize).y2 : asTarget(props.link).y}
            stroke={color}
            strokeWidth={(strokeWidth as number) * 4}
            strokeDasharray={strokeDasharray}
            markerStart={markerStart}
            markerEnd={markerEnd}
        />
    );
};

export default ForceDirectedGraphLink;
