import BaseDeleteButton from 'components/buttons/BaseDeleteButton';
import { useNavigate } from 'react-router-dom';
import { App } from 'antd';

import { getDispatchError, hasDispatchError, isPersonAdminOrMore } from 'utils';
import { useCurrentUser } from 'hooks/useCurrentUser';

import { AppDispatch, useAppDispatch } from 'store';
import { deletePerson } from 'features/personSlice';

const DeletePersonButton = (props: { personId: string }) => {
    const { message } = App.useApp();
    const dispatch: AppDispatch = useAppDispatch();
    const navigate = useNavigate();

    if (!isPersonAdminOrMore(useCurrentUser())) {
        return null;
    }

    async function onConfirm() {
        const res = await dispatch(deletePerson(props.personId));
        if (hasDispatchError(res)) {
            message.error(getDispatchError(res));
            return;
        }
        navigate('/people', { replace: true });
        message.success('The person was successfully deleted.');
    }

    return <BaseDeleteButton objectType={'person'} onConfirm={onConfirm} />;
};

export default DeletePersonButton;
