import type { Team } from 'types';
import BaseCreateButton, { type CreateButtonType } from 'components/buttons/BaseCreateButton';
import CreateAgreementSteps from 'components/steps/CreateAgreementSteps';
import Show from 'components/Show';
import { useAgreementCreatePermission } from 'hooks/permissions';

const CreateAgreementButton = (props: { team?: Team; type?: CreateButtonType }) => (
    <Show permission={useAgreementCreatePermission(props.team?.id)}>
        <BaseCreateButton text={'Create Agreement'} type={props.type}>
            {(onSuccess, onCancel) => (
                <CreateAgreementSteps team={props.team} onSuccess={onSuccess} onCancel={onCancel} />
            )}
        </BaseCreateButton>
    </Show>
);

export default CreateAgreementButton;
