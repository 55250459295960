import { Form, Input } from 'antd';
import { type PhoneNumber } from 'antd-phone-input';
import FormButtonBar from 'components/forms/common/FormButtonBar';
import PersonFormItem from 'components/forms/common/PersonFormItem';
import PhoneFormItem from 'components/forms/common/PhoneFormItem';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { Person } from 'types';
import { isPersonAdminOrMore, isString } from 'utils';

type PersonFormDataInternal = Pick<Person, 'first_name' | 'last_name' | 'title' | 'reports_to_id'> & {
    phone: PhoneNumber | string | undefined;
};

export type PersonFormData = Pick<Person, 'first_name' | 'last_name' | 'title' | 'reports_to_id' | 'phone'>;

const buildPhoneNumber = (phone: PersonFormDataInternal['phone']) => {
    if (!phone) {
        return null;
    }
    if (isString(phone)) {
        return phone;
    }
    return phone.phoneNumber ? `${phone.countryCode}${phone.areaCode}${phone.phoneNumber}` : null;
};

const PersonForm = (props: {
    person?: Person;
    onFinish?: (person: PersonFormData) => void;
    onCancel?: () => void;
    loading?: boolean;
}) => {
    const isAdmin = isPersonAdminOrMore(useCurrentUser());
    const omitIds = props.person ? [props.person.id] : undefined;

    const onFinish = (values: PersonFormDataInternal) => {
        const phone = buildPhoneNumber(values.phone);
        props.onFinish?.({ ...values, phone });
    };

    return (
        <Form<PersonFormDataInternal> name={'person'} initialValues={props.person} onFinish={onFinish}>
            <Form.Item<Person>
                className={'form-control'}
                label={'First Name'}
                name={'first_name'}
                rules={[{ required: true, message: `Please enter the person's first name.` }]}
            >
                <Input />
            </Form.Item>
            <Form.Item<Person>
                className={'form-control'}
                label={'Last Name'}
                name={'last_name'}
                rules={[{ required: true, message: `Please enter the person's last name.` }]}
            >
                <Input />
            </Form.Item>
            <PhoneFormItem />
            {/*
            <Form.Item<Person>
                className={'form-control'}
                label={'Phone Number'}
                name={'last_name'}
                rules={[{ required: true, message: `Please enter the person's phone number.` }]}
            >
                <Input />
            </Form.Item>
            */}

            {isAdmin && (
                <>
                    <Form.Item<Person> className={'form-control'} label={'Title'} name={'title'}>
                        <Input />
                    </Form.Item>
                    <PersonFormItem name={'reports_to_id'} label={'Reports To'} omitIds={omitIds} />
                </>
            )}
            <FormButtonBar onCancel={props.onCancel} loading={props.loading} />
        </Form>
    );
};

export default PersonForm;
