import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';
import { Layout, theme, Typography } from 'antd';

import { useThemeMode } from 'hooks/useThemeMode';
import styles from './styles.module.scss';

import ViggoLogo from './ViggoLogo';

const AuthPage = (
    props: PropsWithChildren<{
        className?: string;
        title?: string;
    }>,
) => {
    const themeMode = useThemeMode();
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const className = classNames(styles.page, `theme-${themeMode}`, {
        [props.className || '']: !!props.className,
    });

    return (
        <Layout className={className} style={{ background: colorBgContainer }}>
            <div className="auth-container">
                <ViggoLogo />
                {props.title ? <Typography.Title>{props.title}</Typography.Title> : null}
                {props.children}
            </div>
        </Layout>
    );
};

export default AuthPage;
