import OneToOneFormItem from 'components/forms/common/OneToOneFormItem';
import PersonFormItem from 'components/forms/common/PersonFormItem';
import type { Position, TemplateNativeField } from 'types';

const handlePosition = (position: Position, templateField: TemplateNativeField, onChange: (value: any) => void) => {
    const setPersonId = (value: string | undefined) => {
        onChange(value || null);
    };

    switch (templateField.native_field.name) {
        case 'person_id':
            return (
                <PersonFormItem
                    allowClear
                    label={'Person'}
                    personId={position.person_id || undefined}
                    setPersonId={setPersonId}
                />
            );
        case 'reports_to':
            return (
                <OneToOneFormItem
                    templateField={templateField}
                    objectRef={position.reports_to}
                    setObjectRef={onChange}
                    excluded={[position.id]}
                />
            );
    }
    return undefined;
};

export default handlePosition;
