import { Team } from 'types';
import CreateObjectiveSteps from 'components/steps/CreateObjectiveSteps';
import BaseCreateButton, { type CreateButtonType } from 'components/buttons/BaseCreateButton';
import Show from 'components/Show';
import { useObjectiveCreatePermission } from 'hooks/permissions';

const CreateObjectiveButton = (props: { team: Team; type?: CreateButtonType }) => (
    <Show permission={useObjectiveCreatePermission(props.team?.id)}>
        <BaseCreateButton text={'Create Objective'} type={props.type}>
            {(onSuccess, onCancel) => (
                <CreateObjectiveSteps team={props.team} onSuccess={onSuccess} onCancel={onCancel} />
            )}
        </BaseCreateButton>
    </Show>
);

export default CreateObjectiveButton;
