import SettingsAction from 'pages/settings/common/SettingsAction';
import React from 'react';
import { App, Popconfirm, theme } from 'antd';
import { ExclamationOutlined } from '@ant-design/icons';

import { deleteField } from 'services/fields';
import { getErrorMessage } from 'utils';
import { useOrg } from 'hooks/useOrg';
import { Field } from 'types';

const DeleteFieldButton = (props: { fieldId: string; onDelete: (field: Field) => void }) => {
    const { token } = theme.useToken();
    const org = useOrg();
    const { message } = App.useApp();

    if (!org) return null;

    const onDelete = async (fieldId: string) => {
        try {
            const field = await deleteField(org, fieldId);
            props.onDelete(field);
        } catch (e) {
            message.error(getErrorMessage(e));
        }
    };

    return (
        <Popconfirm
            title={'Are you sure you want to delete this field?  This action cannot be undone.'}
            icon={<ExclamationOutlined style={{ color: token.colorError }} />}
            onConfirm={() => onDelete(props.fieldId)}
            okButtonProps={{ type: 'default', danger: true }}
        >
            <SettingsAction type={'danger'}>Delete</SettingsAction>
        </Popconfirm>
    );
};

export default DeleteFieldButton;
