import { useState } from 'react';
import { App } from 'antd';

import { Position, PersonRef, OrgUnit } from 'types';
import { AppDispatch, useAppDispatch } from 'store';

import { useCurrentUser } from 'hooks/useCurrentUser';
import { createPosition } from 'features/positionSlice';
import GeneralSteps from 'components/steps/common/GeneralSteps';
import { getDispatchError, hasDispatchError } from 'utils';

const CreatePositionSteps = (props: {
    orgUnit?: OrgUnit;
    reportsTo?: Position;
    onSuccess: (position: Position) => void;
    onCancel: () => void;
}) => {
    const { message } = App.useApp();
    const dispatch: AppDispatch = useAppDispatch();

    const currentUser = useCurrentUser();
    const [position, setPosition] = useState(() => {
        const position: Position = { type: 'position' } as Position;
        if (props.orgUnit) {
            position.org_unit = { type: 'org_unit', id: props.orgUnit.id };
        }
        if (props.reportsTo) {
            position.reports_to = { type: 'position', id: props.reportsTo.id };
        }
        return position;
    });
    const [loading, setLoading] = useState(false);

    function onCancel() {
        props.onCancel();
        setTimeout(() => {
            setPosition({ type: 'position' } as Position);
        }, 0);
    }

    async function onCreate() {
        setLoading(true);
        const newPosition = {
            ...position,
            owners: [{ id: currentUser?.id, type: 'person' } as PersonRef],
        };
        if (props.orgUnit) {
            newPosition.org_unit = { id: props.orgUnit.id, type: 'org_unit' };
        }

        const res = await dispatch(createPosition(newPosition as Position));
        setLoading(false);

        if (hasDispatchError(res)) {
            message.error(getDispatchError(res));
            return;
        }
        message.success('Your position was successfully created.');
        props.onSuccess(res.payload as Position);
    }

    const skip = [];
    if (props.orgUnit) {
        skip.push('org_unit');
    }
    if (props.reportsTo) {
        skip.push('reports_to');
    }

    return (
        <GeneralSteps
            obj={position}
            setObj={setPosition}
            onFinish={onCreate}
            onCancel={onCancel}
            loading={loading}
            skip={skip}
        />
    );
};

export default CreatePositionSteps;
