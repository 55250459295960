import type { CSSProperties, PropsWithChildren } from 'react';

import styles from 'components/tooltip/styles.module.scss';

import { useThemeMode } from 'hooks/useThemeMode';

const BaseTooltip = (
    props: PropsWithChildren<{
        x: number;
        y: number;
        position?: CSSProperties['position'];
    }>,
) => {
    const mode = useThemeMode();
    if (!props.children) return null;

    const yOffset = 5 /* carat */ + 2; /* lineWidth/2 */

    const style = {
        left: props.x,
        top: props.y,
        transform: `translate(-50%, -100%) translateY(-${yOffset}px)`,
        position: props.position,
    } as CSSProperties;

    return (
        <div className={`${styles.tooltip} ${mode === 'dark' ? styles.dark : undefined}`} style={style}>
            {props.children}
        </div>
    );
};

export default BaseTooltip;
