import { Flex } from 'antd';
import PersonAvatar from 'components/avatar/PersonAvatar';
import * as d3 from 'd3';
import { usePerson } from 'hooks/usePeople';
import { useThemeMode } from 'hooks/useThemeMode';
import type { Datum } from 'pages/orgUnitTree/gtree/types';
import { MouseEvent } from 'react';

import type { Position } from 'types';
import styles from './styles.module.scss';

const OrgUnitTreeNode = (props: {
    node: d3.HierarchyNode<Datum>;
    nodeWidth: number;
    nodeHeight: number;
    onClick?: (node: d3.HierarchyNode<Datum>) => void;
}) => {
    const mode = useThemeMode();
    const position = props.node.data.obj as Position;
    const person = usePerson(position.person_id);

    const onClick = (event: MouseEvent) => {
        if (props.onClick) {
            event.preventDefault();
            event.stopPropagation();
            props.onClick(props.node);
        }
    };

    return (
        <Flex
            style={{ cursor: props.onClick ? 'pointer' : 'default', width: props.nodeWidth, height: props.nodeHeight }}
            className={`${styles.node} ${mode}`}
            vertical
            justify={'center'}
            align={'center'}
            onClick={onClick}
        >
            <PersonAvatar size={'large'} person={person} draggable={false} />
            {person ? <div className={'name'}>{person.name}</div> : null}
            <div className={'position'}>{position.name}</div>
        </Flex>
    );
};

export default OrgUnitTreeNode;
