import React from 'react';
import { Link } from 'react-router-dom';

import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';

import type { Zone, Template } from 'types';
import { asDataSource, map } from 'utils';
import { useZones } from 'hooks/useZones';
import { useTemplates } from 'hooks/templates';
import CreateZoneButton from 'components/buttons/CreateZoneButton';

import styles from './styles.module.scss';

function createColumns(templates: Record<string, Template>) {
    const columns: ColumnsType = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, { id }) => <Link to={`/zoneChart/${id}#zone:${id}`}>{text}</Link>,

            defaultSortOrder: 'ascend',
            sorter: (a, b) => a.name.localeCompare(b.name),
            sortDirections: ['ascend', 'descend', 'ascend'],
        },
        {
            title: 'Boundary',
            dataIndex: 'boundary',
            key: 'boundary',
        },
        {
            title: 'Template',
            dataIndex: 'template_id',
            key: 'template_id',
            render: (template_id) => (
                <Link to={`/settings/templates/${template_id}`}>{templates[template_id]?.name}</Link>
            ),
        },
    ];
    return columns;
}

function parentZones(zones: Zone[]) {
    return zones.filter((zone) => !zone.zone?.id);
}

type TreeNode<T> = T & {
    parentId: string | null;
    children: TreeNode<T>[];
};

function treeify(zones: Zone[]) {
    const zoneMap: Record<string, TreeNode<Zone>> = {};

    for (const zone of zones) {
        zoneMap[zone.id] = {
            parentId: zone.zone?.id || null,
            children: [],
            ...zone,
        };
    }
    for (const zone of zones) {
        if (zone.zone?.id) {
            const parent = zoneMap[zone.zone.id];
            parent.children.push(zoneMap[zone.id]);
        }
    }

    return zoneMap;
}

const ZonesListPage = () => {
    const zones = useZones();
    const templates = useTemplates('zone');
    if (!templates || templates.length === 0) {
        return null;
    }

    const zoneMap = treeify(zones);
    const columns = createColumns(map<Template>(templates));
    const dataSource = asDataSource(parentZones(zones));

    const expandedRowRender = (obj: any) => {
        const zones = zoneMap[(obj as Zone).id].children;
        return (
            <Table
                columns={columns}
                expandable={{ expandedRowRender, rowExpandable }}
                dataSource={asDataSource(zones)}
                pagination={false}
            />
        );
    };

    const rowExpandable = (obj: any) => {
        const zone = zoneMap[(obj as Zone).id];
        return zone.children && zone.children.length > 0;
    };

    return (
        <>
            <Table
                className={styles.table}
                columns={columns}
                expandable={{ expandedRowRender, rowExpandable }}
                dataSource={dataSource}
                pagination={false}
            />
            <CreateZoneButton style={{ marginTop: '1em' }} />
        </>
    );
};

export default ZonesListPage;
