import { App, Button, Card, Form, Input } from 'antd';
import { useState } from 'react';

import type { Organization, Person, UserType } from 'types';
import { getDispatchError, hasDispatchError } from 'utils';

import { AppDispatch, useAppDispatch } from 'store';
import { createPerson } from 'features/personSlice';

import UserTypeSelect from './UserTypeSelect';

type CreatePerson = {
    first_name: string;
    last_name: string;
    email: string;
};

const CreatePersonCard = (props: { org: Organization; busy: boolean; setBusy: (value: boolean) => void }) => {
    const { message } = App.useApp();
    const [form] = Form.useForm();
    const [userType, setUserType] = useState<UserType>('regular');

    const dispatch: AppDispatch = useAppDispatch();

    async function onFinish(values: CreatePerson) {
        const person = { ...values, name: `${values.first_name} ${values.last_name}`, user_type: userType } as Person;
        const res = await dispatch(createPerson(person));
        if (hasDispatchError(res)) {
            message.error(getDispatchError(res));
            return;
        }
        message.success(`Person ${values.email} created.`);
        form.resetFields();
    }

    return (
        <Card title={'Add User'} size={'small'}>
            <Form<CreatePerson> form={form} layout={'horizontal'} labelCol={{ span: 8 }} onFinish={onFinish}>
                <Form.Item
                    name={'email'}
                    label={'Email'}
                    rules={[
                        {
                            required: true,
                            message: 'REQUIRED',
                        },
                    ]}
                    required
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name={'first_name'}
                    label={'First Name'}
                    rules={[
                        {
                            required: true,
                            message: 'REQUIRED',
                        },
                    ]}
                    required
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name={'last_name'}
                    label={'Last Name'}
                    rules={[
                        {
                            required: true,
                            message: 'REQUIRED',
                        },
                    ]}
                    required
                >
                    <Input />
                </Form.Item>
                <Form.Item label={'User Type'}>
                    <UserTypeSelect userType={userType} setUserType={setUserType} />
                </Form.Item>
                <Button loading={props.busy} disabled={props.busy} htmlType={'submit'}>
                    Create
                </Button>
            </Form>
        </Card>
    );
};

export default CreatePersonCard;
