import { updateDecision } from 'features/decisionSlice';
import { updateObjective } from 'features/objectiveSlice';
import { updateOrgUnit } from 'features/orgUnitSlice';
import { updatePosition } from 'features/positionSlice';
import { updateRole } from 'features/roleSlice';
import { updateTeam } from 'features/teamSlice';
import { updateZone } from 'features/zoneSlice';

import { AppDispatch, useAppDispatch } from 'store';
import type { Decision, Objective, ObjectType, OrgUnit, Position, Role, Team, TemplatedObject, Zone } from 'types';

export function useUpdateDispatch() {
    const dispatch: AppDispatch = useAppDispatch();

    return (obj: Partial<TemplatedObject> & { id: string; type: ObjectType }) => {
        switch (obj.type) {
            case 'decision':
                return dispatch(updateDecision(obj as Decision));
            case 'objective':
                return dispatch(updateObjective(obj as Objective));
            case 'role':
                return dispatch(updateRole(obj as Role));
            case 'team':
                return dispatch(updateTeam(obj as Team));
            case 'zone':
                return dispatch(updateZone(obj as Zone));
            case 'org_unit':
                return dispatch(updateOrgUnit(obj as OrgUnit));
            case 'position':
                return dispatch(updatePosition(obj as Position));
        }

        return { error: true, payload: { detail: `Invalid object type: ${obj.type}` } };
    };
}
