import { type FullGestureState, type Target, type Vector2, useGesture } from '@use-gesture/react';
import { useEffect, type Dispatch, type SetStateAction } from 'react';
import { ViewBox } from 'pages/orgUnitTree/gtree/types';

export function useViewBoxGestures(
    target: Target,
    setViewBox: Dispatch<SetStateAction<ViewBox>>,
    scaleFactor: Vector2 = [1, 1],
) {
    useEffect(() => {
        const handler = (e: Event) => e.preventDefault();
        document.addEventListener('gesturestart', handler);
        document.addEventListener('gesturechange', handler);
        document.addEventListener('gestureend', handler);
        return () => {
            document.removeEventListener('gesturestart', handler);
            document.removeEventListener('gesturechange', handler);
            document.removeEventListener('gestureend', handler);
        };
    }, []);

    const onDrag = (state: FullGestureState<'drag'>) => {
        if (state.pinching) return state.cancel();
        setViewBox((viewBox: ViewBox) =>
            viewBox.move(
                -state.delta[0] * scaleFactor[0] * viewBox.scale,
                -state.delta[1] * scaleFactor[1] * viewBox.scale,
            ),
        );
    };

    const onWheel = (state: FullGestureState<'wheel'>) => {
        // state.delta gives us [dx, dy]. We use the vertical delta (dy) to adjust scale.
        const delta = state.delta[1];
        setViewBox((viewBox: ViewBox) => viewBox.setScale(Math.max(0.1, viewBox.scale + delta * 0.005)));
    };

    useGesture(
        {
            onDrag,
            onWheel,
            onPinch: () => {},
        },
        {
            target,
            eventOptions: { passive: false },
            drag: {
                // When true, small pointer movements are considered taps rather than drags.
                filterTaps: true,
            },
        },
    );
}
