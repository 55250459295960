import { useState } from 'react';
import { Button, type ButtonProps } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { AgendaItem, Meeting, MeetingColumn } from 'types';
import CreateEditModal from 'components/steps/common/CreateEditModal';
import AgendaItemForm from 'components/forms/AgendaItemForm';

const CreateAgendaItemButton = (
    props: Omit<ButtonProps, 'onClick'> & {
        meeting: Meeting;
        column: MeetingColumn;
        onCreate?: (agendaItem: AgendaItem) => void;
    },
) => {
    const [open, setOpen] = useState(false);

    const onCancel = () => setOpen(false);

    const onFinish = (agendaItem: AgendaItem) => {
        props.onCreate?.(agendaItem);
        setOpen(false);
    };

    const onClick = () => {
        setOpen(true);
    };

    return (
        <>
            <Button type={'link'} icon={<PlusOutlined />} onClick={onClick} />
            <CreateEditModal open={open} title={'Add Agenda Item'} onCancel={onCancel} width={500}>
                <AgendaItemForm meeting={props.meeting} column={props.column} onFinish={onFinish} onCancel={onCancel} />
            </CreateEditModal>
        </>
    );
};

export default CreateAgendaItemButton;
