import { useUpdateDispatch } from 'components/forms/hooks';
import { useState } from 'react';
import { App, Button, Form, Modal } from 'antd';
import FormButtonBar from 'components/forms/common/FormButtonBar';
import type { ErrorDetail, ErrorDetailDispatch, TemplatedObject } from 'types';
import { fieldLabel } from 'utils';
import TemplateFormItem from 'components/forms/common/TemplateFormItem';

const ChangeTemplateButton = (props: { obj: TemplatedObject; onFinish: (obj: TemplatedObject) => void }) => {
    const [obj, setObj] = useState<TemplatedObject>(props.obj);

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const updateDispatch = useUpdateDispatch();
    const { message } = App.useApp();

    const title = `Change ${fieldLabel(obj.type)} Template`;

    const onCancel = () => setOpen(false);

    const onFinish = async () => {
        setLoading(true);

        const updateObject = { id: obj.id, type: obj.type, template_id: obj.template_id };

        const res = await updateDispatch(updateObject);
        setLoading(false);

        if ((res as ErrorDetailDispatch<TemplatedObject>).error) {
            message.error((res.payload as ErrorDetail).detail.toString());
            return;
        }
        message.success(`${fieldLabel(obj.type)} updated successfully.`);
        props.onFinish(obj);
    };

    return (
        <>
            <Button danger onClick={() => setOpen(true)}>
                {title}
            </Button>
            <Modal
                title={title}
                open={open}
                onCancel={onCancel}
                footer={null}
                destroyOnClose
                maskClosable={false}
                width={500}
            >
                <Form name={'template'} onFinish={onFinish}>
                    <TemplateFormItem
                        objectType={props.obj.type}
                        templateId={obj.template_id}
                        setTemplate={(templateId) => {
                            setObj({ ...props.obj, template_id: templateId });
                        }}
                        useDescription
                    ></TemplateFormItem>
                    <FormButtonBar onCancel={onCancel} loading={loading} />
                </Form>
            </Modal>
        </>
    );
};

export default ChangeTemplateButton;
