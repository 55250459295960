import { FieldWithRequired } from 'types';

const fields: FieldWithRequired[] = [
    { id: '67d75adbb5d5b779', name: 'org_unit', type: 'one-to-one', config: { allowed: ['org_unit'] }, required: true },
    { id: '67d75afcb59b0b5b', name: 'description', type: 'text', config: {} },
    { id: '67d75bb7b57b15a0', name: 'person_id', type: 'single-select', config: { options: [] } }, // special-case
    { id: '67d75bbeb5849d64', name: 'reports_to', type: 'one-to-one', config: { allowed: ['position'] } },
];

export default fields;
