import { useState } from 'react';
import { App } from 'antd';

import { Zone } from 'types';
import { AppDispatch, useAppDispatch } from 'store';
import { createZone } from 'features/zoneSlice';
import { getDispatchError, hasDispatchError } from 'utils';
import GeneralSteps from 'components/steps/common/GeneralSteps';

const CreateZoneSteps = (props: { zone?: Zone; onSuccess: (team: Zone) => void; onCancel: () => void }) => {
    const { message } = App.useApp();
    const dispatch: AppDispatch = useAppDispatch();

    const [zone, setZone] = useState<Zone>(() => {
        const zone: Zone = { type: 'zone' } as Zone;
        if (props.zone) {
            zone.zone = { type: 'zone', id: props.zone.id };
        }
        return zone;
    });
    const [loading, setLoading] = useState(false);

    /*
    function onCancel() {
        props.onCancel();
        setTimeout(() => {
            setZone({'type': 'zone'} as Zone);
        }, 0);
    }
     */

    async function onCreate() {
        setLoading(true);
        const newZone = { ...zone, status: 'active' } as Zone;
        if (props.zone) {
            newZone.zone = { id: props.zone.id, type: 'zone' };
        }

        const res = await dispatch(createZone(newZone));
        setLoading(false);

        if (hasDispatchError(res)) {
            message.error(getDispatchError(res));
            return;
        }
        props.onSuccess(res.payload as Zone);
    }

    /* Set zone */
    const skip = props.zone ? ['zone'] : undefined;

    return (
        <GeneralSteps
            obj={zone}
            setObj={setZone}
            onFinish={onCreate}
            onCancel={props.onCancel}
            loading={loading}
            skip={skip}
        />
    );
};

export default CreateZoneSteps;
