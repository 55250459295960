import { useOrgUnits } from 'hooks/orgUnits';
import { usePositions } from 'hooks/positions';
import { useAppSelector } from 'store';
import { selectZoneStatus } from 'features/zoneSlice';
import { selectPeopleStatus } from 'features/personSlice';

import type { Zone, Team, Role, Organization, Person, Position, OrgUnit } from 'types';
import { map } from 'utils';

import { useZones } from './useZones';
import { useTeams } from './teams';
import { useRoles, useTeamRoles } from './roles';
import { usePeople } from './usePeople';
import { useOrg } from './useOrg';

export type ZoneChartData = {
    org: Organization;
    zones: Zone[];
    teams: Team[];
    roles: Role[];
    people: Person[];
};

export function useZoneChartData(): ZoneChartData | null {
    const zoneStatus = useAppSelector(selectZoneStatus);
    const peopleStatus = useAppSelector(selectPeopleStatus);

    const org = useOrg();
    const zones = useZones();
    const teams = useTeams();
    const roles = useRoles();
    const people = usePeople();

    if (zoneStatus !== 'succeeded' || teams == null || roles === null || peopleStatus !== 'succeeded' || !org) {
        return null;
    }

    return { org, zones, teams, roles, people: people ? people : [] };
}

export function useTeamGraphData(team: Team): { roles: Role[]; people: Person[] } | null {
    const peopleStatus = useAppSelector(selectPeopleStatus);

    const roles = useTeamRoles(team.id);
    const people = map(usePeople());
    const peopleWithRoles: Record<string, Person> = {};

    if (!roles || peopleStatus !== 'succeeded') {
        return null;
    }

    for (const role of roles) {
        if (role.assignment_id) {
            peopleWithRoles[role.assignment_id] = people[role.assignment_id];
        }
    }

    return { roles, people: Object.keys(peopleWithRoles).map((key) => peopleWithRoles[key]) };
}

export function useOrgUnitGraphData(): { people: Person[]; positions: Position[]; orgUnits: OrgUnit[] } | null {
    const people = usePeople();
    const positions = usePositions();
    const orgUnits = useOrgUnits();

    return people !== null && positions !== null && orgUnits !== null ? { people, positions, orgUnits } : null;
}
