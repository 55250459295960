import { useNavigate } from 'react-router-dom';
import { App } from 'antd';

import type { PlatformDispatch, Team } from 'types';
import { getDispatchError, getDispatchStatus, hasDispatchError } from 'utils';

import { AppDispatch, useAppDispatch } from 'store';
import { deleteTeam } from 'features/teamSlice';
import BaseDeleteButton from 'components/buttons/BaseDeleteButton';
import Show from 'components/Show';
import { useTeamDeletePermission } from 'hooks/permissions';

const DeleteTeamButton = (props: { teamId: string }) => {
    const dispatch: AppDispatch = useAppDispatch();
    const { message } = App.useApp();
    const navigate = useNavigate();

    async function onConfirm() {
        const res = (await dispatch(deleteTeam(props.teamId) as any)) as PlatformDispatch<Team>;
        if (hasDispatchError(res)) {
            if (getDispatchStatus(res) === 400) {
                message.error('The team is not empty and cannot be deleted.');
            } else {
                message.error(getDispatchError(res));
            }
            return;
        }
        message.success('Your team was successfully deleted.');
        navigate('/teams', { replace: true });
    }

    return (
        <Show permission={useTeamDeletePermission(props.teamId)}>
            <BaseDeleteButton objectType={'team'} onConfirm={onConfirm} />
        </Show>
    );
};

export default DeleteTeamButton;
