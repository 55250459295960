import { useElementSize } from '@custom-react-hooks/use-element-size';
import { type ReactNode } from 'react';

const GraphContainer = (props: { onClick?: () => void; children: (width: number, height: number) => ReactNode }) => {
    const [setRef, size] = useElementSize();

    return (
        <div ref={setRef} style={{ width: '100%', height: '100%' }} onClick={props.onClick}>
            {!!(size.width && size.height) ? props.children(size.width, size.height) : null}
        </div>
    );
};

export default GraphContainer;
