import { Button, Space, Tooltip } from 'antd';
import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import { type ObjectType } from 'types';
import styles from './styles.module.scss';
import ObjectDrawerEditButton from './ObjectDrawerEditButton';

const ObjectDrawerCloseButton = (props: { onClose: () => void }) => {
    return (
        <Tooltip title={'Close'}>
            <Button shape="circle" icon={<CloseOutlined />} onClick={props.onClose} />
        </Tooltip>
    );
};

const ObjectDrawerButtons = (props: {
    teamId?: string;
    objectType: ObjectType;
    edit: boolean;
    setEdit: (value: boolean) => void;
    onClose: () => void;
}) => {
    return (
        <Space className={styles.buttons}>
            <ObjectDrawerEditButton
                objectType={props.objectType}
                icon={props.edit ? <CloseOutlined /> : <EditOutlined />}
                onClick={() => props.setEdit(!props.edit)}
            />
            {!props.edit && <ObjectDrawerCloseButton onClose={props.onClose} />}
        </Space>
    );
};

export default ObjectDrawerButtons;
