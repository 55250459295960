import PersonAvatar from 'components/avatar/PersonAvatar';
import { usePerson } from 'hooks/usePeople';
import { CSSProperties, useState } from 'react';
import { Card, Flex, Typography } from 'antd';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { AgendaItem, Meeting, MeetingColumn } from 'types';
import AgendaItemForm from 'components/forms/AgendaItemForm';
import CreateEditModal from 'components/steps/common/CreateEditModal';

import styles from './styles.module.scss';

const AgendaItemCard = (props: { meeting: Meeting; meetingColumn?: MeetingColumn; agendaItem: AgendaItem }) => {
    const [open, setOpen] = useState(false);
    const person = usePerson(props.agendaItem.owner_id);

    const { isDragging, attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id: props.agendaItem.id,
        data: { column: props.meetingColumn },
    });

    const onClick = () => setOpen(true);
    const onDone = () => setOpen(false);

    const style: CSSProperties = {
        flex: 'none',
        transform: CSS.Translate.toString(transform),
        transition: transition,
        zIndex: isDragging ? 9999 : undefined,
        visibility: isDragging ? 'hidden' : 'visible', // Show card only in DragOverlay
    };

    return (
        <>
            <Card
                id={props.agendaItem.id}
                data-order={props.agendaItem.n}
                style={style}
                ref={setNodeRef}
                {...listeners}
                {...attributes}
                className={`agenda-item ${styles.agendaItem}`}
                size={'small'}
                onClick={onClick}
            >
                <Flex justify={'space-between'} align={'center'} gap={8}>
                    <Typography.Text strong>{props.agendaItem.name}</Typography.Text>
                    {person ? (
                        <div>
                            <PersonAvatar person={person} />
                        </div>
                    ) : null}
                </Flex>
            </Card>
            <CreateEditModal open={open} title={'Update Agenda Item'} onCancel={onDone} width={500}>
                <AgendaItemForm {...props} onFinish={onDone} onCancel={onDone} onDelete={onDone} />
            </CreateEditModal>
        </>
    );
};

export default AgendaItemCard;
