import React, { CSSProperties, useState } from 'react';
import { Button, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import CreateEditModal from 'components/steps/common/CreateEditModal';

export type CreateButtonType = 'default' | 'icon';

const BaseCreateButton = (props: {
    text: string;
    type?: CreateButtonType;
    style?: CSSProperties;
    children: (onSuccess: () => void, onClose: () => void) => React.ReactNode;
    onSuccess?: () => void;
}) => {
    const type = props.type ? props.type : 'default';

    const [openModal, setOpenModal] = useState(false);
    const [openTooltip, setOpenTooltip] = useState(false);

    async function onSuccess() {
        setOpenModal(false);
        setOpenTooltip(false);
        props.onSuccess?.();
    }

    function onClose() {
        setOpenModal(false);
        setOpenTooltip(false);
    }

    function onClick() {
        setOpenTooltip(false);
        setOpenModal(true);
    }

    return (
        <Tooltip title={type === 'icon' ? undefined : props.text} open={openTooltip} onOpenChange={setOpenTooltip}>
            <Button
                style={props.style}
                type={'primary'}
                shape={type === 'default' ? 'default' : 'circle'}
                size={'small'}
                icon={<PlusOutlined />}
                onClick={onClick}
                tabIndex={openModal ? -1 : undefined}
            >
                {type === 'default' ? props.text : undefined}
            </Button>
            <CreateEditModal title={props.text} open={openModal} onCancel={onClose}>
                {props.children(onSuccess, onClose)}
            </CreateEditModal>
        </Tooltip>
    );
};

export default BaseCreateButton;
