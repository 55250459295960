import { Typography, Flex } from 'antd';
import PersonAvatar from 'components/avatar/PersonAvatar';
import CardLink from 'components/cards/CardLink';
import { getAllocation } from 'components/cards/utils';
import ProgressChart from 'components/chart/ProgressChart';

import teamStyles from 'pages/teams/cards/styles.module.scss'; // FIXME
import styles from './styles.module.scss';

import type { Role } from 'types';
import { usePeople } from 'hooks/usePeople';
import { isDefined, isNumber, map } from 'utils';

const RoleCard = (props: { role: Role; onClick?: () => void; showAvatar?: boolean }) => {
    const people = map(usePeople());
    const assignee = props.role.assignment_id ? people[props.role.assignment_id] : undefined;
    const showAvatar = isDefined(props.showAvatar) ? props.showAvatar : true;
    const allocation = getAllocation(props.role);

    return (
        <CardLink className={`${teamStyles.card} ${styles.card} ${styles.role}`} onClick={props.onClick}>
            <Flex className={'w-100'} align={'center'}>
                {showAvatar ? <PersonAvatar person={assignee} /> : null}
                <div className="card-info">
                    {/*
                        <span className='designation'>Team Lead</span>
                        */}
                    <Typography.Title level={3}>{props.role.name}</Typography.Title>
                    {/*
                    <div className="status">
                        <i></i>

                        <span>Active</span>

                            <label className='checkbox'>
                                <CheckOutlined />
                            </label>

                    </div>
                    */}
                </div>
                {isNumber(allocation) ? <ProgressChart progress={allocation} /> : null}
            </Flex>
            {/*
                <div className='link-icon'>
                    <MoreOutlined />
                </div>
                */}
        </CardLink>
    );
};

export default RoleCard;
