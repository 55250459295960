import DonutChart from 'components/chart/DonutChart';

const ProgressChart = (props: { progress: number; size?: number; fontSize?: string }) => {
    const size = props.size ? props.size : 40;
    const progress = Math.min(Math.max(props.progress, 0), 100);
    const data = [{ value: progress }];
    if (progress < 100) {
        data.push({ value: 100 - progress });
    }

    return <DonutChart size={size} fontSize={props.fontSize} data={data} text={`${progress}`} />;
};

export default ProgressChart;
