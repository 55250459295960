import { Form, Input } from 'antd';
import { buildLinkValue, parseLinkValue } from 'components/forms/common/utils';
import { useFocusRef } from 'hooks/focus';
import React, { useState } from 'react';
import { TemplateField, LinkField } from 'types';
import { fieldLabel, getField } from 'utils';

/* Use a markdown representation of the link */
const LinkFormItem = (props: {
    templateField: TemplateField;
    value: string | undefined;
    setValue: (value: string | null) => void;
    rows?: number;
    autoFocus?: boolean;
}) => {
    const ref = useFocusRef(props.autoFocus);

    const match = parseLinkValue(props.value);

    const [href, setHref] = useState(match.href);
    const [text, setText] = useState(match.text);

    const linkField = getField(props.templateField) as LinkField;

    const onLinkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value ? event.target.value : '';
        setHref(value);
        props.setValue(buildLinkValue({ href: value, text }));
    };

    const onTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value ? event.target.value : '';
        setText(value);
        props.setValue(buildLinkValue({ href, text: value }));
    };

    return (
        <Form.Item
            className={'form-control'}
            label={fieldLabel(linkField.name)}
            rules={[{ required: props.templateField.required, message: 'Please enter a value.' }]}
            required={props.templateField.required}
        >
            <Form.Item label={'URL'} layout={'horizontal'}>
                <Input ref={ref} value={href} onChange={onLinkChange} />
            </Form.Item>
            <Form.Item label={'Text'} layout={'horizontal'}>
                <Input value={text} onChange={onTextChange} />
            </Form.Item>
        </Form.Item>
    );
};

export default LinkFormItem;
