import { Select } from 'antd';

export type TeamGraphName = 'team-roles-2' | 'team-roles-3' | 'team-roles-4';

const TeamGraphSelect = (props: { value: TeamGraphName | undefined; onChange: (value: TeamGraphName) => void }) => {
    const value = props.value ? props.value : 'team-roles-3';
    const options = [
        { value: 'team-roles-2', label: <span>team-roles-2</span> },
        { value: 'team-roles-3', label: <span>team-roles-3</span> },
        /* { value: 'team-roles-4', label: <span>team-roles-4</span> }, */
    ];
    return <Select options={options} value={value} onChange={props.onChange} />;
};

export default TeamGraphSelect;
