import PasswordFormItem from 'pages/auth/common/PasswordFormItem';
import { LoginFormResponse } from 'pages/auth/common/types';
import UsernameFormItem from 'pages/auth/common/UsernameFormItem';
import ForgotPasswordLink from 'pages/auth/login/ForgotPasswordLink';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { App, Form, Button, Typography } from 'antd';

import type { OtpSentErrorDetail } from 'types';
import { getErrorMessage } from 'utils';

import { useThemeMode } from 'hooks/useThemeMode';

import logo from 'assets/logo-light.png';
import logoDark from 'assets/logo-dark.png';

type LoginFormRequest = {
    username: string;
    password: string;
};

const LoginForm = (props: { onLogin?: (data: LoginFormResponse) => void }) => {
    const { message } = App.useApp();
    const navigate = useNavigate();
    const username = sessionStorage.getItem('username');

    const themeMode = useThemeMode();
    const src = themeMode === 'light' ? logoDark : logo;

    async function onFinish(values: LoginFormRequest) {
        const formData = new FormData();
        formData.append('username', values.username);
        formData.append('password', values.password);

        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/auth/login`, {
                method: 'POST',
                body: formData,
                credentials: 'include',
            });
            if (res.status !== 200) {
                if (res.status === 403) {
                    const data = (await res.json()) as OtpSentErrorDetail;
                    if (data.detail.reason === 'otp-sent') {
                        localStorage.setItem('username', values.username);
                        localStorage.setItem('channel', data.detail.channel);
                        navigate('/mfa');
                        return;
                    }
                }

                message.error('Invalid Username or Password.');
            } else {
                props.onLogin?.((await res.json()) as LoginFormResponse);
            }
        } catch (e) {
            message.error(
                <>
                    <b>[Unknown Network Error]</b> {getErrorMessage(e)}
                </>,
            );
        }
    }

    return (
        <>
            <div className="login-logo">
                <img src={src} alt={'Viggo'} />
            </div>
            <Form<LoginFormRequest>
                name="login"
                initialValues={{
                    username: username ? username : undefined,
                }}
                onFinish={onFinish}
            >
                <Typography.Title>Login</Typography.Title>
                <UsernameFormItem autoFocus={true} />
                <PasswordFormItem style={{ marginBottom: 0 }} />
                <ForgotPasswordLink />

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Login
                    </Button>
                </Form.Item>

                {/*
                FIXME: replace with true T&Cs + privacy policy
                <div>
                    <small>
                        By logging in, you consent to being contacted via SMS for multi-factor authentication.
                    </small>
                </div>
                */}
            </Form>
        </>
    );
};

export default LoginForm;

/*
<Form.Item name="remember" valuePropName="checked" noStyle>
    <Checkbox>Remember me</Checkbox>
</Form.Item>
 */
