import EditPersonButton from 'components/buttons/EditPersonButton';
import React from 'react';
import { Card, Descriptions, DescriptionsProps, Typography } from 'antd';

import styles from './styles.module.scss';
import { usePeople } from 'hooks/usePeople';
import { Person } from 'types';
import { isPersonSystem, map, userTypeDisplay } from 'utils';

const PeopleInfoCard = (props: { person: Person }) => {
    const people = map(usePeople());

    /* system users don't have reports_to and other org specific data.   This needs to be sorted. */
    const person = isPersonSystem(props.person) && people[props.person.id] ? people[props.person.id] : props.person;
    const reportsTo = person.reports_to_id ? people[person.reports_to_id] : null;

    const items: DescriptionsProps['items'] = [
        {
            key: 'email',
            label: 'Email',
            children: <Typography.Text>{person.email}</Typography.Text>,
            span: 'filled',
        },
    ];

    if (person.first_name) {
        items.push({
            key: 'firstName',
            label: 'First Name',
            children: <Typography.Text>{person.first_name}</Typography.Text>,
            span: 'filled',
        });
    }

    if (person.last_name) {
        items.push({
            key: 'lastName',
            label: 'Last Name',
            children: <Typography.Text>{person.last_name}</Typography.Text>,
            span: 'filled',
        });
    }

    if (person.phone) {
        items.push({
            key: 'phone',
            label: 'Phone',
            children: <Typography.Text>{person.phone}</Typography.Text>,
            span: 'filled',
        });
    }

    if (person.city) {
        items.push({
            key: 'city',
            label: 'City',
            children: person.city,
            span: 'filled',
        });
    }

    if (person.state) {
        items.push({
            key: 'state',
            label: 'State',
            children: person.state,
            span: 'filled',
        });
    }

    if (person.country_code) {
        items.push({
            key: 'countryCode',
            label: 'Country',
            children: person.country_code,
            span: 'filled',
        });
    }

    if (person.timezone) {
        items.push({
            key: 'timezone',
            label: 'Timezone',
            children: person.timezone,
            span: 'filled',
        });
    }

    if (person.state) {
        items.push({
            key: 'status',
            label: 'Status',
            children: person.status,
            span: 'filled',
        });
    }

    if (person.title) {
        items.push({
            key: 'title',
            label: 'Title',
            children: <Typography.Text>{person.title}</Typography.Text>,
            span: 'filled',
        });
    }

    if (reportsTo) {
        items.push({
            key: 'reportsTo',
            label: 'Reports To',
            children: <Typography.Link href={`/people/${reportsTo.id}`}>{reportsTo.name}</Typography.Link>,
            span: 'filled',
        });
    }

    items.push({
        key: 'userType',
        label: 'User Type',
        children: <Typography.Text>{userTypeDisplay(person.user_type)}</Typography.Text>,
        span: 'filled',
    });

    return (
        <Card>
            <Descriptions
                className={styles.people}
                title="User Info"
                items={items}
                bordered
                extra={<EditPersonButton person={person} />}
            />
        </Card>
    );
};

export default PeopleInfoCard;
