import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { Template, SliceStatus, Organization, ErrorDetail, CreateEditTemplate } from 'types';
import { CLEAR_ORG_ACTION, LOGOUT_ACTION, RootState } from 'store';
import apiClient from 'api';

type TemplatesState = {
    entities: Template[];
    status: SliceStatus;
    error: string;
};

export const fetchTemplates = createAsyncThunk<Template[]>('templates/fetch', async (_, thunkAPI) => {
    const state = thunkAPI.getState() as { org: { value: Organization } };
    return await apiClient.get(`/org/${state.org.value.id}/templates`);
});

export const deleteTemplate = createAsyncThunk<
    Template,
    string,
    {
        rejectValue: ErrorDetail;
    }
>('template/delete', async (templateId, thunkAPI) => {
    const state = thunkAPI.getState() as { org: { value: Organization } };
    try {
        return await apiClient.delete<Template>(`/org/${state.org.value.id}/templates/${templateId}`);
    } catch (e) {
        return thunkAPI.rejectWithValue(e as ErrorDetail);
    }
});

export const createTemplate = createAsyncThunk<
    Template,
    CreateEditTemplate,
    {
        rejectValue: ErrorDetail;
    }
>('template/create', async (template, thunkAPI) => {
    const state = thunkAPI.getState() as { org: { value: Organization } };
    try {
        return await apiClient.post(`/org/${state.org.value.id}/templates`, template);
    } catch (e) {
        return thunkAPI.rejectWithValue(e as ErrorDetail);
    }
});

export const updateTemplate = createAsyncThunk<
    Template,
    CreateEditTemplate,
    {
        rejectValue: ErrorDetail;
    }
>('template/update', async (templateData, thunkAPI) => {
    const state = thunkAPI.getState() as { org: { value: Organization } };
    try {
        return await apiClient.patch(`/org/${state.org.value.id}/templates/${templateData.id}`, templateData);
    } catch (e) {
        return thunkAPI.rejectWithValue(e as ErrorDetail);
    }
});

const INITIAL_STATE = {
    entities: [],
    status: 'idle' as TemplatesState['status'],
    error: '',
};

// noinspection JSUnusedGlobalSymbols
const templateSlice = createSlice({
    name: 'templates',
    initialState: INITIAL_STATE satisfies TemplatesState as TemplatesState,
    reducers: {
        clearError: (state) => {
            state.error = '';
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchTemplates.pending, (state: TemplatesState) => {
                state.status = 'pending';
            })
            .addCase(fetchTemplates.fulfilled, (state: TemplatesState, action: PayloadAction<Template[]>) => {
                state.status = 'succeeded';
                state.entities = action.payload as Template[];
            })
            .addCase(fetchTemplates.rejected, (state: TemplatesState, action) => {
                state.status = 'failed';
                //state.error = action.error.message;
                console.log(action);
            })
            .addCase(
                createTemplate.fulfilled,
                (state: TemplatesState, action: PayloadAction<Template | ErrorDetail>) => {
                    state.entities.push(action.payload as Template);
                },
            )
            .addCase(deleteTemplate.fulfilled, (state: TemplatesState, action) => {
                state.entities = state.entities.filter((user: Template) => user.id !== action.payload.id);
            })
            .addCase(updateTemplate.fulfilled, (state: TemplatesState, action) => {
                const template = action.payload as Template;
                const index = state.entities.findIndex((entity) => entity.id === template.id);
                state.entities[index] = template;
            })
            .addCase(CLEAR_ORG_ACTION, (state: TemplatesState) => {
                Object.assign(state, INITIAL_STATE);
            })
            .addCase(LOGOUT_ACTION, (state: TemplatesState) => {
                Object.assign(state, INITIAL_STATE);
            });
    },
});

export const selectTemplates = (state: RootState) => state.templates.entities;
export const selectTemplateStatus = (state: RootState) => state.templates.status;

export default templateSlice.reducer;
