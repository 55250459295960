import { App, Form, Button } from 'antd';
import { useEffect, useState } from 'react';

import type { Person } from 'types';
import { getErrorMessage, isString } from 'utils';
import apiClient, { API_URL } from 'api';

import NewPasswordFormItem from 'pages/auth/common/NewPasswordFormItem';
import UsernameFormItem from 'pages/auth/common/UsernameFormItem';
import PasswordConfirmFormItem from 'pages/auth/common/PasswordConfirmFormItem';

type ResetPasswordRequest = {
    password: string;
};

const ResetPasswordForm = (props: {
    token: string;
    onReset: (data: Person) => void;
    onError?: (status: number, message: string) => void;
}) => {
    const [error, setError] = useState('');
    const token = props.token;
    const { message } = App.useApp();
    const [form] = Form.useForm();

    useEffect(() => {
        const getData = async () => {
            return await apiClient.get<{ username: string }>(`/auth/reset_password?token=${token}`);
        };

        getData()
            .then((data) => form.setFieldValue('username', data.username))
            .catch((_) => setError('Your token is expired or invalid.  Please reset your password again.'));
    }, [token, form, message]);

    async function onFinish(values: ResetPasswordRequest) {
        try {
            const res = await fetch(`${API_URL}/auth/reset_password`, {
                method: 'POST',
                body: JSON.stringify({
                    token: props.token,
                    password: values.password,
                }),
                headers: { 'Content-Type': 'application/json' },
            });
            if (res.status !== 200) {
                const error = await res.json();
                const errorMessage = error.detail && isString(error.detail) ? error.detail : res.statusText;
                props.onError ? props.onError(res.status, errorMessage) : message.error(res.statusText);
            } else {
                props.onReset((await res.json()) as Person);
            }
        } catch (e) {
            message.error(
                <>
                    <b>[Unknown Network Error]</b> {getErrorMessage(e)}
                </>,
            );
        }
    }

    return (
        <Form form={form} name="reset-password" onFinish={onFinish} noValidate>
            {error ? (
                <Form.Item>
                    <strong className={'text-danger'}>{error}</strong>
                </Form.Item>
            ) : null}
            <UsernameFormItem style={{ display: 'none' }} readOnly={true} />
            <NewPasswordFormItem autoFocus={true} />
            <PasswordConfirmFormItem autoComplete={'new-password'} />

            <Form.Item>
                <Button type="primary" htmlType="submit" className="register-form-button">
                    Update Password
                </Button>
            </Form.Item>
        </Form>
    );
};

export default ResetPasswordForm;
