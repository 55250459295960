import { useField } from 'hooks/fields';
import TemplateFieldStyleFormItems from 'pages/settings/templates/edit/TemplateFieldStyleFormItems';
import { isRelationshipFieldType } from 'utils';

const TemplateFieldStyle = (props: { fieldId: string }) => {
    const field = useField(props.fieldId);

    if (!isRelationshipFieldType(field?.type)) {
        return null;
    }

    return <TemplateFieldStyleFormItems />;
};

export default TemplateFieldStyle;
