import { useEffect, useRef } from 'react';

export function useFocusRef(autoFocus: boolean = false) {
    const ref = useRef(null);

    useEffect(() => {
        if (ref.current && autoFocus) {
            (ref.current as { focus: () => void }).focus();
        }
    }, [autoFocus]);

    return ref;
}

export function useSelectFocusRef(autoFocus: boolean = false) {
    const ref = useRef<any>(null);

    useEffect(() => {
        if (ref.current && autoFocus) {
            ref.current.focus();
            /* HACK: Antd seem to miss adding these on initial focus. */
            ref.current.nativeElement.classList.add('ant-select-outlined', 'ant-select-focused');
        }
    }, [autoFocus]);

    return ref;
}
