import { Form, Select } from 'antd';
import { buildObjectOptions } from 'components/forms/common/options';
import { useSelectFocusRef } from 'hooks/focus';
import { ObjectRef, OneToOneField, TemplateField } from 'types';
import { fieldLabel, getField } from 'utils';
import { useObjects } from 'hooks/useObjects';
import { filterOption } from './utils';

const OneToOneFormItem = (props: {
    templateField: TemplateField;
    objectRef: ObjectRef | undefined;
    setObjectRef: (ref: ObjectRef) => void;
    autoFocus?: boolean;
    excluded?: string[];
}) => {
    const ref = useSelectFocusRef(props.autoFocus);
    const field = getField(props.templateField) as OneToOneField;
    const objs = useObjects(field.config.allowed);

    const options = buildObjectOptions(objs, props.excluded ? props.excluded : []);
    const value = props.objectRef ? JSON.stringify({ id: props.objectRef.id, type: props.objectRef.type }) : undefined;

    return (
        <Form.Item
            className={'form-control'}
            label={fieldLabel(field.name)}
            rules={[{ required: props.templateField.required, message: 'Select a value.' }]}
            name={field.name}
            initialValue={value}
        >
            <Select
                ref={ref}
                options={options}
                onChange={(value) => props.setObjectRef(value ? JSON.parse(value) : null)}
                allowClear={!props.templateField.required}
                showSearch={true}
                filterOption={filterOption}
                autoFocus={props.autoFocus}
            />
        </Form.Item>
    );
};

export default OneToOneFormItem;
