import { ColorPicker, Form } from 'antd';
import LineWeightSelect from 'components/graph/common/LineWeightSelect';
import MarkerSelect from 'components/graph/common/MarkerSelect';
import LineStyleSelect from 'components/graph/common/LineStyleSelect';

const TemplateFieldColorPicker = (props: { value?: string; onChange?: (value: string) => void }) => {
    const value = props.value ? props.value : '#999';
    return <ColorPicker value={value} onChange={(color) => props.onChange?.(color.toHexString())} />;
};

const TemplateFieldStyleFormItems = () => {
    return (
        <>
            <Form.Item labelCol={{ span: 8 }} label="Color" name={['metadata', 'style', 'color']}>
                <TemplateFieldColorPicker />
            </Form.Item>
            <Form.Item labelCol={{ span: 8 }} label="Line style" name={['metadata', 'style', 'strokeDasharray']}>
                <LineStyleSelect />
            </Form.Item>
            <Form.Item labelCol={{ span: 8 }} label="Line width" name={['metadata', 'style', 'strokeWidth']}>
                <LineWeightSelect />
            </Form.Item>
            <Form.Item labelCol={{ span: 8 }} label="Marker" name={['metadata', 'marker']}>
                <MarkerSelect />
            </Form.Item>
        </>
    );
};

export default TemplateFieldStyleFormItems;
