import { Flex, Typography } from 'antd';

import type { BaseObject, Role } from 'types';
import { usePeople } from 'hooks/usePeople';
import { map } from 'utils';

const RoleTooltipContent = (props: { role: Role }) => {
    const people = map(usePeople());
    const assignee = props.role.assignment_id ? people[props.role.assignment_id] : undefined;

    return (
        <Flex vertical>
            {props.role.name}
            {assignee ? (
                <Typography.Link href={`/people/${props.role.assignment_id}`}>
                    <small>{assignee.name}</small>
                </Typography.Link>
            ) : (
                <small>[unassigned]</small>
            )}
        </Flex>
    );
};

const TeamNodeTooltipContent = (props: { obj: BaseObject }) => {
    return props.obj.type === 'role' ? <RoleTooltipContent role={props.obj as Role} /> : <>{props.obj.name}</>;
};

export default TeamNodeTooltipContent;
