import { Form } from 'antd';
import React from 'react';
import PhoneInput from 'antd-phone-input';

const PhoneFormItem = () => {
    return (
        <Form.Item
            className={'form-control'}
            name="phone"
            label={'Phone Number'}
            rules={[
                {
                    validator: (_, { valid }) => {
                        /* value is not a function when this field is still a string. */
                        if (!valid || valid()) return Promise.resolve(); // non-strict validation
                        return Promise.reject('Invalid phone number');
                    },
                },
            ]}
        >
            <PhoneInput enableSearch />
        </Form.Item>
    );
};

export default PhoneFormItem;
