import { Flex, Typography } from 'antd';

const OrgNoData = () => {
    return (
        <Flex style={{ height: '100%' }} vertical justify={'center'}>
            <Flex style={{ margin: '1em' }} justify={'center'}>
                <Typography.Paragraph style={{ fontWeight: 'bold', margin: 0 }}>No Data Found!</Typography.Paragraph>
            </Flex>
        </Flex>
    );
};

export default OrgNoData;
