import React, { CSSProperties } from 'react';
import { Select } from 'antd';
import styles from './styles.module.scss';

const DasharraySvg = (props: { width?: number; strokeDasharray?: CSSProperties['strokeDasharray'] }) => {
    const width = props.width ? props.width : 165;
    return (
        <svg height="30" width={width} xmlns="http://www.w3.org/2000/svg">
            <path
                strokeDasharray={props.strokeDasharray}
                d={`M10 15 ${width - 20} 15`}
                strokeWidth={4}
                stroke={'black'}
            />
        </svg>
    );
};

const OPTIONS = [
    { name: 'solid', value: 'none', label: <DasharraySvg /> },
    { name: 'dotted', value: '5,5', label: <DasharraySvg strokeDasharray={'5,5'} width={170} /> },
    { name: 'dashed', value: '10,10', label: <DasharraySvg strokeDasharray={'10,10'} /> },
    { name: 'o', value: '35,10', label: <DasharraySvg strokeDasharray={'35,10'} width={160} /> },
    { name: 'd', value: '20,10,5,5,5,10', label: <DasharraySvg strokeDasharray={'20,10,5,5,5,10'} width={140} /> },
];

const LineStyleSelect = (props: { value?: string; onChange?: (value: string) => void }) => {
    const value = props.value ? props.value : 'none';
    return (
        <Select
            className={styles.line}
            options={OPTIONS}
            value={value}
            onChange={props.onChange}
            dropdownRender={(menu) => (
                <div className={styles.dropdown}>
                    {React.cloneElement(
                        menu as React.ReactElement<{
                            style: React.CSSProperties;
                        }>,
                        { style: { height: '30px' } },
                    )}
                </div>
            )}
        />
    );
};

export default LineStyleSelect;
