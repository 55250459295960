import decision from './decision';
import role from './role';
import objective from './objective';
import org_unit from './org_unit';
import position from './position';
import team from './team';
import zone from './zone';
import { FieldWithRequired } from 'types';

export default { decision, role, objective, team, zone, org_unit, position } as Record<string, FieldWithRequired[]>;
