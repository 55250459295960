import TeamGraphSelect, { TeamGraphName } from 'pages/teams/TeamGraphSelect';
import React, { useState } from 'react';
import { Card, Typography } from 'antd';
import MarkdownDiv from 'components/MarkdownDiv';
import TeamDetailCardFooter from 'pages/teams/footer/TeamDetailCardFooter';
import TeamGraph from 'pages/teams/graph/TeamGraph';
import TeamGraphLegend from 'pages/teams/legend/TeamGraphLegend';
import styles from 'pages/teams/styles.module.scss';

import type { Team, TemplatedObject } from 'types';
const KEY = 'teamGraph';

const TeamGraphCard = (props: { team: Team; onClick?: (obj: TemplatedObject) => void }) => {
    const [graphName, setGraphName] = useState<TeamGraphName>(() => {
        return (localStorage.getItem(KEY) || 'team-roles-3') as TeamGraphName;
    });

    const onChange = (value: TeamGraphName) => {
        localStorage.setItem(KEY, value);
        setGraphName(value);
    };

    return (
        <Card className={styles.TeamChart}>
            <div className="card-header">
                <Typography.Title level={2}>{props.team.name}</Typography.Title>
            </div>
            <div className="card-body">
                <MarkdownDiv markdown={props.team.purpose} />
                <TeamGraph team={props.team} graphName={graphName} onClick={props.onClick} />
            </div>
            <TeamDetailCardFooter>
                <TeamGraphLegend />
                <TeamGraphSelect value={graphName} onChange={onChange} />
            </TeamDetailCardFooter>
        </Card>
    );
};

export default TeamGraphCard;
