import { useNavigate } from 'react-router-dom';
import { App } from 'antd';
import type { Role } from 'types';

import { getDispatchError, hasDispatchError, isDefined, isUndefined } from 'utils';
import BaseDeleteButton from 'components/buttons/BaseDeleteButton';
import Show from 'components/Show';
import { useRoleDeletePermission } from 'hooks/permissions';
import { useRole } from 'hooks/roles';

import { AppDispatch, useAppDispatch } from 'store';
import { deleteRole } from 'features/roleSlice';

const DeleteRoleButton = (props: { roleId: string; navigate?: string | boolean; onDelete?: (role: Role) => void }) => {
    const role = useRole(props.roleId);

    const { message } = App.useApp();
    const dispatch: AppDispatch = useAppDispatch();
    const navigate = useNavigate();

    async function onConfirm() {
        const res = await dispatch(deleteRole(props.roleId));
        if (hasDispatchError(res)) {
            message.error(getDispatchError(res));
            return;
        }

        props.onDelete?.(res.payload as Role);
        if (isDefined(props.navigate) && props.navigate) {
            navigate(props.navigate as string, { replace: true });
        } else if (isUndefined(props.navigate)) {
            navigate('/roles', { replace: true });
        }
        message.success('Your role was successfully deleted.');
    }

    return (
        <Show permission={useRoleDeletePermission(role?.team.id)}>
            <BaseDeleteButton objectType={'role'} onConfirm={onConfirm} />
        </Show>
    );
};

export default DeleteRoleButton;
