import BaseDeleteButton from 'components/buttons/BaseDeleteButton';
import { useNavigate } from 'react-router-dom';
import { App } from 'antd';

import { getDispatchError, getDispatchStatus, hasDispatchError } from 'utils';
import Show from 'components/Show';
import { useZoneDeletePermission } from 'hooks/permissions';

import { AppDispatch, useAppDispatch } from 'store';
import { deleteZone } from 'features/zoneSlice';

const DeleteZoneButton = (props: { zoneId: string; onDelete?: () => void }) => {
    const dispatch: AppDispatch = useAppDispatch();
    const { message } = App.useApp();
    const navigate = useNavigate();

    async function onConfirm() {
        const res = await dispatch(deleteZone(props.zoneId) as any);
        if (hasDispatchError(res)) {
            if (getDispatchStatus(res) === 400) {
                message.error('The zone is not empty and cannot be deleted.');
            } else {
                message.error(getDispatchError(res));
            }
            return;
        }
        message.success('Your zone was successfully deleted.');
        navigate('/zones', { replace: true });
    }

    return (
        <Show permission={useZoneDeletePermission()}>
            <BaseDeleteButton objectType={'zone'} onConfirm={onConfirm} />
        </Show>
    );
};

export default DeleteZoneButton;
