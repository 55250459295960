import CreateEditModal from 'components/steps/common/CreateEditModal';
import CreateOrgUnitSteps from 'components/steps/CreateOrgUnitSteps';
import CreatePositionSteps from 'components/steps/CreatePositionSteps';
import { useOrgUnit } from 'hooks/orgUnits';
import type { GFDGGroup, GFDGNode } from 'pages/orgUnit/gfdg/types';
import { isGFDGGroup, isGFDGNode } from 'pages/orgUnit/gfdg/utils';
import type { OrgUnit, Position } from 'types';

const CreatePositionBase = (props: { orgUnit?: OrgUnit; reportsTo?: Position; onClose?: () => void }) => (
    <CreateEditModal title={'Create Position'} open={true} onCancel={() => props.onClose?.()}>
        <CreatePositionSteps
            orgUnit={props.orgUnit}
            reportsTo={props.reportsTo}
            onSuccess={() => props.onClose?.()}
            onCancel={() => props.onClose?.()}
        />
    </CreateEditModal>
);

const CreatePositionFromNode = (props: { node?: GFDGNode | null; onClose?: () => void }) => {
    const position = props.node ? (props.node.obj as Position) : undefined;
    const orgUnit = useOrgUnit(position?.org_unit?.id);

    return <CreatePositionBase orgUnit={orgUnit || undefined} reportsTo={position} onClose={props.onClose} />;
};

const CreatePosition = (props: { context: GFDGNode | GFDGGroup | null; onClose?: () => void }) => {
    if (isGFDGNode(props.context)) {
        return <CreatePositionFromNode node={props.context} onClose={props.onClose} />;
    }

    const orgUnit = isGFDGGroup(props.context) ? (props.context.obj as OrgUnit) : undefined;
    return <CreatePositionBase orgUnit={orgUnit} onClose={props.onClose} />;
};

const OrgUnitActions = (props: {
    action: 'add-position' | 'add-org-unit';
    context: GFDGNode | GFDGGroup | null;
    onClose?: () => void;
}) => {
    switch (props.action) {
        case 'add-position':
            return <CreatePosition context={props.context} onClose={props.onClose} />;
        case 'add-org-unit':
            return (
                <CreateEditModal title={'Create Org Unit'} open={true} onCancel={() => props.onClose?.()}>
                    <CreateOrgUnitSteps
                        orgUnit={props.context ? ((props.context as GFDGGroup).obj as OrgUnit) : undefined}
                        onSuccess={() => props.onClose?.()}
                        onCancel={() => props.onClose?.()}
                    />
                </CreateEditModal>
            );
    }
};

export default OrgUnitActions;
