import { Flex } from 'antd';
import PersonAvatar from 'components/avatar/PersonAvatar';
import { usePerson } from 'hooks/usePeople';
import { useThemeMode } from 'hooks/useThemeMode';
import { GFDGNode } from 'pages/orgUnit/gfdg/types';
import { Position } from 'types';
import styles from './styles.module.scss';

const OrgUnitNode = (props: { node: GFDGNode }) => {
    const mode = useThemeMode();
    const position = props.node.obj as Position;
    const person = usePerson(position.person_id);

    return (
        <Flex
            className={`${styles.node} ${mode}`}
            vertical
            justify={'center'}
            align={'center'}
            style={{
                height: `${props.node.height}px`,
            }}
        >
            <PersonAvatar person={person} />
            {props.node.obj.name}
        </Flex>
    );
};

export default OrgUnitNode;
