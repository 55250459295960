import { UserOutlined } from '@ant-design/icons';
import { Avatar, type AvatarProps, type ImageProps } from 'antd';
import { useThemeMode } from 'hooks/useThemeMode';
import type { Person } from 'types';
import { initials } from 'utils';

const PersonAvatar = (props: { person?: Person; draggable?: ImageProps['draggable']; size?: AvatarProps['size'] }) => {
    const mode = useThemeMode();
    const color = mode === 'dark' ? 'rgb(140, 140, 140)' : 'rgba(0, 0, 0, 0.25)';
    if (!props.person) {
        return (
            <Avatar
                size={props.size}
                draggable={props.draggable}
                style={{ border: `1px solid ${color}`, color, backgroundColor: 'transparent' }}
                icon={<UserOutlined />}
            />
        );
    }
    if (props.person.picture_medium) {
        return <Avatar size={props.size} draggable={props.draggable} src={props.person.picture_medium} />;
    }
    return <Avatar style={{}}>{initials(props.person.name)}</Avatar>;
};

export default PersonAvatar;
