import { Card, type CardProps, Flex, Space } from 'antd';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { isPersonViewer } from 'utils';

import styles from './styles.module.scss';

const DangerCard = (props: CardProps) => {
    const currentUser = useCurrentUser();
    if (isPersonViewer(currentUser)) return null;

    return (
        <Card size="small" className={`${styles.card} no-head-border`}>
            <Flex vertical align={'center'}>
                <Space className={styles.space} direction={'vertical'} align={'center'} style={{ margin: '0 auto' }}>
                    {props.children}
                </Space>
            </Flex>
        </Card>
    );
};

export default DangerCard;
