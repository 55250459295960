import React from 'react';
import { useParams } from 'react-router-dom';

import Loading from 'components/Loading';
import { useZoneChartData } from 'hooks/data';
import ZoneChart from 'pages/zones/ZoneChart';
import ZoneNoData from 'pages/zones/ZoneNoData';

const ZoneChartPage = () => {
    const { zoneId } = useParams();
    const objs = useZoneChartData();

    if (!objs) {
        return <Loading />;
    }

    if (objs['zones'].length === 0) {
        return <ZoneNoData />;
    }

    return <ZoneChart objs={objs} zoneId={zoneId} />;
};

export default ZoneChartPage;
