import ObjectDrawer from 'components/drawers/ObjectDrawer';
import OrgUnitActions from 'pages/orgUnit/OrgUnitActions';
import React, { useState } from 'react';
import { useElementSize } from '@custom-react-hooks/use-element-size';

import { useOrgUnitGraphData } from 'hooks/data';
import { GFDGGroup, GFDGNode } from 'pages/orgUnit/gfdg/types';
import OrgUnitContextMenu, { type OrgUnitContextMenuItem } from 'pages/orgUnit/OrgUnitContextMenu';
import GroupedForceDirectedGraph from './gfdg/GroupedForceDirectedGraph';

import { orgUnitData } from './data';
import OrgUnitNoData from './OrgUnitNoData';
import OrgUnitNode from './OrgUnitNode';

type ContextMenu = {
    target: GFDGNode | GFDGGroup | null;
    x: number;
    y: number;
};

const OrgUnitGraph = () => {
    const [setRef, size] = useElementSize();
    const data = useOrgUnitGraphData();

    const [menu, setMenu] = useState<ContextMenu>();
    const [action, setAction] = useState<'add-position' | 'add-org-unit'>();
    const [selected, setSelected] = useState<GFDGNode | GFDGGroup | null>(null);

    const onClose = () => {
        setSelected(null);
        setAction(undefined);
        setMenu(undefined);
    };

    const onClick = (target: GFDGNode | GFDGGroup | null) => {
        setSelected(target);
        setAction(undefined);
        setMenu(undefined);
    };

    const onContextMenu = (target: GFDGNode | GFDGGroup | null, x: number, y: number) => {
        setMenu({ target, x, y });
    };

    const onMenuClick = (item: OrgUnitContextMenuItem) => {
        if (item) {
            setAction(item.key as 'add-position' | 'add-org-unit');
        }
    };

    const onNodeContent = (node: GFDGNode) => {
        return <OrgUnitNode node={node} />;
    };

    const onTooltipText = (group: GFDGGroup) => {
        return group.obj.name;
    };

    if (data && data.positions.length === 0) {
        return <OrgUnitNoData />;
    }

    return (
        <div ref={setRef} style={{ width: '100%', height: '100%' }} onClick={onClose}>
            {size.width && size.height && data ? (
                <GroupedForceDirectedGraph
                    width={size.width}
                    height={size.height}
                    data={orgUnitData(data)}
                    onClick={onClick}
                    onContextMenu={onContextMenu}
                    onNodeContent={onNodeContent}
                    onTooltipText={onTooltipText}
                    idealLength={180}
                />
            ) : null}
            {menu && !action ? <OrgUnitContextMenu {...menu} onClick={onMenuClick} /> : null}
            {menu && action ? <OrgUnitActions action={action} context={menu.target} onClose={onClose} /> : null}
            {selected && <ObjectDrawer objectId={selected.obj.id} objectType={selected.obj.type} onClose={onClose} />}
        </div>
    );
};

export default OrgUnitGraph;
