import { fieldLabel, getField, isCustomTemplateField } from 'utils';
import type {
    CreateObject,
    Position,
    Role,
    Template,
    TemplateCustomField,
    TemplateField,
    TemplateNativeField,
} from 'types';

import NameFormItem from 'components/forms/common/NameFormItem';

import type { CreateStepProps } from './CreateEditSteps';
import MultiSelectFormItem from 'components/forms/common/MultiSelectFormItem';
import OneToManyFormItem from 'components/forms/common/OneToManyFormItem';
import OneToOneFormItem from 'components/forms/common/OneToOneFormItem';
import PersonFormItem from 'components/forms/common/PersonFormItem';
import SingleSelectFormItem from 'components/forms/common/SingleSelectFormItem';
import TextAreaFormItem from 'components/forms/common/TextAreaFormItem';
import LinkFormItem from 'components/forms/common/LinkFormItem';
import NumberFormItem from 'components/forms/common/NumberFormItem';

function templateCustomFieldToStep(
    templateField: TemplateField,
    obj: CreateObject,
    setObj: (obj: any) => void,
): CreateStepProps {
    const field = isCustomTemplateField(templateField)
        ? (templateField as TemplateCustomField).custom_field
        : (templateField as TemplateNativeField).native_field;
    switch (field.type) {
        case 'text':
            return {
                title: fieldLabel(field.name),
                content: (
                    <TextAreaFormItem
                        templateField={templateField}
                        value={obj[field.name]}
                        setValue={(value) => {
                            setObj({ ...obj, [field.name]: value });
                        }}
                        autoFocus
                    />
                ),
                valid: () => !templateField.required || !!obj[field.name],
            };
        case 'link':
            return {
                title: fieldLabel(field.name),
                content: (
                    <LinkFormItem
                        templateField={templateField}
                        value={obj[field.name]}
                        setValue={(value) => {
                            setObj({ ...obj, [field.name]: value });
                        }}
                        autoFocus
                    />
                ),
                valid: () => !templateField.required || !!obj[field.name],
            };
        case 'number':
            return {
                title: fieldLabel(field.name),
                content: (
                    <NumberFormItem
                        templateField={templateField}
                        value={obj[field.name]}
                        setValue={(value) => {
                            setObj({ ...obj, [field.name]: value });
                        }}
                        autoFocus
                    />
                ),
                valid: () => !templateField.required || NumberFormItem.valid(obj[field.name], templateField),
            };
        case 'single-select':
            return {
                title: fieldLabel(field.name),
                content: (
                    <SingleSelectFormItem
                        templateField={templateField}
                        value={obj[field.name]}
                        setValue={(value) => {
                            setObj({ ...obj, [field.name]: value });
                        }}
                        autoFocus
                    />
                ),
                valid: () => !templateField.required || !!obj[field.name],
            };
        case 'multi-select':
            return {
                title: fieldLabel(field.name),
                content: (
                    <MultiSelectFormItem
                        templateField={templateField}
                        values={obj[field.name] || []}
                        setValues={(values) => {
                            setObj({ ...obj, [field.name]: values });
                        }}
                        autoFocus
                    />
                ),
                valid: () => !templateField.required || (obj[field.name] && obj[field.name].length > 0),
            };
        case 'one-to-one':
            return {
                title: fieldLabel(field.name),
                content: (
                    <OneToOneFormItem
                        templateField={templateField}
                        objectRef={obj[field.name!]}
                        setObjectRef={(ref) => {
                            setObj({ ...obj, [field.name]: ref });
                        }}
                        autoFocus
                    />
                ),
                valid: () => !templateField.required || !!obj[field.name],
            };
        case 'one-to-many':
            return {
                title: fieldLabel(field.name),
                content: (
                    <OneToManyFormItem
                        templateField={templateField}
                        objectRefs={obj[field?.name!]}
                        setObjectRefs={(refs) => {
                            setObj({ ...obj, [field.name]: refs });
                        }}
                        autoFocus
                    />
                ),
                valid: () => !templateField.required || (obj[field.name] && obj[field.name].length > 0),
            };
    }
}

function templateNativeFieldToStep(
    templateField: TemplateField,
    obj: CreateObject,
    setObj: (obj: any) => void,
): CreateStepProps | null {
    /* FIXME: BEGIN - move to handlers */
    const nativeTemplateField = templateField as TemplateNativeField;

    if (nativeTemplateField.native_field.name === 'accountable_for') {
        return null;
    }

    if (nativeTemplateField.native_field.name === 'assignment_id') {
        return {
            title: fieldLabel(nativeTemplateField.native_field.name),
            content: (
                <PersonFormItem
                    allowClear
                    label={'Assigment'}
                    personId={(obj as Role).assignment_id || undefined}
                    setPersonId={(personId) => {
                        const role = { ...(obj as Role), assignment_id: personId };
                        setObj(role);
                    }}
                />
            ),
            valid: () => true,
        };
    }

    if (nativeTemplateField.native_field.name === 'person_id') {
        return {
            title: fieldLabel(nativeTemplateField.native_field.name),
            content: (
                <PersonFormItem
                    allowClear
                    label={'Person'}
                    personId={(obj as Position).person_id || undefined}
                    setPersonId={(personId) => {
                        const position = { ...(obj as Role), person_id: personId };
                        setObj(position);
                    }}
                />
            ),
            valid: () => true,
        };
    }
    /* FIXME: END - move to handlers */

    return templateCustomFieldToStep(templateField, obj, setObj);
}

function templateSteps(
    obj: CreateObject,
    setObj: (obj: any) => void,
    template: Template | undefined,
    skip: string[] = [],
) {
    const steps: CreateStepProps[] = [
        {
            title: 'Name',
            content: (
                <NameFormItem
                    name={obj.name || ''}
                    onChange={(name) => {
                        setObj({ ...obj, name });
                    }}
                />
            ),
            valid: () => !!obj.name,
        },
    ];

    if (template) {
        for (let i = 0; i < template.template_fields.length; i++) {
            const templateField = template.template_fields[i];

            const name = getField(templateField).name;
            if (!skip.includes(name)) {
                const step = isCustomTemplateField(templateField)
                    ? templateCustomFieldToStep(templateField, obj, setObj)
                    : templateNativeFieldToStep(templateField, obj, setObj);
                if (step) {
                    steps.push(step);
                }
            }
        }
    }
    return steps;
}

export default templateSteps;
