import React, { type CSSProperties } from 'react';
import { MailOutlined } from '@ant-design/icons';
import { Form, Input, type InputProps } from 'antd';

const UsernameFormItem = (props: {
    style?: CSSProperties;
    autoFocus?: InputProps['autoFocus'];
    readOnly?: InputProps['readOnly'];
}) => {
    return (
        <Form.Item
            style={props.style}
            name="username"
            rules={[
                {
                    required: true,
                    message: 'REQUIRED',
                },
            ]}
        >
            <Input
                prefix={<MailOutlined />}
                placeholder="Email"
                autoComplete={'email'}
                autoFocus={props.autoFocus}
                readOnly={props.readOnly}
            />
        </Form.Item>
    );
};

export default UsernameFormItem;
