import CreateEditStepsFooter from 'components/steps/common/CreateEditStepsFooter';
import React, { useState } from 'react';
import { Flex, Form, Steps } from 'antd';

import styles from './styles.module.scss';

export type CreateStepProps = {
    title: string;
    content: React.ReactNode;
    valid: () => boolean;
};

const CreateEditSteps = (props: {
    steps: CreateStepProps[];
    onCreate: () => void;
    onCancel: () => void;
    actionText?: string;
    loading?: boolean;
}) => {
    const [current, setCurrent] = useState(0);
    const items = props.steps.map((item) => ({ key: item.title, title: item.title }));

    /* Length 1 steps means we're just selecting the template. */
    const isCreate = current === props.steps.length - 1 && props.steps.length > 1;
    const actionText = props.actionText ? props.actionText : 'Create';

    const onNext = () => {
        setCurrent(current + 1);
    };

    const onPrevious = () => {
        setCurrent(current - 1);
    };

    function onCancel() {
        props.onCancel();
        setTimeout(() => {
            /*
             * Run this on the next tick so the user doesn't see the content reset
             * when the modal is transitioning closed.
             */
            setCurrent(0);
        }, 0);
    }

    return (
        <Flex className={styles.steps}>
            {props.steps && props.steps.length > 1 ? (
                <Steps size={'small'} direction={'vertical'} current={current} items={items} progressDot />
            ) : null}

            <div className={styles.content}>
                <Form className={styles.form}>{props.steps[current].content}</Form>
                <CreateEditStepsFooter
                    valid={props.steps[current].valid()}
                    loading={props.loading}
                    onNext={isCreate ? props.onCreate : onNext}
                    onPrevious={current > 0 ? onPrevious : undefined}
                    onCancel={onCancel}
                    actionText={isCreate ? actionText : undefined}
                />
            </div>
        </Flex>
    );
};

export default CreateEditSteps;
