import { useZoneCreatePermission } from 'hooks/permissions';
import { type CSSProperties } from 'react';

import BaseCreateButton, { type CreateButtonType } from 'components/buttons/BaseCreateButton';
import CreateZoneSteps from 'components/steps/CreateZoneSteps';
import Show from 'components/Show';

const CreateZoneButton = (props: { style?: CSSProperties; type?: CreateButtonType }) => (
    <Show permission={useZoneCreatePermission()}>
        <BaseCreateButton style={props.style} text={'Create Zone'} type={props.type}>
            {(onSuccess, onCancel) => <CreateZoneSteps onSuccess={onSuccess} onCancel={onCancel} />}
        </BaseCreateButton>
    </Show>
);

export default CreateZoneButton;
