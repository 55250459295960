import React from 'react';
import { Col, Layout, Row, theme } from 'antd';
import { useNavigate } from 'react-router-dom';

import styles from './styles.module.scss';
import apiClient from 'api';
import LoginForm from './LoginForm';

import loginBg from 'assets/login-bg.jpg';
import { useThemeMode } from 'hooks/useThemeMode';
import { LoginFormResponse } from 'pages/auth/common/types';

const LoginPage = () => {
    const themeMode = useThemeMode();
    const navigate = useNavigate();
    const LoginSRC = loginBg;
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    function onLogin(data: LoginFormResponse) {
        apiClient.setToken(data.token.access_token);
        navigate('/');
    }

    return (
        <Layout className={`${styles.page} theme-${themeMode}`} style={{ background: colorBgContainer }}>
            <Row className={styles.page}>
                <Col className={'col-left'} xs={24} md={12}>
                    <img src={LoginSRC} alt={'Viggo'} />
                    <div className="login-text">
                        <h1>
                            Hello <br />
                            Welcome <br />
                            Back
                        </h1>
                        <p>Please contact Viggo if you haven't received your login credentials.</p>
                    </div>
                </Col>
                <Col className={'col-right'} xs={24} md={12}>
                    <LoginForm onLogin={onLogin} />
                </Col>
            </Row>
        </Layout>
    );
};

export default LoginPage;
