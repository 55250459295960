import * as d3 from 'd3';
import type { MouseEvent } from 'react';
import { OrgUnit } from 'types';
import { isA } from 'utils';
import { getGroupBBox } from './utils';
import type { Datum, GTreeGroupData } from 'pages/orgUnitTree/gtree/types';

const GTreeGroup = (props: {
    group: GTreeGroupData;
    nodeMap: Map<string, d3.HierarchyNode<Datum>>;
    groupMap: Map<string, GTreeGroupData>;
    width: number;
    height: number;
    onMouseMove?: (event: MouseEvent) => void;
    paddingX?: number;
    paddingY?: number;
    onClick?: (group: GTreeGroupData) => void;
    onContextMenu?: (group: GTreeGroupData, x: number, y: number) => void;
}) => {
    if (props.group.nodes.length === 0 && props.group.groups.length === 0) return null;

    const paddingX = isA<number>(props.paddingX) ? props.paddingX : props.width / 4;
    const paddingY = isA<number>(props.paddingY) ? props.paddingY : props.height / 4;

    const rect = getGroupBBox(props.group, props.nodeMap, props.groupMap, props.width, props.height);
    const x = rect.x - paddingX;
    const y = rect.y - paddingY;

    const orgUnit = props.group.obj as OrgUnit;

    const onClick = (event: MouseEvent) => {
        if (props.onClick) {
            event.preventDefault();
            event.stopPropagation();
            props.onClick(props.group);
        }
    };

    const onContextMenu = (event: MouseEvent) => {
        if (props.onContextMenu) {
            event.preventDefault();
            event.stopPropagation();
            props.onContextMenu(props.group, event.clientX, event.clientY);
        }
    };

    return (
        <g style={{ cursor: props.onClick ? 'pointer' : 'default' }} onClick={onClick} onContextMenu={onContextMenu}>
            <rect
                onMouseMove={props.onMouseMove}
                className={'group'}
                fill={'#999'}
                fillOpacity={0.5}
                x={x}
                y={y}
                width={rect.width + 2 * paddingX}
                height={rect.height + 2 * paddingY}
            />
            <text x={x + 8} y={y + 8} alignmentBaseline={'hanging'} fontSize={16}>
                {orgUnit.name}
            </text>
        </g>
    );
};

export default GTreeGroup;
