import CreateOrgUnitSteps from 'components/steps/CreateOrgUnitSteps';
import { useCurrentUser } from 'hooks/useCurrentUser';

import BaseCreateButton, { type CreateButtonType } from 'components/buttons/BaseCreateButton';
import { isPersonAdminOrMore } from 'utils';

const CreateOrgUnitButton = (props: { type?: CreateButtonType }) => {
    const currentUser = useCurrentUser();
    if (!isPersonAdminOrMore(currentUser)) return null;

    return (
        <BaseCreateButton text={'Create Org Unit'} type={props.type}>
            {(onSuccess, onCancel) => <CreateOrgUnitSteps onSuccess={onSuccess} onCancel={onCancel} />}
        </BaseCreateButton>
    );
};

export default CreateOrgUnitButton;
