import { configureStore, createAction } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

import themeModeSlice from 'features/themeModeSlice';
import currentSlice from 'features/currentUserSlice';
import roleSlice from 'features/roleSlice';
import teamSlice from 'features/teamSlice';
import templateSlice from 'features/templateSlice';
import decisionSlice from 'features/decisionSlice';
import objectiveSlice from 'features/objectiveSlice';
import meetingTypeSlice from 'features/meetingTypeSlice';
import riskSlice from 'features/riskSlice';
import peopleSlice from 'features/personSlice';
import orgSlice from 'features/orgSlice';
import orgUnitSlice from 'features/orgUnitSlice';
import positionSlice from 'features/positionSlice';
import zoneSlice from 'features/zoneSlice';
import agreementSlice from 'features/agreementSlice';
import settingsSlice from 'features/settingsSlice';
import zoneFilterSlice from './features/zoneFilterSlice';

export const LOGOUT_ACTION = createAction('logout');
export const CLEAR_ORG_ACTION = createAction('clear');

const store = configureStore({
    reducer: {
        settingsSlice: settingsSlice,
        themeMode: themeModeSlice,
        current: currentSlice,
        org: orgSlice,

        decisions: decisionSlice,
        objectives: objectiveSlice,
        meetingTypes: meetingTypeSlice,
        risks: riskSlice,
        roles: roleSlice,
        agreements: agreementSlice,
        teams: teamSlice,
        templates: templateSlice,
        people: peopleSlice,
        orgUnits: orgUnitSlice,
        positions: positionSlice,
        zones: zoneSlice,
        zoneFilters: zoneFilterSlice,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();

export default store;
