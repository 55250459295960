import { Col } from 'antd';
import CreateRoleButton from 'components/buttons/CreateRoleButton';

import type { Role, Team } from 'types';

import { useTeamRoles } from 'hooks/roles';

import RoleCard from 'components/cards/RoleCard';
import Tab from './Tab';

const RolesTab = (props: { team: Team; onClick?: (role: Role) => void }) => {
    const roles = useTeamRoles(props.team.id) || [];
    return (
        <Tab extra={<CreateRoleButton team={props.team} type={'icon'} />} empty={roles.length === 0}>
            {roles.map((role) => (
                <Col xs={24} xl={12} key={role.id}>
                    <RoleCard role={role} onClick={() => props.onClick?.(role)} />
                </Col>
            ))}
        </Tab>
    );
};

export default RolesTab;
