import apiClient from 'api';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store';

import { Decision, Team } from 'types';
import { nameSort } from 'utils';
import { fetchDecisions, selectDecisions, selectDecisionStatus } from 'features/decisionSlice';

export function useDecisions(): Decision[] {
    const dispatch = useAppDispatch();
    const decisions = useAppSelector(selectDecisions);
    const decisionStatus = useAppSelector(selectDecisionStatus);
    const token = apiClient.token;

    useEffect(() => {
        if (token && decisionStatus === 'idle') {
            dispatch(fetchDecisions() as any);
        }
    }, [token, decisionStatus, dispatch]);

    return [...decisions].sort(nameSort);
}

export function useTeamDecisions(team: Team | null): Decision[] {
    const decisions = useDecisions();
    return team ? decisions.filter((decision) => decision.team.id === team.id) : [];
}
