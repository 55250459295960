import { GFDGNode, GFDGGroup, GFDGLink, GFDGData, GFDGBoundedNode } from 'pages/orgUnit/gfdg/types';

export function asSource(d: { source: GFDGNode | string | number }) {
    const source = d.source as GFDGNode;
    return { x: source.x as number, y: source.y as number };
}

export function asTarget(d: { target: GFDGNode | string | number }) {
    const target = d.target as GFDGNode;
    return { x: target.x as number, y: target.y as number };
}

export function isGFDGNode(target: GFDGNode | GFDGLink | GFDGGroup | null): target is GFDGNode {
    return !!(target && (target as GFDGNode).type === 'node');
}

/*
export function isGFDGLink(target: GFDGNode | GFDGLink | GFDGGroup | null): target is GFDGLink {
    return !!(target && (target as GFDGLink).type === 'link');
}
*/

export function isGFDGGroup(target: GFDGNode | GFDGLink | GFDGGroup | null): target is GFDGGroup {
    return !!(target && (target as GFDGGroup).type === 'group');
}

export function getDataBBox(data: GFDGData): DOMRect {
    let x = Infinity,
        X = 0,
        y = Infinity,
        Y = 0;

    for (const baseNode of data.nodes) {
        const node = baseNode as GFDGBoundedNode;
        x = Math.min(x, node.bounds.x);
        y = Math.min(y, node.bounds.y);
        X = Math.max(X, node.bounds.X);
        Y = Math.max(Y, node.bounds.Y);
    }

    return data.nodes.length ? new DOMRect(x, y, X - x, Y - y) : new DOMRect();
}
