import AuthPage from 'pages/auth/common/AuthPage';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Flex, Typography } from 'antd';

import styles from './styles.module.scss';
import { getFirstQueryParam } from 'utils';
import ResetPasswordForm from './ResetPasswordForm';

const ResetPasswordPage = () => {
    const token = getFirstQueryParam('t');
    const [error, setError] = useState<string | null>(token ? null : 'Missing token');
    const [done, setDone] = useState(window.location.hash === '#reset');

    function onReset() {
        setDone(true);
    }

    function onError(_: number, message: string) {
        setError(message);
    }

    if (error || !token) {
        return (
            <AuthPage className={styles.page}>
                <Flex vertical justify={'center'}>
                    <Typography.Text style={{ textAlign: 'center' }}>
                        Your reset password request couldn't be processed. Please try again.
                    </Typography.Text>
                    <Typography.Text style={{ textAlign: 'center', margin: '1rem auto' }} className={'text-danger'}>
                        [{error}]
                    </Typography.Text>
                    <Link style={{ textAlign: 'center' }} to={'/reset_password'}>
                        Reset Password
                    </Link>
                </Flex>
            </AuthPage>
        );
    }

    if (done) {
        return (
            <AuthPage className={styles.page} title={'Your password was successfully updated.'}>
                <Button type="primary" block href={'/'}>
                    Continue To Your Organization
                </Button>
            </AuthPage>
        );
    }

    return (
        <AuthPage title={'Set Password'}>
            <ResetPasswordForm token={token} onReset={onReset} onError={onError} />
            <div className="auth-help-text">
                Back to <Link to={'/login'}>Log In</Link>
            </div>
        </AuthPage>
    );
};

export default ResetPasswordPage;
