import * as d3 from 'd3';
import { useCurrentUser } from 'hooks/useCurrentUser';
import OrgUnitTreeShowGroups from 'pages/orgUnitTree/OrgUnitTreeShowGroups';
import React, { useState } from 'react';

import type { OrgUnit, Position } from 'types';

import ObjectDrawer from 'components/drawers/ObjectDrawer';

import { orgUnitTreeData } from 'pages/orgUnitTree/data';
import GraphContainer from 'pages/orgUnitTree/gtree/GraphContainer';
import GTreeGraph from 'pages/orgUnitTree/gtree/GTreeGraph';
import { isPersonAdminOrMore, toBoolean } from 'utils';

import OrgUnitTreeNoData from './OrgUnitTreeNoData';
import OrgUnitTreeNode from './OrgUnitTreeNode';
import OrgUnitTreeActions from './OrgUnitTreeActions';
import OrgUnitTreeContextMenu, { type OrgUnitTreeContextMenuItem } from './OrgUnitTreeContextMenu';

import type { Datum, GTreeGroupData, GTreeNodeData } from 'pages/orgUnitTree/gtree/types';

const KEY = 'showOrgUnits';
const NODE_WIDTH = 240;
const NODE_HEIGHT = 160;

type ContextMenu = {
    target: GTreeNodeData | GTreeGroupData | null;
    x: number;
    y: number;
};

const OrgUnitTreeGraph = (props: { positions: Position[]; orgUnits: OrgUnit[] }) => {
    const currentUser = useCurrentUser();

    const [menu, setMenu] = useState<ContextMenu>();
    const [action, setAction] = useState<'add-position' | 'add-org-unit'>();

    const [selected, setSelected] = useState<GTreeNodeData | GTreeGroupData | null>(null);
    const [showOrgUnits, setShowOrgUnits] = useState<boolean>(() => {
        const value = localStorage.getItem(KEY);
        return value !== null ? toBoolean(value) : true;
    });

    const onClose = () => {
        setSelected(null);
        setAction(undefined);
        setMenu(undefined);
    };

    const onSliderClick = (value: boolean) => {
        localStorage.setItem(KEY, value ? 'true' : 'false');
        setShowOrgUnits(value);
    };

    const onMenuClick = (item: OrgUnitTreeContextMenuItem) => {
        if (item) {
            setAction(item.key as 'add-position' | 'add-org-unit');
        }
    };

    const onNodeClick = (node: d3.HierarchyNode<Datum>) => {
        setSelected(node.data);
    };

    const onGroupClick = (group: GTreeGroupData) => {
        setSelected(group);
    };

    const onNodeContent = (node: d3.HierarchyNode<Datum>) => (
        <OrgUnitTreeNode node={node} nodeWidth={NODE_WIDTH} nodeHeight={NODE_HEIGHT} onClick={onNodeClick} />
    );

    const onContextMenu = (target: GTreeNodeData | GTreeGroupData | null, x: number, y: number) => {
        setMenu({ target, x, y });
    };

    return props.positions?.length ? (
        <>
            <GraphContainer onClick={onClose}>
                {(width, height) => (
                    <GTreeGraph
                        width={width}
                        height={height}
                        nodeWidth={NODE_WIDTH}
                        nodeHeight={NODE_HEIGHT}
                        data={orgUnitTreeData(props, showOrgUnits)}
                        onGroupClick={onGroupClick}
                        onNodeContent={onNodeContent}
                        onContextMenu={isPersonAdminOrMore(currentUser) ? onContextMenu : undefined}
                    />
                )}
            </GraphContainer>
            <OrgUnitTreeShowGroups onClick={onSliderClick} />
            {menu && !action ? <OrgUnitTreeContextMenu {...menu} onClick={onMenuClick} /> : null}
            {menu && action ? <OrgUnitTreeActions action={action} context={menu.target} onClose={onClose} /> : null}
            {selected && <ObjectDrawer objectId={selected.obj.id} objectType={selected.obj.type} onClose={onClose} />}
        </>
    ) : (
        <OrgUnitTreeNoData />
    );
};

export default OrgUnitTreeGraph;
