import type { UserDatum } from 'pages/org/OrgChartComponent';
import type { Person } from 'types';

function findRoot(parentId: string, records: Record<string, UserDatum>): UserDatum {
    let parent = records[parentId];
    while (parent.parentId) {
        parent = records[parent.parentId];
    }
    return parent;
}

export function orgChartData(people: Record<string, Person>) {
    const records: Record<string, UserDatum> = {};
    for (const personId in people) {
        const person = people[personId];
        if (person.reports_to_id) {
            const reportsTo = people[person.reports_to_id];

            records[person.id] = {
                id: person.id,
                parentId: reportsTo.id,
                name: person.name,
                title: person.title ? person.title : '',
                imageUrl: person.picture_medium,
            };

            if (!records[reportsTo.id]) {
                // This may be overwritten if found later...
                records[reportsTo.id] = {
                    id: reportsTo.id,
                    parentId: '',
                    name: reportsTo.name,
                    title: reportsTo.title ? reportsTo.title : '',
                    imageUrl: reportsTo.picture_medium,
                };
            }
        }
    }
    /* result now only contains parents and children so any node without a parent is a root */
    const roots: Record<string, Set<string>> = {};

    for (const datum of Object.values(records)) {
        if (datum.parentId) {
            let root = findRoot(datum.parentId, records);
            const nodes = roots[root.id] || new Set<string>();
            nodes.add(datum.id);
            roots[root.id] = nodes;
        } else {
            /* another root */
            roots[datum.id] = roots[datum.id] || new Set<string>();
            roots[datum.id].add(datum.id);
        }
    }

    const result: Record<string, UserDatum[]> = {};

    for (const id of Object.keys(roots)) {
        const nodeIds = roots[id];
        result[id] = Array.from(nodeIds).map((id) => records[id]);
    }
    return result;
}
