import DrawerDescription from 'components/drawers/common/DrawerDescription';
import { useTemplate } from 'hooks/templates';

const TemplateDrawerDescription = (props: { objectType: string; templateId: string }) => {
    const template = useTemplate(props.templateId, props.objectType);
    return template ? (
        <DrawerDescription title={'Template'} dangerouslySetInnerHTML={{ __html: template.name }} />
    ) : null;
};

export default TemplateDrawerDescription;
