import { ChangeEvent } from 'react';
import { Form, Input } from 'antd';

import { useFocusRef } from 'hooks/focus';

type NameFormItemProps = {
    name: string;
};

const NameFormItem = (
    props: Partial<NameFormItemProps> & {
        onChange?: (name: string) => void;
        autoFocus?: boolean;
    },
) => {
    const ref = useFocusRef();

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        props.onChange?.(e.target.value);
    };

    return (
        <Form.Item<NameFormItemProps>
            className={'form-control'}
            label={'Name'}
            name={'name'}
            rules={[{ required: true, message: 'Please enter a descriptive name.' }]}
            initialValue={props.name}
            required={true}
        >
            <Input ref={ref} onChange={onChange} autoFocus={props.autoFocus} />
        </Form.Item>
    );
};

export default NameFormItem;
