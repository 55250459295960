import React from 'react';
import { useNavigate } from 'react-router-dom';

import { map } from 'utils';
import { usePeople } from 'hooks/usePeople';

import { orgChartData } from 'pages/org/data';
import OrgChartContainer from 'pages/org/OrgChartContainer';
import OrgNoData from 'pages/org/OrgNoData';

const OrganizationPage = () => {
    const navigate = useNavigate();

    const people = usePeople();
    if (!people) return null;

    const onClick = (id: string) => {
        navigate(`/people/${id}`);
    };

    const peopleMap = map(people);
    const data = orgChartData(peopleMap);
    const keys = Object.keys(data);

    if (!keys || keys.length === 0) return <OrgNoData />;

    return <OrgChartContainer data={data} onClick={onClick} people={peopleMap} />;
};

export default OrganizationPage;
