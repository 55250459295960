import { Role } from 'types';
import { isDefined, isNumber } from 'utils';

export function getAllocation(role: Role): number | undefined {
    const obj = role as { [key: string]: any };
    if (isNumber(obj.allocation)) {
        return obj.allocation;
    }
    if (isDefined(obj.Allocation)) {
        return obj.Allocation;
    }
    return undefined;
}
