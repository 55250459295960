import { useTemplates } from 'hooks/templates';
import React, { useEffect, useState } from 'react';

import ForceDirectedGraph from 'components/graph/fdg/ForceDirectedGraph';
import type { FDGLink, FDGNode } from 'components/graph/fdg/types';
import Loading from 'components/Loading';
import { useOrg } from 'hooks/useOrg';
import { teamRolesData } from 'pages/teams/graph/data';

import { useRoles } from 'hooks/roles';
import { useTeams } from 'hooks/teams';
import { graph, type GraphData } from 'services/graphs';

import styles from './styles.module.scss';
import type { Team } from 'types';
import TextStar from './TextStar';

const TeamRolesGraph = (props: {
    width: number;
    height: number;
    team: Team;
    depth: 2 | 3 | 4;
    mode: 'light' | 'dark';
    onClick?: (node: FDGNode) => void;
    onTooltipText?: (target: FDGNode | FDGLink) => React.ReactNode;
}) => {
    const [data, setData] = useState<GraphData>();

    const org = useOrg();
    const teams = useTeams();
    const roles = useRoles();
    const templates = useTemplates();

    const team_id = props.team.id;
    const depth = props.depth;

    function onNodeContent(node: FDGNode) {
        /* width/height is multiplied by 4 */
        return node.obj.id === props.team.id ? (
            <TextStar x={props.width * 2} y={props.height * 2} fill={styles.teamColorStroke} />
        ) : null;
    }

    useEffect(() => {
        if (org) {
            graph(org, team_id, `team-roles-${depth}`).then((graphData) => setData(graphData));
        }
    }, [org, team_id, depth]);

    if (teams === null || roles === null || !data) return <Loading />;

    const teamGraphData = teamRolesData(team_id, data, teams, roles, templates, props.mode);
    return (
        <ForceDirectedGraph
            width={props.width}
            height={props.height}
            data={teamGraphData}
            onClick={props.onClick}
            onTooltipText={props.onTooltipText}
            onNodeContent={onNodeContent}
        />
    );
};
export default TeamRolesGraph;
