import { Form, InputNumber } from 'antd';
import { NumberField } from 'types';

const NumberConfigField = (props: { field: NumberField; setField: (field: NumberField) => void }) => {
    return (
        <>
            <Form.Item className="form-control" label="Minimum Value">
                <InputNumber
                    value={props.field.config.ge}
                    onChange={(ge) => {
                        const config = { ...props.field.config, ge };
                        props.setField({ ...props.field, config });
                    }}
                />
            </Form.Item>
            <Form.Item className="form-control" label="Maximum Value">
                <InputNumber
                    value={props.field.config.le}
                    onChange={(le) => {
                        const config = { ...props.field.config, le };
                        props.setField({ ...props.field, config });
                    }}
                />
            </Form.Item>
        </>
    );
};

export default NumberConfigField;
