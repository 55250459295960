import { type CSSProperties } from 'react';
import Show from 'components/Show';
import { useTeamCreatePermission } from 'hooks/permissions';
import BaseCreateButton, { type CreateButtonType } from 'components/buttons/BaseCreateButton';
import CreateTeamSteps from 'components/steps/CreateTeamSteps';

const CreateTeamButton = (props: { style?: CSSProperties; type?: CreateButtonType }) => (
    <Show permission={useTeamCreatePermission()}>
        <BaseCreateButton style={props.style} text={'Create Team'} type={props.type}>
            {(onSuccess, onCancel) => <CreateTeamSteps onSuccess={onSuccess} onCancel={onCancel} />}
        </BaseCreateButton>
    </Show>
);

export default CreateTeamButton;
