import { isGTreeNodeData } from 'pages/orgUnitTree/gtree/utils';
import type { CSSProperties, MouseEvent, KeyboardEvent } from 'react';
import { Menu, type MenuProps } from 'antd';
import type { GTreeGroupData, GTreeNodeData } from 'pages/orgUnitTree/gtree/types';

export type OrgUnitTreeContextMenuItem = Required<MenuProps>['items'][number];

const OrgUnitTreeContextMenu = (props: {
    onClick?: MenuProps['onClick'];
    target: GTreeNodeData | GTreeGroupData | null;
    x: number;
    y: number;
}) => {
    const style = {
        width: 256,
        position: 'fixed',
        left: props.x,
        top: props.y,
        border: '1px solid #ccc',
        borderRadius: '12px',
    } as CSSProperties;

    const onClick = (menuItem: { domEvent: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement> }) => {
        menuItem.domEvent.preventDefault();
        menuItem.domEvent.stopPropagation();
    };

    let items: MenuProps['items'];
    if (isGTreeNodeData(props.target)) {
        items = [{ key: 'add-position', label: 'Add position', onClick }];
    } else {
        items = [
            { key: 'add-org-unit', label: 'Add org unit', onClick },
            { key: 'add-position', label: 'Add position', onClick },
        ];
    }

    return <Menu onClick={props.onClick} style={style} mode="inline" items={items} />;
};

export default OrgUnitTreeContextMenu;
