import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { Agreement, SliceStatus, Organization, ErrorDetail } from 'types';
import { CLEAR_ORG_ACTION, LOGOUT_ACTION, RootState } from 'store';
import apiClient from 'api';

type AgreementsState = {
    entities: Agreement[];
    status: SliceStatus;
    error: string;
};

export const fetchAgreements = createAsyncThunk<Agreement[]>('agreements/fetch', async (_, thunkAPI) => {
    const state = thunkAPI.getState() as { org: { value: Organization } };
    return await apiClient.get(`/org/${state.org.value.id}/agreements`);
});

export const deleteAgreement = createAsyncThunk<
    Agreement,
    string,
    {
        rejectValue: ErrorDetail;
    }
>('agreement/delete', async (agreementId, thunkAPI) => {
    const state = thunkAPI.getState() as { org: { value: Organization } };
    try {
        return await apiClient.delete<Agreement>(`/org/${state.org.value.id}/agreements/${agreementId}`);
    } catch (e) {
        return thunkAPI.rejectWithValue((await (e as Response).json()) as ErrorDetail);
    }
});

export const createAgreement = createAsyncThunk<
    Agreement,
    Agreement,
    {
        rejectValue: ErrorDetail;
    }
>('agreement/create', async (agreement, thunkAPI) => {
    const state = thunkAPI.getState() as { org: { value: Organization } };
    try {
        return await apiClient.post(`/org/${state.org.value.id}/agreements`, agreement);
    } catch (e) {
        return thunkAPI.rejectWithValue(e as ErrorDetail);
    }
});

type UpdateAgreement = Partial<Agreement> & { id: string };
export const updateAgreement = createAsyncThunk<
    Agreement,
    UpdateAgreement,
    {
        rejectValue: ErrorDetail;
    }
>('agreement/update', async (data, thunkAPI) => {
    const state = thunkAPI.getState() as { org: { value: Organization } };
    try {
        return await apiClient.patch(`/org/${state.org.value.id}/agreements/${data.id}`, data);
    } catch (e) {
        return thunkAPI.rejectWithValue(e as ErrorDetail);
    }
});

const INITIAL_STATE = {
    entities: [],
    status: 'idle' as AgreementsState['status'],
    error: '',
};

// noinspection JSUnusedGlobalSymbols
const agreementSlice = createSlice({
    name: 'agreements',
    initialState: INITIAL_STATE satisfies AgreementsState as AgreementsState,
    reducers: {
        clearError: (state) => {
            state.error = '';
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchAgreements.pending, (state: AgreementsState) => {
                state.status = 'pending';
            })
            .addCase(fetchAgreements.fulfilled, (state: AgreementsState, action: PayloadAction<Agreement[]>) => {
                state.status = 'succeeded';
                state.entities = action.payload as Agreement[];
            })
            .addCase(fetchAgreements.rejected, (state: AgreementsState, action) => {
                state.status = 'failed';
                //state.error = action.error.message;
                console.log(action);
            })
            .addCase(
                createAgreement.fulfilled,
                (state: AgreementsState, action: PayloadAction<Agreement | ErrorDetail>) => {
                    state.entities.push(action.payload as Agreement);
                },
            )
            .addCase(deleteAgreement.fulfilled, (state: AgreementsState, action) => {
                state.entities = state.entities.filter((agreement: Agreement) => agreement.id !== action.payload.id);
            })
            .addCase(CLEAR_ORG_ACTION, (state: AgreementsState) => {
                Object.assign(state, INITIAL_STATE);
            })
            .addCase(LOGOUT_ACTION, (state: AgreementsState) => {
                Object.assign(state, INITIAL_STATE);
            })
            .addCase(
                updateAgreement.fulfilled,
                (state: AgreementsState, action: PayloadAction<Agreement | ErrorDetail>) => {
                    const agreement = action.payload as Agreement;
                    const index = state.entities.findIndex((r) => r.id === agreement.id);
                    state.entities[index] = agreement;
                },
            );
    },
});

export const selectAgreements = (state: RootState) => state.agreements.entities;
export const selectAgreementStatus = (state: RootState) => state.agreements.status;

export default agreementSlice.reducer;
