import apiClient from 'api';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store';

import { Agreement, Team } from 'types';
import { nameSort } from 'utils';
import { fetchAgreements, selectAgreements, selectAgreementStatus } from 'features/agreementSlice';

export function useAgreements(): Agreement[] {
    const dispatch = useAppDispatch();
    const agreements = useAppSelector(selectAgreements);
    const agreementStatus = useAppSelector(selectAgreementStatus);
    const token = apiClient.token;

    useEffect(() => {
        if (token && agreementStatus === 'idle') {
            dispatch(fetchAgreements() as any);
        }
    }, [token, agreementStatus, dispatch]);

    return [...agreements].sort(nameSort);
}

/*
export function useAgreement(agreementId: string | undefined) {
    const agreements = useAgreements();
    return agreementId ? agreements.find((agreement) => agreement.id === agreementId) : null;
}
*/

export function useTeamAgreements(team: Team | null): Agreement[] {
    const agreements = useAgreements();
    return team ? agreements.filter((agreement) => agreement.team.id === team.id) : [];
}
