import { useEffect, useState } from 'react';
import useWebSocket from 'react-use-websocket';
import apiClient, { API_URL } from 'api';
import { useOrg } from 'hooks/useOrg';
import { WebSocketMessage } from 'types';
import { isDebug } from 'utils';
import id from 'id';

export function useSubscribe() {
    const org = useOrg();
    const debug = isDebug('websocket');
    const [message, setMessage] = useState<WebSocketMessage | null>(null);

    const url = new URL(API_URL);
    url.pathname = `/org/${org.id}/ws`;
    url.protocol = url.protocol === 'https:' ? 'wss:' : 'ws:';
    if (apiClient.token) {
        url.searchParams.set('token', apiClient.token);
    }

    /*
     * There is only one websocket connection per unique URL allowed (even across tabs).
     * This id is unique per application instance.
     */
    url.searchParams.set('id', id);

    const { lastMessage } = useWebSocket(
        url.toString(),
        debug
            ? {
                  share: true,
                  onOpen: () => console.log(`[${org.id}] WebSocket connection opened`),
                  onClose: (event) => console.log(`[${org.id}] WebSocket connection closed:`, event),
                  onError: (error) => console.log(`[${org.id}] WebSocket error:`, error),
              }
            : { share: true },
    );

    useEffect(() => {
        if (debug && lastMessage !== null) {
            console.log(`[${org.id}]`, lastMessage.data);
        }
        setMessage(lastMessage ? (JSON.parse(lastMessage.data) as WebSocketMessage) : null);
    }, [lastMessage, debug, org.id]);

    return message;
}
