import handleOrgUnit from './orgUnit';
import handlePosition from './position';
import handleRole from './role';
import type { Role, Position, OrgUnit, TemplatedObject, TemplateNativeField } from 'types';

export function handleFormField(
    obj: TemplatedObject,
    templateField: TemplateNativeField,
    onChange: (value: any) => void,
) {
    if (obj.type === 'role') {
        return handleRole(obj as Role, templateField, onChange);
    }
    if (obj.type === 'position') {
        return handlePosition(obj as Position, templateField, onChange);
    }
    if (obj.type === 'org_unit') {
        return handleOrgUnit(obj as OrgUnit, templateField, onChange);
    }
    return undefined;
}
