import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { Position, SliceStatus, Organization, ErrorDetail } from 'types';
import { CLEAR_ORG_ACTION, LOGOUT_ACTION, RootState } from 'store';
import apiClient from 'api';

type PositionsState = {
    entities: Position[];
    status: SliceStatus;
    error: string;
};

export const fetchPositions = createAsyncThunk<Position[]>('positions/fetch', async (_, thunkAPI) => {
    const state = thunkAPI.getState() as { org: { value: Organization } };
    return await apiClient.get(`/org/${state.org.value.id}/positions`);
});

export const deletePosition = createAsyncThunk<
    Position,
    string,
    {
        rejectValue: ErrorDetail;
    }
>('position/delete', async (userId, thunkAPI) => {
    const state = thunkAPI.getState() as { org: { value: Organization } };
    try {
        return await apiClient.delete<Position>(`/org/${state.org.value.id}/positions/${userId}`);
    } catch (e) {
        return thunkAPI.rejectWithValue((await (e as Response).json()) as ErrorDetail);
    }
});

export const createPosition = createAsyncThunk<
    Position,
    Position,
    {
        rejectValue: ErrorDetail;
    }
>('position/create', async (position, thunkAPI) => {
    const state = thunkAPI.getState() as { org: { value: Organization } };
    try {
        return await apiClient.post(`/org/${state.org.value.id}/positions`, position);
    } catch (e) {
        return thunkAPI.rejectWithValue(e as ErrorDetail);
    }
});

type UpdatePosition = Partial<Position> & { id: string };
export const updatePosition = createAsyncThunk<
    Position,
    UpdatePosition,
    {
        rejectValue: ErrorDetail;
    }
>('position/update', async (data, thunkAPI) => {
    const state = thunkAPI.getState() as { org: { value: Organization } };
    try {
        return await apiClient.patch(`/org/${state.org.value.id}/positions/${data.id}`, data);
    } catch (e) {
        return thunkAPI.rejectWithValue(e as ErrorDetail);
    }
});

const INITIAL_STATE = {
    entities: [],
    status: 'idle' as PositionsState['status'],
    error: '',
};

// noinspection JSUnusedGlobalSymbols
const positionSlice = createSlice({
    name: 'positions',
    initialState: INITIAL_STATE satisfies PositionsState as PositionsState,
    reducers: {
        clearError: (state) => {
            state.error = '';
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchPositions.pending, (state: PositionsState) => {
                state.status = 'pending';
            })
            .addCase(fetchPositions.fulfilled, (state: PositionsState, action: PayloadAction<Position[]>) => {
                state.status = 'succeeded';
                state.entities = action.payload as Position[];
            })
            .addCase(fetchPositions.rejected, (state: PositionsState, action) => {
                state.status = 'failed';
                //state.error = action.error.message;
                console.error(action);
            })
            .addCase(
                createPosition.fulfilled,
                (state: PositionsState, action: PayloadAction<Position | ErrorDetail>) => {
                    state.entities.push(action.payload as Position);
                },
            )
            .addCase(deletePosition.fulfilled, (state: PositionsState, action) => {
                state.entities = state.entities.filter((user: Position) => user.id !== action.payload.id);
            })
            .addCase(CLEAR_ORG_ACTION, (state: PositionsState) => {
                Object.assign(state, INITIAL_STATE);
            })
            .addCase(LOGOUT_ACTION, (state: PositionsState) => {
                Object.assign(state, INITIAL_STATE);
            })
            .addCase(
                updatePosition.fulfilled,
                (state: PositionsState, action: PayloadAction<Position | ErrorDetail>) => {
                    const position = action.payload as Position;
                    const index = state.entities.findIndex((r) => r.id === position.id);
                    Object.assign(state.entities[index], position);
                    state.entities = [...state.entities];
                },
            );
    },
});

export const selectPositions = (state: RootState) => state.positions.entities;
export const selectPositionStatus = (state: RootState) => state.positions.status;

export default positionSlice.reducer;
