import { Switch, type SwitchProps } from 'antd';
import styles from './styles.module.scss';

const OrgUnitTreeShowGroups = (props: { onClick?: SwitchProps['onClick'] }) => {
    return (
        <Switch
            className={styles.switch}
            onClick={props.onClick}
            checkedChildren="Show Org Units"
            unCheckedChildren="Hide Org Units"
            defaultChecked
        />
    );
};

export default OrgUnitTreeShowGroups;
