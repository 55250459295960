import type { MouseEvent } from 'react';
import type { GFDGGroup } from './types';

const GroupedForceDirectedGraphGroup = (props: {
    group: GFDGGroup;
    onClick?: (groups: GFDGGroup) => void;
    onContextMenu?: (group: GFDGGroup, x: number, y: number) => void;
    onMouseMove?: (event: MouseEvent) => void;
}) => {
    if (!props.group.bounds) return null;

    const width = props.group.bounds.X - props.group.bounds.x;
    const height = props.group.bounds.Y - props.group.bounds.y;

    const onClick = (event: MouseEvent) => {
        if (props.onClick) {
            event.preventDefault();
            event.stopPropagation();
            props.onClick(props.group);
        }
    };

    const onContextMenu = (event: MouseEvent) => {
        if (props.onContextMenu) {
            event.preventDefault();
            event.stopPropagation();
            props.onContextMenu?.(props.group, event.clientX, event.clientY);
        }
    };

    return (
        <rect
            style={{ cursor: props.onClick ? 'pointer' : 'default' }}
            onMouseMove={props.onMouseMove}
            onContextMenu={onContextMenu}
            onClick={onClick}
            className={'group'}
            width={width}
            height={height}
            fill={'#999'}
            fillOpacity={0.5}
            x={props.group.bounds.x}
            y={props.group.bounds.y}
        />
    );
};

export default GroupedForceDirectedGraphGroup;
