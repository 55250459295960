import { EditOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { capitalCase } from 'change-case';
import Show from 'components/Show';
import {
    useAgreementUpdatePermission,
    useDecisionUpdatePermission,
    useObjectiveUpdatePermission,
    useRiskUpdatePermission,
    useRoleUpdatePermission,
    useTeamUpdatePermission,
    useZoneUpdatePermission,
} from 'hooks/permissions';
import { type ReactNode } from 'react';
import { type ObjectType } from 'types';

const BaseEditButton = (props: { objectType: ObjectType; onClick?: () => void; icon?: ReactNode }) => {
    const icon = props.icon ? props.icon : <EditOutlined />;
    return (
        <Tooltip title={`Edit ${capitalCase(props.objectType)}`}>
            <Button shape="circle" icon={icon} onClick={props.onClick} />
        </Tooltip>
    );
};

const ZoneEditButton = (props: { onClick?: () => void; icon?: ReactNode }) => (
    <Show permission={useZoneUpdatePermission()}>
        <BaseEditButton objectType={'zone'} onClick={props.onClick} icon={props.icon} />
    </Show>
);

const TeamEditButton = (props: { teamId?: string; onClick?: () => void; icon?: ReactNode }) => (
    <Show permission={useTeamUpdatePermission(props.teamId)}>
        <BaseEditButton objectType={'team'} onClick={props.onClick} icon={props.icon} />
    </Show>
);

const RoleEditButton = (props: { teamId?: string; onClick?: () => void; icon?: ReactNode }) => (
    <Show permission={useRoleUpdatePermission(props.teamId)}>
        <BaseEditButton objectType={'role'} onClick={props.onClick} icon={props.icon} />
    </Show>
);

const DecisionEditButton = (props: { teamId?: string; onClick?: () => void; icon?: ReactNode }) => (
    <Show permission={useDecisionUpdatePermission(props.teamId)}>
        <BaseEditButton objectType={'decision'} onClick={props.onClick} icon={props.icon} />
    </Show>
);

const ObjectiveEditButton = (props: { teamId?: string; onClick?: () => void; icon?: ReactNode }) => (
    <Show permission={useObjectiveUpdatePermission(props.teamId)}>
        <BaseEditButton objectType={'objective'} onClick={props.onClick} icon={props.icon} />
    </Show>
);

const AgreementEditButton = (props: { teamId?: string; onClick?: () => void; icon?: ReactNode }) => (
    <Show permission={useAgreementUpdatePermission(props.teamId)}>
        <BaseEditButton objectType={'agreement'} onClick={props.onClick} icon={props.icon} />
    </Show>
);

const RiskEditButton = (props: { teamId?: string; onClick?: () => void; icon?: ReactNode }) => (
    <Show permission={useRiskUpdatePermission(props.teamId)}>
        <BaseEditButton objectType={'agreement'} onClick={props.onClick} icon={props.icon} />
    </Show>
);

const ObjectDrawerEditButton = (props: {
    objectType: ObjectType;
    teamId?: string;
    icon?: ReactNode;
    onClick?: () => void;
}) => {
    switch (props.objectType) {
        case 'zone':
            return <ZoneEditButton {...props} />;
        case 'team':
            return <TeamEditButton {...props} />;
        case 'role':
            return <RoleEditButton {...props} />;
        case 'decision':
            return <DecisionEditButton {...props} />;
        case 'objective':
            return <ObjectiveEditButton {...props} />;
        case 'agreement':
            return <AgreementEditButton {...props} />;
        case 'risk':
            return <RiskEditButton {...props} />;
    }
    return null;
};

export default ObjectDrawerEditButton;
