import { Form, Input } from 'antd';
import { useFocusRef } from 'hooks/focus';
import { TemplateField, TextField } from 'types';
import { fieldLabel, getField } from 'utils';

const TextAreaFormItem = (props: {
    templateField: TemplateField;
    value: string | undefined;
    setValue: (value: string | null) => void;
    rows?: number;
    autoFocus?: boolean;
}) => {
    const ref = useFocusRef(props.autoFocus);
    const textField = getField(props.templateField) as TextField;
    const rows = props.rows ? props.rows : 8;

    return (
        <Form.Item
            className={'form-control'}
            label={fieldLabel(textField.name)}
            rules={[{ required: props.templateField.required, message: 'Please enter a value.' }]}
            name={textField.name}
            initialValue={props.value}
        >
            <Input.TextArea
                ref={ref}
                onChange={(event) => {
                    props.setValue(event.target.value ? event.target.value : null);
                }}
                placeholder={'Markdown is accepted.'}
                rows={rows}
            />
        </Form.Item>
    );
};

export default TextAreaFormItem;
