import { Space } from 'antd';
import ChangeTemplateButton from 'components/buttons/ChangeTemplateButton';
import DeleteDecisionButton from 'components/buttons/DeleteDecisionButton';
import DeleteObjectiveButton from 'components/buttons/DeleteObjectiveButton';
import DeleteRoleButton from 'components/buttons/DeleteRoleButton';
import DeleteTeamButton from 'components/buttons/DeleteTeamButton';
import DeleteZoneButton from 'components/buttons/DeleteZoneButton';
import DangerCard from 'components/cards/DangerCard';
import TeamMeetingsTable from 'components/drawers/team/meetingTable/TeamMeetingsTable';
import TeamProvider from 'providers/TeamProvider';
import type { Team, TemplatedObject } from 'types';

import ObjectDrawerDisplay from './ObjectDrawerDisplay';
import ObjectDrawerEdit from './ObjectDrawerEdit';

const ObjectDrawerContents = (props: {
    obj: TemplatedObject;
    edit?: boolean;
    setEdit?: (value: boolean) => void;
    onClose?: () => void;
}) => {
    const onFinish = () => {
        props.setEdit?.(false);
        props.onClose?.();
    };

    if (props.edit) {
        return (
            <>
                <ObjectDrawerEdit
                    obj={props.obj}
                    onFinish={() => props.setEdit?.(false)}
                    onCancel={() => props.setEdit?.(false)}
                />
                {props.obj.type === 'decision' && (
                    <DangerCard>
                        <ChangeTemplateButton obj={props.obj} onFinish={onFinish} />
                        <DeleteDecisionButton decisionId={props.obj.id} navigate={false} onDelete={onFinish} />
                    </DangerCard>
                )}
                {props.obj.type === 'objective' && (
                    <DangerCard>
                        <ChangeTemplateButton obj={props.obj} onFinish={onFinish} />
                        <DeleteObjectiveButton objectiveId={props.obj.id} navigate={false} onDelete={onFinish} />
                    </DangerCard>
                )}
                {props.obj.type === 'role' && (
                    <DangerCard>
                        <ChangeTemplateButton obj={props.obj} onFinish={onFinish} />
                        <DeleteRoleButton
                            roleId={props.obj.id}
                            navigate={false}
                            onDelete={() => {
                                props.setEdit?.(false);
                                props.onClose?.();
                            }}
                        />
                    </DangerCard>
                )}
                {props.obj.type === 'team' && (
                    <TeamProvider team={props.obj as Team}>
                        <Space className={'w-100'} direction={'vertical'} size={'large'}>
                            <TeamMeetingsTable team={props.obj as Team} />
                            <DangerCard>
                                <ChangeTemplateButton obj={props.obj} onFinish={onFinish} />
                                <DeleteTeamButton teamId={props.obj.id} />
                            </DangerCard>
                        </Space>
                    </TeamProvider>
                )}
                {props.obj.type === 'zone' && (
                    <DangerCard>
                        <DeleteZoneButton
                            zoneId={props.obj.id}
                            onDelete={() => {
                                props.setEdit?.(false);
                                props.onClose?.();
                            }}
                        />
                    </DangerCard>
                )}
            </>
        );
    }

    return <ObjectDrawerDisplay obj={props.obj as TemplatedObject} />;
};

export default ObjectDrawerContents;
