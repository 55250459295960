import { fromMarkdown } from 'mdast-util-from-markdown';
import React from 'react';

/* e.g. for [text](/link)
{
  "type": "root",
  "children": [
    {
      "type": "paragraph",
      "children": [
        {
          "type": "link",
          "title": null,
          "url": "/link",
          "children": [
            {
              "type": "text",
              "value": "text",
              "position": {
                "start": {
                  "line": 1,
                  "column": 2,
                  "offset": 1
                },
                "end": {
                  "line": 1,
                  "column": 6,
                  "offset": 5
                }
              }
            }
          ],
          "position": {
            "start": {
              "line": 1,
              "column": 1,
              "offset": 0
            },
            "end": {
              "line": 1,
              "column": 14,
              "offset": 13
            }
          }
        }
      ],
      "position": {
        "start": {
          "line": 1,
          "column": 1,
          "offset": 0
        },
        "end": {
          "line": 1,
          "column": 14,
          "offset": 13
        }
      }
    }
  ],
  "position": {
    "start": {
      "line": 1,
      "column": 1,
      "offset": 0
    },
    "end": {
      "line": 1,
      "column": 14,
      "offset": 13
    }
  }
}
*/

export function parseLinkValue(value: string | undefined) {
    const result = { href: '', text: '' };
    if (value) {
        const tree = fromMarkdown(value);
        if (!!tree && tree.children.length) {
            const paragraph = tree.children[0];
            if (paragraph.type === 'paragraph' && paragraph.children.length) {
                let link: any = paragraph.children[0];
                if (link.type === 'text') {
                    result.text += link.value;
                    link = paragraph.children.length > 1 ? paragraph.children[1] : null;
                }
                if (link && link.type === 'link') {
                    result.href = link.url;
                    if (link.children.length) {
                        const text = link.children[0];
                        if (text.type === 'text') {
                            result.text += text.value;
                        }
                    }
                }
            }
            // console.log(JSON.stringify(tree, null, 4));
        }
    }

    return result;
}

export function buildLinkValue({ href, text }: { href: string; text: string }) {
    href = href.replace('\\', '\\\\').replace(')', '\\)');
    text = text.replace('\\', '\\\\').replace(']', '\\]');
    return `[${text}](${href})`;
}

/* Hack: only works with 'Label' elements with a known set of prompts. */
function getNameFromElement(node: React.ReactElement) {
    const value = node.props.obj?.name ? node.props.obj.name : node.props.name;
    return value ? (value as string) : '';
}

export const getTextFromNode = (node: React.ReactNode): string => {
    if (typeof node === 'string') return node;
    if (React.isValidElement(node)) return getNameFromElement(node);
    if (Array.isArray(node)) return node.map(getTextFromNode).join('');
    return '';
};

export const filterOption = (input: string, option?: { label: React.ReactNode }) => {
    if (!option) return false;
    const labelText = getTextFromNode(option.label).toLowerCase();
    return labelText.includes(input.toLowerCase());
};
