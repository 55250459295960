import * as d3 from 'd3';
import type { Datum, GTreeGroupData, GTreeNodeData } from 'pages/orgUnitTree/gtree/types';

type Bounds = { x: number; y: number; X: number; Y: number };

const getNodeBounds = (node: d3.HierarchyNode<Datum>, width: number, height: number): Bounds => {
    return {
        x: node.x! - width / 2,
        y: node.y!,
        X: node.x! + width / 2,
        Y: node.y! + height,
    };
};

const computeBounds = (a: Bounds, b: Bounds): Bounds => {
    return {
        x: Math.min(a.x, b.x),
        y: Math.min(a.y, b.y),
        X: Math.max(a.X, b.X),
        Y: Math.max(a.Y, b.Y),
    } as Bounds;
};

const toRect = (bounds: Bounds): DOMRect => new DOMRect(bounds.x, bounds.y, bounds.X - bounds.x, bounds.Y - bounds.y);

export function getRootBBox(root: d3.HierarchyNode<Datum>, nodeWidth: number, nodeHeight: number): DOMRect {
    let bounds: Bounds = { x: Infinity, y: Infinity, X: -Infinity, Y: -Infinity };

    for (const node of root.descendants()) {
        const nodeBounds = getNodeBounds(node, nodeWidth, nodeHeight);
        bounds = computeBounds(bounds, nodeBounds);
    }

    return toRect(bounds);
}

export function getGroupBBox(
    group: GTreeGroupData,
    nodeMap: Map<string, d3.HierarchyNode<Datum>>,
    groupMap: Map<string, GTreeGroupData>,
    nodeWidth: number,
    nodeHeight: number,
): DOMRect {
    const getGroupBounds = (bounds: Bounds, group: GTreeGroupData): Bounds => {
        for (const nodeId of group.nodes) {
            const node = nodeMap.get(nodeId);
            if (node) {
                const nodeBounds = getNodeBounds(node, nodeWidth, nodeHeight);
                bounds = computeBounds(bounds, nodeBounds);
            }
        }
        for (const groupId of group.groups) {
            const childGroup = groupMap.get(groupId);
            if (childGroup) {
                bounds = getGroupBounds(bounds, childGroup);
            }
        }
        return bounds;
    };

    let bounds: Bounds = { x: Infinity, y: Infinity, X: -Infinity, Y: -Infinity };
    bounds = getGroupBounds(bounds, group);
    return toRect(bounds);
}

export function isGTreeGroupData(target: any): target is GTreeGroupData {
    return !!(target && (target as GTreeGroupData).nodes);
}

export function isGTreeNodeData(target: any): target is GTreeNodeData {
    return !!(target && !(target as GTreeGroupData).nodes);
}
