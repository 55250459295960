import { CSSProperties, type MouseEvent, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import type { HierarchyCircularNode, ZoomView } from 'd3';
import { useElementSize } from '@custom-react-hooks/use-element-size';
import { FullGestureState, useGesture } from '@use-gesture/react';

import { getNodeFromEvent } from 'components/canvas/utils';
import GraphControls from 'components/graph/common/GraphControls';

import type { CPGNode } from './types';
import pack from './pack';
import draw from './draw';
import {
    buildColorMap,
    canvasScaleFactor,
    dimension,
    getFocusableNode,
    minZoomDimension,
    nodeToZoomView,
    zoomViewEquals,
} from './utils';
import timer from './timer';
import { useThemeMode } from 'hooks/useThemeMode';
import { useImages } from './hooks';

import styles from './styles.module.scss';
import CPGTooltip from './CPGTooltip';

// https://www.visualcinnamon.com/2015/11/learnings-from-a-d3-js-addict-on-starting-with-canvas/
// https://gist.github.com/nbremer/b6e4ec3859b2f732dfc0

interface CPGProps {
    root: CPGNode;
    focusNodeId?: string;
    count: number;
    radius?: number;
    onClick?: (node: CPGNode | undefined) => void;
    onDoubleClick?: (node: CPGNode | undefined) => void;
    onContextMenu?: (node: CPGNode | undefined, x: number, y: number) => void;
    onTooltipText?: (node: CPGNode | undefined) => string;
    duration?: number;
    extraControls?: ReactNode;
}

const ZOOM_FACTOR = 1.2;
const CANVAS_DIMENSION = 2000;

const CirclePackingGraph = (props: CPGProps) => {
    const [setRef, size] = useElementSize();

    const scaleFactor = canvasScaleFactor(size, CANVAS_DIMENSION);
    const canvasWidth = size.width * scaleFactor;
    const canvasHeight = size.height * scaleFactor;

    const mode = useThemeMode();
    const canvas = useRef<HTMLCanvasElement>(null);
    const shadowCanvas = useRef<HTMLCanvasElement>(null);

    const [colorMap, setColorMap] = useState<Map<string, HierarchyCircularNode<CPGNode>>>();

    const [rootNode, setRootNode] = useState<HierarchyCircularNode<CPGNode>>();
    const [hoverNode, setHoverNode] = useState<HierarchyCircularNode<CPGNode>>();
    const [zoomView, setZoomView] = useState<ZoomView>();

    const imageMap = useImages(rootNode);

    const context = canvas.current ? canvas.current.getContext('2d') : undefined;
    const shadowContext = shadowCanvas.current
        ? shadowCanvas.current.getContext('2d', { willReadFrequently: true })
        : undefined;
    const duration = props.duration ? props.duration : 500;
    const { root, count } = props;

    /* Animate from->to */
    const zoom = useCallback(
        (toZoomView: ZoomView, fromZoomView: ZoomView | undefined = undefined) => {
            if (!context || !shadowContext || !imageMap || !rootNode) {
                return;
            }

            if (!fromZoomView) {
                draw({
                    context,
                    shadowContext,
                    imageMap,
                    rootNode,
                    count,
                    width: canvasWidth,
                    height: canvasHeight,
                    zoomView: toZoomView,
                    mode,
                });
                return;
            }

            if (zoomViewEquals(toZoomView, fromZoomView)) {
                return;
            }

            timer((t) => {
                const zoomView = [
                    (toZoomView[0] - fromZoomView[0]) * t + fromZoomView[0], // When t=0 x=fromZoomView, when t=1, x=toZoomView
                    (toZoomView[1] - fromZoomView[1]) * t + fromZoomView[1],
                    (toZoomView[2] - fromZoomView[2]) * t + fromZoomView[2], // Diameter plus .05 so the lines don't overrun the canvas?
                ] as ZoomView;

                draw({
                    context,
                    shadowContext,
                    imageMap,
                    rootNode,
                    count,
                    width: canvasWidth,
                    height: canvasHeight,
                    zoomView,
                    mode,
                });
            }, duration);
        },
        [context, shadowContext, imageMap, rootNode, mode, duration, canvasWidth, canvasHeight, count],
    );

    const zoomTo = useCallback(
        (node: HierarchyCircularNode<CPGNode> | undefined) => {
            const focusNode = getFocusableNode(node) || rootNode;
            if (focusNode) {
                const newZoomView = nodeToZoomView(focusNode);
                zoom(newZoomView, zoomView);
                setZoomView(newZoomView);
            }
        },
        [rootNode, zoomView, zoom],
    );

    /* Pack */
    useEffect(() => {
        if (size.width && size.height) {
            const rootNode = pack({ width: size.width, height: size.height, node: root });
            const colorMap = buildColorMap(rootNode, count);

            setRootNode(rootNode);
            setColorMap(colorMap);

            // Avoid having zoomView as a dependency of useEffect.
            setZoomView((z) => (z ? z : nodeToZoomView(rootNode)));
        }
    }, [root, size, count]);

    /* Draw */
    useEffect(() => {
        if (rootNode && imageMap) {
            zoom(zoomView ? zoomView : nodeToZoomView(rootNode));
        }
    }, [rootNode, imageMap, zoomView, zoom]);

    function onClick(event: MouseEvent) {
        if (colorMap && context && shadowContext && rootNode && imageMap) {
            const node =
                getFocusableNode(getNodeFromEvent<HierarchyCircularNode<CPGNode>>(event, shadowContext, colorMap)) ||
                rootNode;
            zoomTo(node);
            props.onClick?.(node ? node.data : undefined);
        }
    }

    function onDoubleClick(event: MouseEvent) {
        if (colorMap && context && shadowContext && rootNode && imageMap) {
            const node = getNodeFromEvent<HierarchyCircularNode<CPGNode>>(event, shadowContext, colorMap);
            zoomTo(node);
            props.onDoubleClick?.(node ? node.data : undefined);
        }
    }

    function onContextMenu(event: MouseEvent) {
        if (props.onContextMenu) {
            event.stopPropagation();
            event.preventDefault();

            if (colorMap && context && shadowContext && rootNode && imageMap) {
                const node = getNodeFromEvent<HierarchyCircularNode<CPGNode>>(event, shadowContext, colorMap);
                props.onContextMenu(node?.data, event.clientX, event.clientY);
            }
        }
    }

    function onReset() {
        if (context && shadowContext && rootNode && imageMap) {
            zoomTo(rootNode);
            props.onClick?.(rootNode.data);
        }
    }

    /* We can't use onGesture.onHover since it only hovers the canvas element. */
    function onMouseMove(event: MouseEvent) {
        if (colorMap && shadowContext && zoomView) {
            const node = getNodeFromEvent<HierarchyCircularNode<CPGNode>>(event, shadowContext, colorMap);
            if (node?.data.id !== hoverNode?.data.id) {
                setHoverNode(node);
            }
        }
    }

    function onZoomIn() {
        if (zoomView) {
            const newZoomView = [zoomView[0], zoomView[1], zoomView[2] / ZOOM_FACTOR] as ZoomView;
            setZoomView(newZoomView);
        }
    }

    function onZoomOut() {
        if (zoomView) {
            const dimension = zoomView[2] * ZOOM_FACTOR;
            const newZoomView = [zoomView[0], zoomView[1], dimension] as ZoomView;
            setZoomView(newZoomView);
        }
    }

    function onDrag(state: Omit<FullGestureState<'drag'>, 'event'>) {
        if (state.pinching) {
            return state.cancel();
        }
        if (zoomView) {
            const x = zoomView[0] - state.delta[0];
            const y = zoomView[1] - state.delta[1];

            const newZoomView = [x, y, zoomView[2]] as ZoomView;
            setZoomView(newZoomView);
        }
    }

    function onWheel(state: FullGestureState<'wheel'>) {
        if (canvas.current && zoomView && rootNode) {
            const delta = state.movement[1];
            let width = zoomView[2] + delta / scaleFactor;

            if (delta > 0) {
                const d = dimension(size);
                if (width > d) {
                    width = d;
                }
            } else if (delta < 0) {
                const d = minZoomDimension(rootNode);
                if (width < d) {
                    width = d;
                }
            }

            const newZoomView = [zoomView[0], zoomView[1], width] as ZoomView;
            setZoomView(newZoomView);
        }
    }

    useGesture(
        { onDrag, onWheel, onPinch: () => {} },
        {
            target: canvas,
            pinch: { scaleBounds: { min: 0.5, max: 2 } },
            eventOptions: { passive: false },
            drag: { filterTaps: true },
        },
    );

    /* Canvas elements are inline by default which causes a gap and messes up the color calcs. */
    const style: CSSProperties = {};
    if (size.width > size.height) {
        style.height = '100%';
        style.margin = '0 auto';
    } else {
        style.width = '100%';
        style.margin = 'auto 0';
    }

    return (
        <div ref={setRef} className={styles.container}>
            <canvas
                style={style}
                className={styles.canvas}
                width={canvasWidth}
                height={canvasHeight}
                ref={canvas}
                onClick={onClick}
                onDoubleClick={onDoubleClick}
                onMouseMove={onMouseMove}
                onContextMenu={onContextMenu}
            ></canvas>
            <canvas
                style={{ ...style, visibility: 'hidden' }}
                className={styles.canvas}
                width={canvasWidth}
                height={canvasHeight}
                ref={shadowCanvas}
            ></canvas>
            <GraphControls onZoomIn={onZoomIn} onZoomOut={onZoomOut} onReset={onReset}>
                {props.extraControls}
            </GraphControls>
            {props.onTooltipText && zoomView && (
                <CPGTooltip
                    canvas={canvas.current}
                    node={hoverNode}
                    onTooltipText={props.onTooltipText}
                    width={size.width}
                    height={size.height}
                    zoomView={zoomView}
                ></CPGTooltip>
            )}
        </div>
    );
};

export default CirclePackingGraph;
