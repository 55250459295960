import logoDark from 'assets/logo-dark.png';
import logo from 'assets/logo-light.png';

import { useThemeMode } from 'hooks/useThemeMode';
import styles from './styles.module.scss';

const ViggoLogo = () => {
    const themeMode = useThemeMode();
    const src = themeMode === 'light' ? logoDark : logo;
    return (
        <div className={styles.logo}>
            <img src={src} alt={'Viggo'} />
        </div>
    );
};

export default ViggoLogo;
