import { FDGLink, FDGNode } from 'components/graph/fdg/types';
import styles from 'pages/teams/graph/styles.module.scss';

import { map } from 'utils';

import type { GraphData } from 'services/graphs';
import type { ObjectRef, Role, Team, Template, TemplateCustomField, TemplateNativeField, ThemeMode } from 'types';
import type { TeamGraphData } from './types';

const getTemplateField = (template: Template | undefined, name: string) => {
    if (template) {
        return template.template_fields.find((f) => {
            const fieldName = (f as TemplateNativeField).native_field?.name
                ? (f as TemplateNativeField).native_field.name
                : (f as TemplateCustomField).custom_field?.name;
            return fieldName && fieldName.toLowerCase() === name.toLowerCase();
        });
    }
};

export function teamRolesData(
    team_id: string,
    data: GraphData,
    teams: Team[],
    roles: Role[],
    templates: Template[],
    mode: ThemeMode,
) {
    const teamMap = map(teams);
    const roleMap = map(roles);
    const templateMap = map(templates);

    const getTemplate = (ref: ObjectRef) => {
        let obj: Role | Team | undefined = undefined;
        if (ref?.type === 'role') {
            obj = roleMap[ref.id];
        } else if (ref?.type === 'team') {
            obj = teamMap[ref.id];
        }
        return obj ? templateMap[obj.template_id] : undefined;
    };

    const nodes = data.nodes.map((objectRef) => {
        const node = { id: objectRef.id } as FDGNode;
        if (objectRef.type === 'team') {
            const team = teamMap[objectRef.id];
            node.fill = styles.teamColorFill;
            node.stroke = styles.teamColorStroke;
            node.obj = team;
            node.radiusMultiple = objectRef.id === team_id ? 2 : 1.5;
        } else {
            const role = roleMap[objectRef.id];
            node.fill = role.assignment_id ? styles.roleColorFill : mode === 'dark' ? styles.darkBg : styles.lightBg;

            node.stroke = styles.roleColorStroke;
            node.obj = role;
            //node.radius = undefined;
        }
        return node;
    });

    if (nodes.length === 0) {
        // nodes empty means that the team didn't have any relationships.
        const team = teamMap[team_id];

        nodes.push({
            id: team_id,
            fill: styles.teamColorFill,
            stroke: styles.teamColorStroke,
            obj: team,
            radiusMultiple: 2,
        } as FDGNode);
    }

    const nodeMap = new Map(
        nodes.map((node, index) => {
            node.index = index;
            return [node.id, index];
        }),
    );

    const links = data.links.map((link) => {
        const template = getTemplate(link.source as ObjectRef);
        const templateField = getTemplateField(template, link.label);

        return {
            source: nodeMap.get(link.source.id),
            target: nodeMap.get(link.target.id),
            label: link.label,
            directed: templateField?.metadata?.marker === 'arrow',
            marker: templateField?.metadata?.marker,
            style: templateField?.metadata?.style,
        } as FDGLink;
    });

    return { nodes, links } as TeamGraphData;
}
