import BaseTooltip from 'components/tooltip/BaseTooltip';
import { PropsWithChildren } from 'react';

const ElementTooltip = (
    props: PropsWithChildren<{ element: Element; dx?: number; dy?: number; position?: 'top' | 'center' }>,
) => {
    const dx = props.dx ? props.dx : 0;
    const dy = props.dy ? props.dy : 0;
    const position = props.position ? props.position : 'top';

    const rect = props.element.getBoundingClientRect();
    const x = rect.left + rect.width / 2 + dx;
    const y = position === 'top' ? rect.top + dy : rect.top + rect.height / 2 + dy;

    return (
        <BaseTooltip x={x} y={y} position={'fixed'}>
            {props.children}
        </BaseTooltip>
    );
};

export default ElementTooltip;
