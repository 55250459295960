import { PropsWithChildren } from 'react';
import { Flex } from 'antd';
import styles from './styles.module.scss';

const TeamDetailCardFooter = (props: PropsWithChildren) => {
    return (
        <Flex className={styles.footer} justify={'space-between'}>
            {props.children}
        </Flex>
    );
};

export default TeamDetailCardFooter;
