import React, { useState } from 'react';
import { App } from 'antd';

import type { Create, Meeting, MeetingType, Team } from 'types';
import { getErrorMessage } from 'utils';
import { createMeeting, updateMeeting } from 'services/meetings';

import { useOrg } from 'hooks/useOrg';

import CreateEditSteps from 'components/steps/common/CreateEditSteps';
import DescriptionFormItem from 'components/forms/common/DescriptionFormItem';
import NameFormItem from 'components/forms/common/NameFormItem';

import MeetingTypeFormItem from './MeetingTypeFormItem';
import MeetingElementsFormItem from './MeetingElementsFormItem';

const DEFAULT_MEETING_ELEMENTS = [
    { title: 'Check-in', duration: '5m' },
    { title: 'Create Agenda', duration: '5m' },
    { title: 'Updates', duration: '5m' },
    { title: 'Discussion', duration: '30m' },
];

type CreateEditMeetingPropsBase = {
    team: Team;
    onSuccess: (meeting: Meeting) => void;
    onCancel: () => void;
};

type CreateMeetingProps = CreateEditMeetingPropsBase;

type EditMeetingProps = CreateEditMeetingPropsBase & {
    meeting: Meeting;
};

type CreateEditMeetingProps = CreateMeetingProps | EditMeetingProps;

const isEditProps = (props: CreateEditMeetingProps) => !!(props as EditMeetingProps).meeting;

const CreateEditMeetingSteps = (props: CreateEditMeetingProps) => {
    const { message } = App.useApp();
    const org = useOrg();
    const isEdit = isEditProps(props);

    const [meetingType, setMeetingType] = useState<MeetingType>();
    const [meeting, setMeeting] = useState(() => {
        if (isEditProps(props)) {
            return (props as EditMeetingProps).meeting;
        }
        return { elements: DEFAULT_MEETING_ELEMENTS } as Meeting;
    });

    function onCancel() {
        props.onCancel();
        setTimeout(() => {
            setMeeting(isEditProps(props) ? (props as EditMeetingProps).meeting : ({} as Meeting));
        }, 0);
    }

    async function onCreate() {
        try {
            const newMeeting = {
                ...meeting,
                meeting_type_id: meetingType?.id,
                team_id: props.team.id,
            } as Create<Meeting>;
            const res = await createMeeting(org!, newMeeting);
            props.onSuccess(res);
            message.success('Your meeting was created successfully.');
        } catch (e) {
            message.error(getErrorMessage(e));
        }
    }

    async function onUpdate() {
        try {
            const newMeeting = {
                ...meeting,
                meeting_type: { id: meetingType?.id, type: 'meeting_type' },
                team: { id: props.team.id, type: 'team' },
            } as Meeting;
            const res = await updateMeeting(org!, newMeeting);
            props.onSuccess(res);
            message.success('Your meeting was updated successfully.');
        } catch (e) {
            message.error(getErrorMessage(e));
        }
    }

    async function onConfirm() {
        return isEdit ? onUpdate() : onCreate();
    }

    const steps = [];

    if (!isEdit) {
        steps.push({
            title: 'Meeting Type',
            content: (
                <MeetingTypeFormItem
                    meetingType={meetingType}
                    setMeetingType={(meetingType) => {
                        if (meeting && !meeting.name) {
                            setMeeting({ ...meeting, name: meetingType.name });
                        }
                        setMeetingType(meetingType);
                    }}
                />
            ),
            valid: () => !!meetingType,
        });
    }

    steps.push(
        {
            title: 'Name',
            content: (
                <NameFormItem
                    name={meeting.name}
                    onChange={(name) => {
                        setMeeting({ ...meeting, name });
                    }}
                />
            ),
            valid: () => !!meeting.name,
        },
        {
            title: 'Description',
            content: (
                <DescriptionFormItem
                    description={meeting.description}
                    onChange={(description) => {
                        setMeeting({ ...meeting, description });
                    }}
                />
            ),
            valid: () => true,
        },
        {
            title: 'Elements',
            content: (
                <MeetingElementsFormItem
                    elements={meeting.elements}
                    setElements={(elements) => {
                        setMeeting({ ...meeting, elements });
                    }}
                />
            ),
            valid: () => true,
        },
    );

    return (
        <CreateEditSteps
            steps={steps}
            onCreate={onConfirm}
            onCancel={onCancel}
            actionText={isEditProps(props) ? 'Update' : 'Create'}
        />
    );
};

export default CreateEditMeetingSteps;
