import GotoTeamButton from 'components/buttons/GotoTeamButton';
import TemplateDrawerDescription from 'components/drawers/common/TemplateDrawerDescription';
import type { Team, TemplatedObject } from 'types';

import { useTemplate } from 'hooks/templates';

import Object from 'components/Object';
import DrawerField from 'components/drawers/common/DrawerField';
import { getField, isTeamPage } from 'utils';

const ObjectDrawerDisplay = (props: { obj: TemplatedObject }) => {
    const template = useTemplate(props.obj.template_id, props.obj.type);
    const templateFields = template ? template.template_fields : [];

    return (
        <>
            {templateFields.map((templateField, i) => {
                const field = getField(templateField);
                return (
                    <DrawerField
                        key={`${field.type}=${field.name}-${i}`}
                        obj={props.obj}
                        templateField={templateField}
                    />
                );
            })}
            <TemplateDrawerDescription objectType={props.obj.type} templateId={props.obj.template_id} />

            {props.obj.type === 'team' && !isTeamPage(props.obj.id) && <GotoTeamButton team={props.obj as Team} />}

            <Object style={{ margin: '2em 0' }} object={props.obj} />
        </>
    );
};

export default ObjectDrawerDisplay;
