import { useEffect } from 'react';

import apiClient from 'api';
import { fetchOrgUnits, selectOrgUnits, selectOrgUnitStatus } from 'features/orgUnitSlice';

import { OrgUnit } from 'types';
import { useAppDispatch, useAppSelector } from 'store';
import { nameSort } from 'utils';

export function useOrgUnits(): OrgUnit[] | null {
    const dispatch = useAppDispatch();
    const orgUnits = useAppSelector(selectOrgUnits);
    const orgUnitStatus = useAppSelector(selectOrgUnitStatus);
    const token = apiClient.token;

    useEffect(() => {
        if (token && orgUnitStatus === 'idle') {
            dispatch(fetchOrgUnits());
        }
    }, [token, orgUnitStatus, dispatch]);

    return orgUnitStatus === 'succeeded' ? [...orgUnits].sort(nameSort) : null;
}

export function useOrgUnit(id: string | undefined) {
    const orgUnits = useOrgUnits();
    return orgUnits && id ? orgUnits.find((orgUnit) => orgUnit.id === id) : null;
}
