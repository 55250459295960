import { Typography } from 'antd';
import type { BaseObject, OneToManyField } from 'types';
import { fieldLabel, getObjectUrl } from 'utils';
import { useObjects } from 'hooks/useObjects';
import ObjectTypeIcon from 'components/ObjectTypeIcon';
import DrawerDescription from './DrawerDescription';

const FieldItem = (props: { obj: BaseObject; objects: BaseObject[] }) => {
    const value = props.objects.find((obj) => obj.id === props.obj.id);
    if (!value) {
        return (
            <div>
                {fieldLabel(props.obj.type)}[{props.obj.id}] Not Found! Please report this error.
            </div>
        );
    }

    return (
        <Typography.Link style={{ display: 'block' }} href={getObjectUrl(props.obj)}>
            <ObjectTypeIcon style={{ marginRight: '.25em' }} type={props.obj.type} /> {value.name}
        </Typography.Link>
    );
};

const OneToManyFieldDrawerDescription = (props: { obj: BaseObject & { [p: string]: any }; field: OneToManyField }) => {
    const objects = useObjects(props.field.config.allowed);

    const refs = props.obj[props.field.name] as BaseObject[] | undefined;
    if (!refs) {
        return null;
    }

    return (
        <DrawerDescription title={fieldLabel(props.field.name)}>
            {refs.map((objectRef, i) => {
                const obj = objects[objectRef.type].find((obj) => obj.id === objectRef.id);
                return obj ? (
                    <FieldItem
                        key={`${i}`} //key={`${ref.type}-${ref.id}-${i}`}
                        obj={obj}
                        objects={objects[objectRef.type] || []}
                    />
                ) : null;
            })}
        </DrawerDescription>
    );
};

export default OneToManyFieldDrawerDescription;
