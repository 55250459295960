import { Form, InputNumber } from 'antd';
import { useFocusRef } from 'hooks/focus';
import { TemplateField, NumberField } from 'types';
import { fieldLabel, getField, isDefined } from 'utils';

function helpText(numberField: NumberField) {
    let strings = [];
    if (isDefined(numberField.config.ge)) {
        strings.push(`Min Value: ${numberField.config.ge}`);
    }
    if (isDefined(numberField.config.le)) {
        strings.push(`Max Value: ${numberField.config.le}`);
    }
    return strings.join(', ');
}

function valid(value: number | undefined, templateField: TemplateField) {
    if (!isDefined(value)) {
        return false;
    }

    const numberField = getField(templateField) as NumberField;
    if (isDefined(numberField.config.le) && (value as number) > (numberField.config.le as number)) {
        return false;
    }
    return !(isDefined(numberField.config.ge) && (value as number) < (numberField.config.ge as number));
}

const NumberFormItem = (props: {
    templateField: TemplateField;
    value: number | undefined;
    setValue: (value: number | undefined) => void;
    autoFocus?: boolean;
}) => {
    const ref = useFocusRef(props.autoFocus);
    const numberField = getField(props.templateField) as NumberField;
    const value = isDefined(props.value) ? (props.value as number) : null;

    const help = helpText(numberField);
    const min = numberField.config.ge !== null ? numberField.config.ge : undefined;
    const max = numberField.config.le !== null ? numberField.config.le : undefined;

    return (
        <Form.Item
            className={'form-control'}
            label={fieldLabel(numberField.name)}
            rules={[{ required: props.templateField.required, message: 'Please enter a value.' }]}
            name={numberField.name}
            initialValue={value}
            help={help}
        >
            <InputNumber
                ref={ref}
                onChange={(value) => {
                    const newValue = value !== null ? value : undefined;
                    props.setValue(newValue);
                }}
                min={min}
                max={max}
            />
        </Form.Item>
    );
};

NumberFormItem.valid = valid;
export default NumberFormItem;
