import { ReactComponent as TextSvg } from 'assets/text.svg';
import { ReactComponent as LinkSvg } from 'assets/link.svg';
import { ReactComponent as NumberSvg } from 'assets/number.svg';
import { ReactComponent as SingleSelectSvg } from 'assets/single-select.svg';
import { ReactComponent as MultiSelectSvg } from 'assets/multi-select.svg';
import { ReactComponent as OneToOneSvg } from 'assets/one-to-one.svg';
import { ReactComponent as OneToManySvg } from 'assets/one-to-many.svg';
import { useThemeMode } from 'hooks/useThemeMode';

import type { FieldType } from 'types';

const SettingsFieldIcon = (props: { type: FieldType }) => {
    const mode = useThemeMode();
    const color = mode === 'dark' ? 'white' : 'black';

    switch (props.type) {
        case 'text':
            return <TextSvg stroke={color} />;
        case 'link':
            return <LinkSvg fill={color} stroke={color} />;
        case 'number':
            return <NumberSvg fill={color} stroke={color} />;
        case 'single-select':
            return <SingleSelectSvg stroke={color} />;
        case 'multi-select':
            return <MultiSelectSvg stroke={color} />;
        case 'one-to-one':
            return <OneToOneSvg fill={color} />;
        case 'one-to-many':
            return <OneToManySvg fill={color} />;
    }
};

export default SettingsFieldIcon;
