import apiClient from 'api';
import type { Organization, Meeting, Create, AgendaItem, CreateAgendaItem, UpdateAgendaItem } from 'types';

export async function fetchMeetings(org: Organization, teamId: string | undefined = undefined) {
    const url = teamId ? `/org/${org.id}/meetings?team=${teamId}` : `/org/${org.id}/meetings`;
    return apiClient.get<Meeting[]>(url);
}

export async function createMeeting(org: Organization, meeting: Create<Meeting>) {
    return apiClient.post<Meeting>(`/org/${org.id}/meetings`, meeting);
}

export async function updateMeeting(org: Organization, meeting: Meeting) {
    const { id, ...update } = meeting;
    return apiClient.patch<Meeting>(`/org/${org.id}/meetings/${id}`, update);
}

export async function deleteMeeting(org: Organization, meeting: Meeting) {
    return apiClient.delete<Meeting>(`/org/${org.id}/meetings/${meeting.id}`);
}

export async function createAgendaItem(org: Organization, agendaItem: CreateAgendaItem) {
    return apiClient.post<AgendaItem>(`/org/${org.id}/agenda_items`, agendaItem);
}

export async function updateAgendaItem(org: Organization, id: string, updateAgendaItem: UpdateAgendaItem) {
    return apiClient.patch<AgendaItem>(`/org/${org.id}/agenda_items/${id}`, updateAgendaItem);
}

export async function deleteAgendaItem(org: Organization, agendaItemId: string) {
    return apiClient.delete<AgendaItem>(`/org/${org.id}/agenda_items/${agendaItemId}`);
}
