import { ChangeEvent } from 'react';
import { Form, Input } from 'antd';
import { isUndefined } from 'utils';

type DescriptionFormItemProps = {
    description: string;
};

const DescriptionFormItem = (
    props: Partial<DescriptionFormItemProps> & {
        onChange?: (description: string) => void;
        required?: boolean;
    },
) => {
    const isManaged = isUndefined(props.onChange);

    const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        props.onChange?.(e.target.value);
    };

    const rules = props.required ? [{ required: true, message: 'Please enter a description.' }] : undefined;

    return (
        <Form.Item<DescriptionFormItemProps>
            className={'form-control'}
            label={'Description'}
            name={isManaged ? 'description' : undefined}
            rules={rules}
            initialValue={isManaged ? props.description : undefined}
            required={props.required}
        >
            <Input.TextArea
                value={isManaged ? undefined : props.description}
                onChange={isManaged ? undefined : onChange}
            />
        </Form.Item>
    );
};

export default DescriptionFormItem;
