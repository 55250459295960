import apiClient from 'api';
import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'store';

import type { Template } from 'types';
import { nameSort } from 'utils';
import { fetchTemplates, selectTemplates, selectTemplateStatus } from 'features/templateSlice';

export function useTemplates(objectType?: Template['type']): Template[] {
    const dispatch = useAppDispatch();
    let templates = useAppSelector(selectTemplates) || [];
    const templateStatus = useAppSelector(selectTemplateStatus);
    const token = apiClient.token;

    useEffect(() => {
        if (token && templateStatus === 'idle') {
            dispatch(fetchTemplates());
        }
    }, [token, templateStatus, dispatch]);

    if (objectType) {
        templates = templates.filter((template) => template.type === objectType);
    }

    return [...templates].sort(nameSort);
}

export function useTemplate(id: string | undefined, objectType: string | undefined): Template | undefined {
    const templates = useTemplates();
    return id && objectType
        ? templates.find((template) => template.id === id && template.type === objectType)
        : undefined;
}
