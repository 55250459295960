import { type MouseEvent, type ReactNode, useRef, useState } from 'react';
import * as d3 from 'd3';
import { type Vector2 } from '@use-gesture/react';

import GraphControls from 'components/graph/common/GraphControls';

import { Datum, GTreeGroupData, GTreeNodeData, ViewBox } from './types';
import { getRootBBox } from './utils';
import { useViewBoxGestures } from './hooks';
import GTreeLink from './GTreeLink';
import GTreeNode from './GTreeNode';
import GTreeGroup from './GTreeGroup';

import styles from './styles.module.scss';

const ZOOM_FACTOR = 1.2;

const GTreeGraphSVG = (props: {
    width: number;
    height: number;
    nodeWidth: number;
    nodeHeight: number;
    graphData: d3.HierarchyNode<Datum>;
    groups: GTreeGroupData[];
    onNodeContent: (node: d3.HierarchyNode<Datum>) => ReactNode;
    onGroupClick: (group: GTreeGroupData) => void;
    onContextMenu?: (target: GTreeNodeData | GTreeGroupData | null, x: number, y: number) => void;
}) => {
    const rect = getRootBBox(props.graphData, props.nodeWidth, props.nodeHeight);
    const width = rect.width + 2 * props.nodeWidth;
    const height = rect.height + 2 * props.nodeHeight;

    const ref = useRef<SVGSVGElement>(null);
    const [viewBox, setViewBox] = useState<ViewBox>(() => {
        return new ViewBox({
            cx: rect.x - props.nodeWidth + width / 2,
            cy: rect.y - props.nodeHeight + height / 2,
            width,
            height,
        });
    });

    const scaleFactor: Vector2 = [width / props.width, height / props.height];
    useViewBoxGestures(ref, setViewBox, scaleFactor); // FIXME: calculate scale factor

    const onContextMenu = (event: MouseEvent) => {
        if (props.onContextMenu) {
            event.preventDefault();
            event.stopPropagation();
            props.onContextMenu(null, event.clientX, event.clientY);
        }
    };

    const onZoomIn = () => setViewBox(viewBox.setScale(viewBox.scale / ZOOM_FACTOR));
    const onZoomOut = () => setViewBox(viewBox.setScale(viewBox.scale * ZOOM_FACTOR));
    const onReset = () => setViewBox(viewBox.reset());

    const nodes = props.graphData.descendants();

    const nodeMap = new Map<string, d3.HierarchyNode<Datum>>();
    for (const node of nodes) {
        nodeMap.set(node.data.id, node);
    }

    const groupMap = new Map<string, GTreeGroupData>();
    for (const group of props.groups) {
        groupMap.set(group.id, group);
    }

    return (
        <>
            <svg
                className={styles.svg}
                ref={ref}
                width={props.width}
                height={props.height}
                viewBox={viewBox.toString()}
                onContextMenu={onContextMenu}
            >
                <g>
                    {props.groups.map((group, index) => (
                        <GTreeGroup
                            key={index}
                            group={group}
                            nodeMap={nodeMap}
                            groupMap={groupMap}
                            width={props.nodeWidth}
                            height={props.nodeHeight}
                            onClick={props.onGroupClick}
                            onContextMenu={props.onContextMenu}
                        />
                    ))}
                </g>
                <g>
                    {nodes.map((node, index) => (
                        <GTreeNode
                            key={index}
                            node={node}
                            width={props.nodeWidth}
                            height={props.nodeHeight}
                            onNodeContent={props.onNodeContent}
                            onContextMenu={props.onContextMenu}
                        />
                    ))}
                </g>
                <g>
                    {props.graphData.links().map((d, index) => (
                        <GTreeLink key={index} d={d} width={props.nodeWidth} height={props.nodeHeight} />
                    ))}
                </g>
            </svg>
            <GraphControls
                onZoomIn={onZoomIn}
                onZoomOut={onZoomOut}
                onReset={onReset}
                resetDisabled={!viewBox.canBeReset}
            />
        </>
    );
};

export default GTreeGraphSVG;
