import { GFDGConstraint, GFDGGroup, GFDGLink, GFDGNode } from 'pages/orgUnit/gfdg/types';
import type { OrgUnit, Position } from 'types';

const PADDING = 20;

// Positions are nodes, org units are groups.
export function orgUnitData(data: { positions: Position[]; orgUnits: OrgUnit[] }) {
    const nodeMap = new Map<string, GFDGNode>();
    const groupMap = new Map<string, GFDGGroup>();
    const childrenMap = new Map<string, GFDGNode[]>();

    const nodes = data.positions.map((position, index) => {
        const node = { type: 'node', id: position.id, obj: position } as GFDGNode;
        node.index = index;
        node.width = 240;
        node.height = 160;
        nodeMap.set(position.id, node);

        if (position.reports_to) {
            const children = childrenMap.get(position.reports_to.id) || [];
            children.push(node);
            childrenMap.set(position.reports_to.id, children);
        }

        return node;
    });

    const links = data.positions
        .map((position) => {
            const source = nodeMap.get(position.id);
            if (position.reports_to?.id) {
                const target = nodeMap.get(position.reports_to.id);
                // NOTE: intentionally swap these to draw the layout upside down
                //return { source: source?.index, target: target?.index } as GFDGLink;
                return { type: 'link', target: source?.index, source: target?.index } as GFDGLink;
            }
            return null;
        })
        .filter((value) => !!value) as GFDGLink[];

    const groups = data.orgUnits.map((orgUnit, index) => {
        const group = { type: 'group', id: orgUnit.id, padding: PADDING, index, obj: orgUnit } as GFDGGroup;
        group.leaves = [];
        group.groups = [];
        groupMap.set(orgUnit.id, group);
        return group;
    });

    for (const position of data.positions) {
        const group = groupMap.get(position.org_unit?.id);
        if (group) {
            group.leaves = group.leaves ? group.leaves : [];
            const node = nodeMap.get(position.id);
            if (node) {
                group.leaves.push(node.index);
            }
        }
    }

    for (const orgUnit of data.orgUnits) {
        if (orgUnit.org_unit?.id) {
            const parentGroup = groupMap.get(orgUnit.org_unit.id);
            if (parentGroup) {
                parentGroup.groups = parentGroup.groups ? parentGroup.groups : [];
                const group = groupMap.get(orgUnit.id);
                if (group) {
                    parentGroup.groups.push(group.index);
                }
            }
        }
    }

    const constraints: GFDGConstraint[] = [];
    for (const key of Array.from(childrenMap.keys())) {
        const children = childrenMap.get(key);
        if (children) {
            for (let i = 0; i < children.length - 1; i++) {
                constraints.push({ axis: 'x', left: children[i].index, right: children[i + 1].index, gap: 180 });
            }
        }
    }

    return { nodes, links, groups: groups.filter((g) => g.groups?.length || g.leaves?.length), constraints };
}
