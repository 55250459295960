import { Checkbox, Form } from 'antd';
import { CreateEditTemplateCustomField } from 'types';

import styles from './styles.module.scss';
import SettingsButtonBar from 'pages/settings/common/SettingsButtonBar';
import TemplateFieldStyle from './TemplateFieldStyle';
import TemplateFieldSelect from './TemplateFieldSelect';

const TemplateCustomFieldForm = (props: {
    templateField?: CreateEditTemplateCustomField;
    onFinish: (templateField: CreateEditTemplateCustomField) => void;
    onCancel?: () => void;
    excludedFieldIds?: string[];
}) => {
    const [form] = Form.useForm();
    const fieldId = Form.useWatch('field_id', form);

    /* For edit, make sure we allow the currently selected field too. */
    const excludedFieldIds = props.excludedFieldIds
        ? props.excludedFieldIds.filter((id) => id !== props.templateField?.field_id)
        : [];

    return (
        <Form<CreateEditTemplateCustomField>
            name="template-field"
            className={styles.form}
            initialValues={props.templateField}
            onFinish={props.onFinish}
            autoComplete="off"
            form={form}
        >
            <Form.Item
                label="Field"
                name="field_id"
                className="form-control"
                rules={[{ required: true, message: 'Please input a name.' }]}
            >
                <TemplateFieldSelect excludedFieldIds={excludedFieldIds} />
            </Form.Item>

            <Form.Item label="Required" name="required" valuePropName="checked">
                <Checkbox />
            </Form.Item>

            <TemplateFieldStyle fieldId={fieldId} />

            <SettingsButtonBar onCancel={() => props.onCancel?.()} />
        </Form>
    );
};

export default TemplateCustomFieldForm;
