import React, { CSSProperties } from 'react';
import { useElementSize } from '@custom-react-hooks/use-element-size';

import type { Team, TemplatedObject } from 'types';

import styles from './styles.module.scss';
import { useTeamGraphData } from 'hooks/data';
import { useThemeMode } from 'hooks/useThemeMode';

import Loading from 'components/Loading';
import type { FDGNode, FDGLink } from 'components/graph/fdg/types';
import type { TeamGraphName } from 'pages/teams/TeamGraphSelect';

import TeamTooltipContent from './tooltips/TeamTooltipContent';
import TeamRolesGraph from './TeamRolesGraph';

const TeamGraph = (props: { team: Team; graphName: TeamGraphName; onClick?: (obj: TemplatedObject) => void }) => {
    const mode = useThemeMode();
    const [setRef, size] = useElementSize();
    const objs = useTeamGraphData(props.team);

    if (!objs) {
        return <Loading />;
    }

    function onTooltipText(target: FDGNode | FDGLink) {
        return target ? <TeamTooltipContent target={target} /> : null;
    }

    const style: CSSProperties = {};
    if (size.width && size.height) {
        if (size.width > (3 * size.height) / 2) {
            style.height = `${size.height}px`;
        } else {
            style.width = `${size.width}px`;
        }
    }

    /* expand when other graphs are available */
    let depth: 2 | 3 | 4 = 3;
    switch (props.graphName) {
        case 'team-roles-2':
            depth = 2;
            break;
        case 'team-roles-4':
            depth = 4;
            break;
    }

    return (
        <div ref={setRef} className={styles.container}>
            {size.width && size.height ? (
                <TeamRolesGraph
                    team={props.team}
                    width={size.width}
                    height={size.height}
                    mode={mode}
                    depth={depth}
                    onTooltipText={onTooltipText}
                    onClick={(node) => props.onClick?.(node.obj as TemplatedObject)}
                />
            ) : null}
        </div>
    );
};

export default TeamGraph;
