import { CompressOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Space, Tooltip } from 'antd';
import { PropsWithChildren, ReactNode } from 'react';

import styles from './styles.module.scss';

const ResetButton = (props: { onClick: () => void; disabled?: boolean }) => (
    <Tooltip placement={'left'} title={'Reset'}>
        <Button size={'small'} disabled={props.disabled} icon={<CompressOutlined />} onClick={props.onClick} />
    </Tooltip>
);

const ZoomInButton = (props: { onClick: () => void; disabled?: boolean }) => (
    <Tooltip placement={'left'} title={'Zoom In'}>
        <Button size={'small'} disabled={props.disabled} icon={<PlusOutlined />} onClick={props.onClick} />
    </Tooltip>
);

const ZoomOutButton = (props: { onClick: () => void; disabled?: boolean }) => (
    <Tooltip placement={'left'} title={'Zoom Out'}>
        <Button size={'small'} disabled={props.disabled} icon={<MinusOutlined />} onClick={props.onClick} />
    </Tooltip>
);

const GraphControls = (
    props: PropsWithChildren<{
        onZoomIn: () => void;
        onZoomOut: () => void;
        onReset: () => void;
        resetDisabled?: boolean;
        extraControls?: ReactNode;
    }>,
) => {
    return (
        <Space size={4} direction={'vertical'} className={styles.controls}>
            <ResetButton onClick={props.onReset} disabled={props.resetDisabled} />
            <ZoomInButton onClick={props.onZoomIn} />
            <ZoomOutButton onClick={props.onZoomOut} />
            {props.children}
        </Space>
    );
};

export default GraphControls;
