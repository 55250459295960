import React from 'react';
import { Form } from 'antd';

import styles from './styles.module.scss';

const ForgotPasswordLink = () => {
    return (
        <Form.Item>
            <a className={styles.link} href="/forgot-password">
                Forgot password?
            </a>
        </Form.Item>
    );
};

export default ForgotPasswordLink;
