import { type CSSProperties, useState } from 'react';
import { Form, Input, type InputProps } from 'antd';
import { LockOutlined } from '@ant-design/icons';

import PasswordChecker from 'components/auth/PasswordChecker';
import { PASSWORD_LENGTH } from 'utils';

const NewPasswordFormItem = (props: { autoFocus?: InputProps['autoFocus'] }) => {
    const [isFocused, setIsFocused] = useState(false);
    const form = Form.useFormInstance();
    const password = Form.useWatch('password', form);

    const style: CSSProperties = {
        marginBottom: password ? '.5em' : undefined,
    };

    return (
        <>
            <Form.Item
                style={style}
                name="password"
                rules={[
                    {
                        required: true,
                        message: 'REQUIRED',
                    },
                    {
                        validator(_, value) {
                            if (!value || PasswordChecker.isValid(value, PASSWORD_LENGTH)) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error(''));
                        },
                    },
                ]}
            >
                <Input
                    prefix={<LockOutlined />}
                    type="password"
                    placeholder="Password"
                    autoComplete={'new-password'}
                    autoFocus={props.autoFocus}
                    minLength={PASSWORD_LENGTH}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                />
            </Form.Item>
            <PasswordChecker
                style={{ display: isFocused ? 'block' : 'none', margin: '0 0 2rem 0' }} // negative margin when show
                password={password}
                length={PASSWORD_LENGTH}
            />
        </>
    );
};

export default NewPasswordFormItem;
