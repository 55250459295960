import type { Team } from 'types';
import CreateRoleSteps from 'components/steps/CreateRoleSteps';
import BaseCreateButton, { type CreateButtonType } from 'components/buttons/BaseCreateButton';
import Show from 'components/Show';
import { useRoleCreatePermission } from 'hooks/permissions';

const CreateRoleButton = (props: { team?: Team; type?: CreateButtonType }) => (
    <Show permission={useRoleCreatePermission(props.team?.id)}>
        <BaseCreateButton text={'Create Role'} type={props.type}>
            {(onSuccess, onCancel) => <CreateRoleSteps team={props.team} onSuccess={onSuccess} onCancel={onCancel} />}
        </BaseCreateButton>
    </Show>
);
export default CreateRoleButton;
