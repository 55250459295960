import { FDGLink } from 'components/graph/fdg/types';
import { asSource, asTarget } from 'pages/orgUnit/gfdg/utils';

const LINE_COLOR = '#999';

const GroupedForceDirectedGraphLink = (props: { link: FDGLink }) => {
    const color = props.link.style?.color ? props.link.style.color : LINE_COLOR;

    const strokeWidth = props.link.style?.strokeWidth ? props.link.style.strokeWidth : 1;

    // FIXME: probably don't need directed
    return (
        <line
            className={'link'}
            x1={asSource(props.link).x}
            y1={asSource(props.link).y}
            x2={asTarget(props.link).x}
            y2={asTarget(props.link).y}
            stroke={color}
            strokeWidth={(strokeWidth as number) * 4}
        />
    );
};

export default GroupedForceDirectedGraphLink;
