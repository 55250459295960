import { useState } from 'react';
import { Select, SelectProps } from 'antd';
import debounce from 'lodash/debounce';

import { search } from 'services/search';
import { useOrg } from 'hooks/useOrg';
import { ObjectType, SearchResult } from 'types';

import styles from './styles.module.scss';
import { resultsToOptions } from './utils';
import { useLatestPromise } from './hooks';

const OBJECT_TYPES = ['zone', 'team', 'person', 'objective', 'role', 'decision', 'agreement'] as ObjectType[];

const SearchInput = (props: { onChange: (url: string) => void }) => {
    const org = useOrg();
    const [options, setOptions] = useState<SelectProps['options']>([]);

    // Protect against the search calls returning out of order.
    const { execute } = useLatestPromise<SearchResult[]>();

    const onSearch = async (newValue: string) => {
        if (newValue && org) {
            try {
                const results = await execute(() => {
                    return search(org, newValue, OBJECT_TYPES);
                });
                if (results !== null) {
                    setOptions(resultsToOptions(results));
                }
            } catch (e) {
                console.log(e);
            }
        } else {
            setOptions([]);
        }
    };

    const onChange = (newValue: string) => {
        props.onChange(newValue);
    };

    return (
        <Select
            className={styles.searchInput}
            showSearch
            placeholder={'Enter search terms'}
            defaultActiveFirstOption={false}
            filterOption={false}
            onSearch={debounce(onSearch, 300)}
            onChange={onChange}
            options={options}
        />
    );
};

export default SearchInput;
