import React from 'react';
import type { BaseObject, Team, TeamObject } from 'types';
import { useTeam, useTeams } from 'hooks/teams';
import { useZones } from 'hooks/useZones';

export const Sep = () => <span>in</span>;

export const BaseLabel = (props: { name: string }) => {
    return <div>{props.name}</div>;
};

export const TeamLabel = (props: { id: string; name: string }) => {
    const zones = useZones();
    const team = useTeam(props.id);
    const zone = zones.find((z) => z.id === team?.zone.id);

    return zone ? (
        <div>
            {props.name} <Sep /> {zone.name}
        </div>
    ) : (
        <BaseLabel name={props.name} />
    );
};

export const TeamObjectLabel = (props: { name: string; teamId?: string }) => {
    const teams = useTeams() || [];
    const team = props.teamId ? teams.find<Team>((t: Team): t is Team => t.id === props.teamId) : undefined;

    return team ? (
        <div>
            {props.name} <Sep /> {team.name}
        </div>
    ) : (
        <BaseLabel name={props.name} />
    );
};

export const ObjectLabel = (props: { obj: BaseObject }) => {
    switch (props.obj.type) {
        case 'team':
            return <TeamLabel {...props.obj} />;
        case 'decision':
        case 'objective':
        case 'role':
            const teamObject = props.obj as TeamObject;
            return <TeamObjectLabel name={teamObject.name} teamId={teamObject.team.id} />;
    }
    return <BaseLabel name={props.obj.name} />;
};
