import { LockOutlined, MailOutlined, SafetyOutlined } from '@ant-design/icons';
import { App, Button, Form, Input, Radio, Typography } from 'antd';
import apiClient from 'api';
import { type MfaResponse } from 'pages/auth/common/types';
import { useEffect, useState } from 'react';

type MfaFormRequest = {
    username: string;
    otp: string;
    password: string;
    trust_device: boolean;
};

const MfaForm = (props: { onLogin?: (data: MfaResponse) => void }) => {
    const { message } = App.useApp();
    const username = localStorage.getItem('username');
    const [readOnly, setReadOnly] = useState(true);

    useEffect(() => {
        /* prevent chrome autofill */
        setReadOnly(false);
    }, []);

    async function onFinish(values: MfaFormRequest) {
        const req = { ...values };

        if (username) {
            req.username = username;
        }

        try {
            const res = await apiClient.post<MfaResponse>('/auth/mfa', req);
            localStorage.removeItem('username');
            props.onLogin?.(res);
        } catch (e) {
            message.error('Invalid One-time code or Password.  Please try again.');
        }
    }

    return (
        <Form<MfaFormRequest> name="login" onFinish={onFinish} autoComplete={'off'}>
            <Form.Item
                name="otp"
                rules={[
                    {
                        required: true,
                        message: 'REQUIRED',
                    },
                ]}
            >
                <Input
                    prefix={<SafetyOutlined />}
                    type="text"
                    placeholder="One-time code"
                    autoComplete={'off'}
                    readOnly={readOnly}
                    autoFocus
                />
            </Form.Item>
            <Form.Item
                style={{ display: username ? 'none' : undefined }}
                name="username"
                initialValue={username}
                rules={[
                    {
                        required: true,
                        message: 'REQUIRED',
                    },
                ]}
            >
                <Input prefix={<MailOutlined />} placeholder="Email" autoComplete={'email'} />
            </Form.Item>
            <Form.Item
                name="password"
                rules={[
                    {
                        required: true,
                        message: 'REQUIRED',
                    },
                ]}
            >
                <Input
                    prefix={<LockOutlined />}
                    type="password"
                    placeholder="Password"
                    autoComplete={'current-password'}
                />
            </Form.Item>
            <hr />
            <h4>Would you like to save this device?</h4>
            <Typography.Paragraph>Only save this device if it's a trusted personal device.</Typography.Paragraph>

            <Form.Item
                name="trust_device"
                rules={[
                    {
                        required: true,
                        message: 'REQUIRED',
                    },
                ]}
            >
                <Radio.Group
                    name={'trust_device'}
                    options={[
                        { value: true, label: 'Yes, save this device.' },
                        { value: false, label: 'No, do not save this device.' },
                    ]}
                />
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="submit">
                    Verify
                </Button>
            </Form.Item>
        </Form>
    );
};

export default MfaForm;
