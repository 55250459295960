import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { OrgUnit, SliceStatus, Organization, ErrorDetail } from 'types';
import { CLEAR_ORG_ACTION, LOGOUT_ACTION, RootState } from 'store';
import apiClient from 'api';

type OrgUnitsState = {
    entities: OrgUnit[];
    status: SliceStatus;
    error: string;
};

export const fetchOrgUnits = createAsyncThunk<OrgUnit[]>('org_units/fetch', async (_, thunkAPI) => {
    const state = thunkAPI.getState() as { org: { value: Organization } };
    return await apiClient.get(`/org/${state.org.value.id}/org_units`);
});

export const deleteOrgUnit = createAsyncThunk<
    OrgUnit,
    string,
    {
        rejectValue: ErrorDetail;
    }
>('org_unit/delete', async (userId, thunkAPI) => {
    const state = thunkAPI.getState() as { org: { value: Organization } };
    try {
        return await apiClient.delete<OrgUnit>(`/org/${state.org.value.id}/org_units/${userId}`);
    } catch (e) {
        return thunkAPI.rejectWithValue((await (e as Response).json()) as ErrorDetail);
    }
});

export const createOrgUnit = createAsyncThunk<
    OrgUnit,
    OrgUnit,
    {
        rejectValue: ErrorDetail;
    }
>('org_unit/create', async (org_unit, thunkAPI) => {
    const state = thunkAPI.getState() as { org: { value: Organization } };
    try {
        return await apiClient.post(`/org/${state.org.value.id}/org_units`, org_unit);
    } catch (e) {
        return thunkAPI.rejectWithValue(e as ErrorDetail);
    }
});

type UpdateOrgUnit = Partial<OrgUnit> & { id: string };
export const updateOrgUnit = createAsyncThunk<
    OrgUnit,
    UpdateOrgUnit,
    {
        rejectValue: ErrorDetail;
    }
>('org_unit/update', async (data, thunkAPI) => {
    const state = thunkAPI.getState() as { org: { value: Organization } };
    try {
        return await apiClient.patch(`/org/${state.org.value.id}/org_units/${data.id}`, data);
    } catch (e) {
        return thunkAPI.rejectWithValue(e as ErrorDetail);
    }
});

const INITIAL_STATE = {
    entities: [],
    status: 'idle' as OrgUnitsState['status'],
    error: '',
};

// noinspection JSUnusedGlobalSymbols
const org_unitSlice = createSlice({
    name: 'org_units',
    initialState: INITIAL_STATE satisfies OrgUnitsState as OrgUnitsState,
    reducers: {
        clearError: (state) => {
            state.error = '';
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchOrgUnits.pending, (state: OrgUnitsState) => {
                state.status = 'pending';
            })
            .addCase(fetchOrgUnits.fulfilled, (state: OrgUnitsState, action: PayloadAction<OrgUnit[]>) => {
                state.status = 'succeeded';
                state.entities = action.payload as OrgUnit[];
            })
            .addCase(fetchOrgUnits.rejected, (state: OrgUnitsState, action) => {
                state.status = 'failed';
                //state.error = action.error.message;
                console.error(action);
            })
            .addCase(createOrgUnit.fulfilled, (state: OrgUnitsState, action: PayloadAction<OrgUnit | ErrorDetail>) => {
                state.entities.push(action.payload as OrgUnit);
            })
            .addCase(deleteOrgUnit.fulfilled, (state: OrgUnitsState, action) => {
                state.entities = state.entities.filter((user: OrgUnit) => user.id !== action.payload.id);
            })
            .addCase(CLEAR_ORG_ACTION, (state: OrgUnitsState) => {
                Object.assign(state, INITIAL_STATE);
            })
            .addCase(LOGOUT_ACTION, (state: OrgUnitsState) => {
                Object.assign(state, INITIAL_STATE);
            })
            .addCase(updateOrgUnit.fulfilled, (state: OrgUnitsState, action: PayloadAction<OrgUnit | ErrorDetail>) => {
                const org_unit = action.payload as OrgUnit;
                const index = state.entities.findIndex((r) => r.id === org_unit.id);
                Object.assign(state.entities[index], org_unit);
                state.entities = [...state.entities];
            });
    },
});

export const selectOrgUnits = (state: RootState) => state.orgUnits.entities;
export const selectOrgUnitStatus = (state: RootState) => state.orgUnits.status;

export default org_unitSlice.reducer;
