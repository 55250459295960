import OneToOneFormItem from 'components/forms/common/OneToOneFormItem';
import type { OrgUnit, TemplateNativeField } from 'types';

const handleOrgUnit = (orgUnit: OrgUnit, templateField: TemplateNativeField, onChange: (value: any) => void) => {
    switch (templateField.native_field.name) {
        case 'org_unit':
            return (
                <OneToOneFormItem
                    templateField={templateField}
                    objectRef={orgUnit.org_unit}
                    setObjectRef={onChange}
                    excluded={[orgUnit.id]}
                />
            );
    }
    return undefined;
};

export default handleOrgUnit;
