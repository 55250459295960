import { useEffect } from 'react';

import apiClient from 'api';
import { Position } from 'types';
import { useAppDispatch, useAppSelector } from 'store';
import { nameSort } from 'utils';

import { fetchPositions, selectPositions, selectPositionStatus } from 'features/positionSlice';

export function usePositions(): Position[] | null {
    const dispatch = useAppDispatch();
    const positions = useAppSelector(selectPositions);
    const positionStatus = useAppSelector(selectPositionStatus);
    const token = apiClient.token;

    useEffect(() => {
        if (token && positionStatus === 'idle') {
            dispatch(fetchPositions());
        }
    }, [token, positionStatus, dispatch]);

    return positionStatus === 'succeeded' ? [...positions].sort(nameSort) : null;
}
