import apiClient from 'api';
import type { ObjectRef, Organization } from 'types';

export type GraphData = {
    nodes: ObjectRef[];
    links: {
        label: string;
        source: ObjectRef;
        target: ObjectRef;
    }[];
};

export async function graph(org: Organization, team_id: string, graph_id: string) {
    return apiClient.get<GraphData>(`/org/${org.id}/teams/${team_id}/graphs/${graph_id}`);
}
