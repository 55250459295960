import MarkdownDiv from 'components/MarkdownDiv';
import { useFocusRef } from 'hooks/focus';
import React, { useEffect } from 'react';
import { Form, Select } from 'antd';

import { selectOptions } from 'utils';
import type { Template } from 'types';
import { useTemplates } from 'hooks/templates';

const TemplateFormItem = (props: {
    objectType: Template['type'];
    templateId?: string;
    setTemplate: (templateId: string) => void;
    useDescription?: boolean;
}) => {
    const ref = useFocusRef();
    const { templateId, setTemplate } = props;
    const templates = useTemplates(props.objectType);
    const template =
        templateId && templates && props.useDescription ? templates.find((t) => t.id === templateId) : null;
    const options = selectOptions(templates);

    useEffect(() => {
        if (templates && templates.length === 1 && !templateId) {
            setTemplate(templates[0].id);
        }
    }, [templateId, setTemplate, templates]);

    return (
        <Form.Item>
            <Form.Item
                className={'form-control'}
                label={'What template do you want to use?'}
                rules={[{ required: true }]}
                required={true}
            >
                <Select
                    ref={ref}
                    placeholder="Please select"
                    value={props.templateId}
                    onChange={props.setTemplate}
                    options={options}
                    autoFocus={true}
                />
            </Form.Item>
            {template?.description ? <MarkdownDiv markdown={template.description} /> : null}
        </Form.Item>
    );
};

export default TemplateFormItem;
