import { type ReactNode, useEffect, useState } from 'react';
import * as d3 from 'd3';

import type { Datum, GTreeData, GTreeGroupData, GTreeNodeData } from 'pages/orgUnitTree/gtree/types';
import GTreeGraphSVG from './GTreeGraphSVG';

const NODE_WIDTH = 240;
const NODE_HEIGHT = 160;

const GTreeGraph = (props: {
    width: number;
    height: number;
    nodeWidth?: number;
    nodeHeight?: number;
    data: GTreeData;
    onNodeContent: (node: d3.HierarchyNode<Datum>) => ReactNode;
    onGroupClick: (group: GTreeGroupData) => void;
    onContextMenu?: (target: GTreeNodeData | GTreeGroupData | null, x: number, y: number) => void;
}) => {
    const [graphData, setGraphData] = useState<d3.HierarchyNode<Datum>>();

    const { data } = props;

    const nodeWidth = props.nodeWidth ? props.nodeWidth : NODE_WIDTH;
    const nodeHeight = props.nodeHeight ? props.nodeHeight : NODE_HEIGHT;

    useEffect(() => {
        /* This is an O(n) algorithm so we don't need a web worker */
        if (data.roots.length) {
            /* FIXME: need a graph select for all roots */
            const root = d3.hierarchy(data.roots[0]) as unknown as d3.HierarchyNode<unknown>;
            const tree = d3.tree().nodeSize([nodeWidth * 2, nodeHeight * 2]);
            tree(root);
            setGraphData(root as d3.HierarchyNode<Datum>);
        }
    }, [data, nodeWidth, nodeHeight]);

    return graphData ? (
        <GTreeGraphSVG
            width={props.width}
            height={props.height}
            nodeWidth={nodeWidth}
            nodeHeight={nodeHeight}
            graphData={graphData}
            groups={props.data.groups}
            onNodeContent={props.onNodeContent}
            onGroupClick={props.onGroupClick}
            onContextMenu={props.onContextMenu}
        />
    ) : null;
};

export default GTreeGraph;
