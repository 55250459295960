import apiClient from 'api';
import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'store';
import { fetchZones, selectZones, selectZoneStatus } from 'features/zoneSlice';

import type { Zone } from 'types';
import { nameSort } from 'utils';

export function useZones(): Zone[] {
    const dispatch = useAppDispatch();
    const zones = useAppSelector(selectZones);
    const zoneStatus = useAppSelector(selectZoneStatus);
    const token = apiClient.token;

    useEffect(() => {
        if (token && zoneStatus === 'idle') {
            dispatch(fetchZones() as any);
        }
    }, [token, zoneStatus, dispatch]);

    return [...zones].sort(nameSort);
}

export function useZone(zoneId: string | undefined) {
    const zones = useZones();
    return zones.find((zone) => zone.id === zoneId);
}
