import { BaseLabel, TeamLabel, TeamObjectLabel } from 'components/forms/common/labels';
import React from 'react';
import { SearchResult } from 'types';

const BaseSearchLabel = (props: { result: SearchResult }) => {
    return <BaseLabel name={props.result.name} />;
};

const TeamSearchLabel = (props: { result: SearchResult }) => {
    return <TeamLabel {...props.result} />;
};

const TeamObjectSearchLabel = (props: { result: SearchResult }) => {
    return <TeamObjectLabel {...props.result} />;
};

const SearchLabel = (props: { result: SearchResult }) => {
    switch (props.result.type) {
        case 'team':
            return <TeamSearchLabel result={props.result} />;
        case 'decision':
        case 'objective':
        case 'role':
            return <TeamObjectSearchLabel result={props.result} />;
    }
    return <BaseSearchLabel result={props.result} />;
};

export default SearchLabel;
