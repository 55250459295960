import { CreateEditTemplateCustomField, CreateEditTemplateField, CreateEditTemplateNativeField } from 'types';
import CreateEditModal from 'components/steps/common/CreateEditModal';

import { isCreateEditNativeField } from './utils';
import TemplateCustomFieldForm from './TemplateCustomFieldForm';
import TemplateNativeFieldForm from './TemplateNativeFieldForm';

const CreateEditTemplateFieldModal = (props: {
    templateField?: CreateEditTemplateField;
    open: boolean;
    onSave: (templateField: CreateEditTemplateField) => void;
    onCancel?: () => void;
    excludedFieldIds?: string[];
}) => {
    const title = props.templateField ? 'Edit Template Field' : 'Add Template Field';
    const isNative = !!(props.templateField && isCreateEditNativeField(props.templateField));

    return (
        <CreateEditModal title={title} open={props.open} onCancel={() => props.onCancel?.()} width={500}>
            {isNative ? (
                <TemplateNativeFieldForm
                    templateField={props.templateField as CreateEditTemplateNativeField}
                    onFinish={props.onSave}
                    onCancel={() => props.onCancel?.()}
                    excludedFieldIds={props.excludedFieldIds}
                />
            ) : (
                <TemplateCustomFieldForm
                    templateField={props.templateField as CreateEditTemplateCustomField}
                    onFinish={props.onSave}
                    onCancel={() => props.onCancel?.()}
                    excludedFieldIds={props.excludedFieldIds}
                />
            )}
        </CreateEditModal>
    );
};

export default CreateEditTemplateFieldModal;
