import * as d3 from 'd3';
import type { MouseEvent, ReactNode } from 'react';
import type { Datum, GTreeNodeData } from './types';
import styles from './styles.module.scss';

const GTreeNode = (props: {
    node: d3.HierarchyNode<Datum>;
    width: number;
    height: number;
    onNodeContent: (node: d3.HierarchyNode<Datum>) => ReactNode;
    onContextMenu?: (node: GTreeNodeData, x: number, y: number) => void;
}) => {
    const x = props.node.x ? props.node.x : 0;
    const y = props.node.y ? props.node.y : 0;

    const onContextMenu = (event: MouseEvent) => {
        if (props.onContextMenu) {
            event.preventDefault();
            event.stopPropagation();
            props.onContextMenu(props.node.data, event.clientX, event.clientY);
        }
    };

    return (
        <foreignObject
            className={`${styles.foreignObject} node`}
            width={props.width}
            height={props.height}
            x={x - props.width / 2}
            y={y - props.node.height / 2}
            onContextMenu={onContextMenu}
        >
            {props.onNodeContent(props.node)}
        </foreignObject>
    );
};

export default GTreeNode;
