import { useNavigate } from 'react-router-dom';
import { App } from 'antd';

import { Decision, PlatformDispatch, PlatformError } from 'types';
import { isDefined, isUndefined } from 'utils';

import { AppDispatch, useAppDispatch } from 'store';
import { deleteDecision } from 'features/decisionSlice';
import BaseDeleteButton from 'components/buttons/BaseDeleteButton';
import Show from 'components/Show';
import { useDecisionDeletePermission } from 'hooks/permissions';

const DeleteDecisionButton = (props: {
    decisionId: string;
    onDelete?: (decision: Decision) => void;
    navigate?: string | boolean;
}) => {
    const { message } = App.useApp();
    const dispatch: AppDispatch = useAppDispatch();
    const navigate = useNavigate();

    async function onConfirm() {
        const res = (await dispatch(deleteDecision(props.decisionId) as any)) as PlatformDispatch<Decision>;
        if (res.error) {
            message.error((res.payload as PlatformError).message);
            return;
        }

        props.onDelete?.(res.payload as Decision);
        if (isDefined(props.navigate) && props.navigate) {
            navigate(props.navigate as string, { replace: true });
        } else if (isUndefined(props.navigate)) {
            navigate('/decisions', { replace: true });
        }
        message.success('Your decision was successfully deleted.');
    }

    return (
        <Show permission={useDecisionDeletePermission(props.decisionId)}>
            <BaseDeleteButton objectType={'decision'} onConfirm={onConfirm} />
        </Show>
    );
};

export default DeleteDecisionButton;
