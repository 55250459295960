import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Card, Col, Row } from 'antd';

import { ObjectRef, TemplatedObject } from 'types';
import MainContent from 'components/layout/MainContent';
import ObjectDrawer from 'components/drawers/ObjectDrawer';
import { useHash } from 'hooks/hash';

import { useTeam } from 'hooks/teams';
import { useRoles } from 'hooks/roles';

import TeamMeetingProvider from 'providers/TeamMeetingProvider';
import { parseHash } from 'utils';

import TeamDetailCard from './cards/TeamDetailCard';
import TeamGraphCard from './cards/TeamGraphCard';

import styles from './styles.module.scss';
import TeamMeetingsRow from './TeamMeetingsRow';

const TeamDetailPage = () => {
    useRoles();
    const [selected, setSelected] = useState<ObjectRef>();

    const { teamId } = useParams();
    const { hash, removeHash } = useHash();

    const team = useTeam(teamId);

    useEffect(() => {
        const ref = parseHash(hash);
        if (ref) {
            setSelected(ref);
            removeHash();
        }
    }, [hash, removeHash]);

    function onClick(obj: TemplatedObject) {
        setSelected(obj);
    }

    if (!team) {
        return null;
    }

    return (
        <TeamMeetingProvider team={team}>
            <MainContent>
                <Row gutter={16} className={styles.TeamRow}>
                    <Col xs={24} md={12}>
                        <TeamGraphCard team={team} onClick={onClick} />
                    </Col>
                    <Col xs={24} md={12}>
                        <Card className={styles.TeamDetails}>
                            <TeamDetailCard team={team} onClick={onClick} />
                        </Card>
                    </Col>
                </Row>
                <TeamMeetingsRow team={team} />

                {selected ? (
                    <ObjectDrawer
                        objectId={selected.id}
                        objectType={selected.type}
                        onClose={() => setSelected(undefined)}
                    />
                ) : null}
            </MainContent>
        </TeamMeetingProvider>
    );
};

export default TeamDetailPage;
