import React from 'react';
import { Button, Flex } from 'antd';
import styles from 'components/steps/common/styles.module.scss';

const CreateEditStepsFooter = (props: {
    actionText?: string;
    valid: boolean;
    loading?: boolean;
    onNext: () => void;
    onPrevious?: () => void;
    onCancel?: () => void;
}) => {
    return (
        <Flex gap={'small'} className={styles.footer} justify={'end'} style={{ flexDirection: 'row-reverse' }}>
            <Button
                className={styles.button}
                type="primary"
                onClick={props.onNext}
                disabled={!props.valid || props.loading}
                loading={props.loading}
            >
                {props.actionText ? props.actionText : 'Next'}
            </Button>
            {!!props.onPrevious && (
                <Button className={styles.button} onClick={props.onPrevious} disabled={props.loading}>
                    Previous
                </Button>
            )}
            {!!props.onCancel && (
                <Button className={styles.button} type={'link'} onClick={props.onCancel} disabled={props.loading}>
                    Cancel
                </Button>
            )}
        </Flex>
    );
};

export default CreateEditStepsFooter;
