// noinspection JSUnusedGlobalSymbols

import { useCurrentUser } from 'hooks/useCurrentUser';
import { useTeamRoles } from 'hooks/roles';
import { isPersonAdminOrMore, isPersonPower, isPersonPowerOrMore, isPersonRegular } from 'utils';

/* User is (a) admin/system or (b) regular or power AND has a role on the team */
function useTeamRegular(teamId?: string) {
    const currentUser = useCurrentUser();
    const hasRoleOnTeam = useHasRoleOnTeam(teamId);

    if (teamId) {
        return (
            isPersonAdminOrMore(currentUser) ||
            (isPersonPower(currentUser) && hasRoleOnTeam) ||
            (isPersonRegular(currentUser) && hasRoleOnTeam)
        );
    }
    return isPersonAdminOrMore(currentUser);
}

/* User is (a) admin/system or (b) power AND has a role on the team */
function useTeamPower(teamId?: string) {
    const currentUser = useCurrentUser();
    const hasRoleOnTeam = useHasRoleOnTeam(teamId);

    if (teamId) {
        return isPersonAdminOrMore(currentUser) || (isPersonPower(currentUser) && hasRoleOnTeam);
    }
    return isPersonAdminOrMore(currentUser);
}

export function useHasRoleOnTeam(teamId?: string): boolean {
    const roles = useTeamRoles(teamId);
    const role = roles?.find((role) => role.team.id === teamId);
    return !!role;
}

export const useZoneCreatePermission = () => isPersonAdminOrMore(useCurrentUser());
export const useZoneUpdatePermission = () => isPersonPowerOrMore(useCurrentUser());
export const useZoneDeletePermission = () => isPersonAdminOrMore(useCurrentUser());

export const useTeamCreatePermission = () => isPersonAdminOrMore(useCurrentUser());
export const useTeamUpdatePermission = (teamId?: string) => useTeamPower(teamId);
export const useTeamDeletePermission = (teamId?: string) => useTeamPower(teamId);

export const useRoleCreatePermission = (teamId?: string) => useTeamPower(teamId);
export const useRoleUpdatePermission = (teamId?: string) => useTeamRegular(teamId);
export const useRoleDeletePermission = (teamId?: string) => useTeamPower(teamId);

export const useDecisionCreatePermission = (teamId?: string) => useTeamRegular(teamId);
export const useDecisionUpdatePermission = (teamId?: string) => useTeamRegular(teamId);
export const useDecisionDeletePermission = (teamId: string) => useTeamPower(teamId);

export const useObjectiveCreatePermission = (teamId?: string) => useTeamRegular(teamId);
export const useObjectiveUpdatePermission = (teamId?: string) => useTeamRegular(teamId);
export const useObjectiveDeletePermission = (teamId: string) => useTeamPower(teamId);

export const useRiskCreatePermission = (teamId?: string) => useTeamRegular(teamId);
export const useRiskUpdatePermission = (teamId?: string) => useTeamRegular(teamId);
export const useRiskDeletePermission = (teamId: string) => useTeamPower(teamId);

export const useAgreementCreatePermission = (teamId?: string) => useTeamRegular(teamId);
export const useAgreementUpdatePermission = (teamId?: string) => useTeamPower(teamId);
export const useAgreementDeletePermission = (teamId: string) => useTeamPower(teamId);
