export class ViewBox {
    /* original values */
    private readonly __cx: number;
    private readonly __cy: number;
    private readonly __width: number;
    private readonly __height: number;

    protected _cx: number;
    protected _cy: number;
    protected _scale: number;

    constructor(
        options: { cx?: number; cy?: number; width?: number; height?: number; scale?: number } = {
            cx: 0,
            cy: 0,
            width: 0,
            height: 0,
            scale: 1,
        },
    ) {
        this._cx = this.__cx = options.cx || 0;
        this._cy = this.__cy = options.cy || 0;
        this.__width = options.width || 0;
        this.__height = options.height || 0;
        this._scale = options.scale || 1;
    }

    public dup(): ViewBox {
        const viewBox = new ViewBox({ cx: this.__cx, cy: this.__cy, width: this.__width, height: this.__height });
        viewBox._cx = this._cx;
        viewBox._cy = this._cy;
        viewBox._scale = this._scale;
        return viewBox;
    }

    public reset(): ViewBox {
        return new ViewBox({ cx: this.__cx, cy: this.__cy, width: this.__width, height: this.__height });
    }

    public setScale(scale: number): ViewBox {
        const viewBox = this.dup();
        viewBox._scale = scale;
        return viewBox;
    }

    public get scale(): number {
        return this._scale;
    }

    public get canBeReset(): boolean {
        return this._scale !== 1 || this._cx !== this.__cx || this._cy !== this.__cy;
    }

    public move(dx: number, dy: number): ViewBox {
        const viewBox = this.dup();
        viewBox._cx += dx;
        viewBox._cy += dy;
        return viewBox;
    }

    private get minX(): number {
        return this._cx - (this.__width * this._scale) / 2;
    }

    private get minY(): number {
        return this._cy - (this.__height * this._scale) / 2;
    }

    toString() {
        return `${this.minX} ${this.minY} ${this.__width * this._scale} ${this.__height * this._scale}`;
    }
}

export type GTreeNodeData = {
    id: string;
    obj: any;
};

export type Datum = GTreeNodeData & {
    parentId: string | null | undefined;
    children: Datum[];
};

export type GTreeGroupData = {
    id: string;
    obj: any;
    nodes: string[];
    groups: string[];
};

export type GTreeData = {
    roots: Datum[];
    groups: GTreeGroupData[];
};
