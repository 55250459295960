import React from 'react';
import { Select } from 'antd';
import styles from './styles.module.scss';

const OPTIONS = [
    { value: 1, label: '1' },
    { value: 1.5, label: '1.5' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
];

const LineWeightSelect = (props: { value?: number; onChange?: (value: number) => void }) => {
    const value = props.value ? props.value : 1;

    return <Select className={styles.line} options={OPTIONS} value={value} onChange={props.onChange} />;
};

export default LineWeightSelect;
