import { Form, Select } from 'antd';
import { useFocusRef } from 'hooks/focus';
import { MultiSelectField, TemplateField } from 'types';
import { fieldLabel, getField } from 'utils';

const MultiSelectFormItem = (props: {
    templateField: TemplateField;
    values: string[];
    setValues: (values: string[]) => void;
    autoFocus?: boolean;
}) => {
    const ref = useFocusRef(props.autoFocus);
    const field = getField(props.templateField) as MultiSelectField;

    const options = field.config.options.map((option) => {
        return { label: option, value: option };
    });

    return (
        <Form.Item
            className={'form-control'}
            label={fieldLabel(field.name)}
            rules={[{ required: props.templateField.required, message: 'Select one or more values.' }]}
            name={field.name}
            initialValue={props.values}
        >
            <Select
                ref={ref}
                mode={'multiple'}
                options={options}
                onChange={props.setValues}
                allowClear={!props.templateField.required}
                autoFocus={props.autoFocus}
            />
        </Form.Item>
    );
};

export default MultiSelectFormItem;
