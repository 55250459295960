import { Navigate, RouteObject } from 'react-router-dom';

import { current } from 'loader';
import ErrorBoundary from 'components/ErrorBoundary';
import PageElement from 'components/layout/PageElement';

import LoginPage from 'pages/auth/login/LoginPage';
import ForgotPasswordPage from 'pages/auth/forgotPassword/ForgotPasswordPage';
import ResetPasswordPage from 'pages/auth/resetPassword/ResetPasswordPage';
import MfaPage from 'pages/auth/mfa/MfaPage';

import HomePage from 'pages/home/HomePage';
import ZoneChartPage from 'pages/zones/ZoneChartPage';

import PeopleListPage from 'pages/tables/people/PeopleListPage';
import PersonDetailPage from 'pages/tables/people/PersonDetailPage';

import ZonesListPage from 'pages/tables/zones/ZonesListPage';
import ZoneChartTitle from 'pages/zones/ZoneChartTitle';

import TeamsListPage from 'pages/teams/TeamsListPage';
import TeamDetailPage from 'pages/teams/TeamDetailPage';

import RolesListPage from 'pages/tables/roles/RolesListPage';

import ObjectivesListPage from 'pages/tables/objectives/ObjectivesListPage';
import DecisionsListPage from 'pages/tables/decisions/DecisionsListPage';
import AgreementsListPage from 'pages/tables/agreements/AgreementsListPage';

import SettingsPage from 'pages/settings/SettingsPage';
import CreateEditTemplatePage from 'pages/settings/templates/CreateEditTemplatePage';
import EditTemplatePage from 'pages/settings/templates/EditTemplatePage';
import CreateEditMeetingTypePage from 'pages/settings/meetings/CreateEditMeetingTypePage';

import AdminPage from 'pages/admin/AdminPage';

import ProfilePage from 'pages/profile/ProfilePage';
import OrganizationPage from 'pages/org/OrganizationPage';
import OrgUnitPage from 'pages/orgUnit/OrgUnitPage';
import OrgUnitTreePage from 'pages/orgUnitTree/OrgUnitTreePage';
import EditMeetingTypePage from 'pages/settings/meetings/EditMeetingTypePage';
import SettingsPageElement from './pages/settings/SettingsPageElement';

const router = [
    {
        path: '/login',
        element: <LoginPage />,
    },
    {
        path: '/forgot-password',
        element: <ForgotPasswordPage />,
    },
    {
        path: '/reset-password',
        element: <ResetPasswordPage />,
    },
    {
        path: '/mfa',
        element: <MfaPage />,
    },
    {
        path: '',
        element: <PageElement />,
        errorElement: <ErrorBoundary />,
        loader: current,
        children: [
            {
                path: '/',
                element: <HomePage />,
            },
            {
                path: '/zoneChart',
                children: [
                    {
                        path: '',
                        element: <ZoneChartPage />,
                        handle: {
                            title: 'Zone Chart',
                        },
                    },
                    {
                        path: ':zoneId',
                        element: <ZoneChartPage />,
                        handle: {
                            title: <ZoneChartTitle />,
                            className: 'zone-chart',
                        },
                    },
                ],
            },

            {
                path: '/orgChart',
                element: <OrganizationPage />,
                handle: {
                    title: 'Organization',
                },
            },

            {
                path: '/orgUnitChart',
                element: <OrgUnitPage />,
                handle: {
                    title: 'Org Unit',
                },
            },

            {
                path: '/orgUnitTree',
                element: <OrgUnitTreePage />,
                handle: {
                    title: 'Org Unit (Tree)',
                },
            },

            {
                path: '/profile',
                element: <ProfilePage />,
                handle: {
                    title: 'Profile',
                },
            },
            {
                path: '/people',
                children: [
                    {
                        path: '',
                        element: <PeopleListPage />,
                        handle: {
                            title: 'People',
                        },
                    },
                    {
                        path: ':personId',
                        element: <PersonDetailPage />,
                    },
                ],
            },

            {
                path: '/zones',
                children: [
                    {
                        path: '',
                        element: <ZonesListPage />,
                        handle: {
                            title: 'Zones',
                        },
                    },
                ],
            },

            {
                path: '/teams',
                children: [
                    {
                        path: '',
                        element: <TeamsListPage />,
                        handle: {
                            title: 'Teams',
                        },
                    },
                    {
                        path: ':teamId',
                        element: <TeamDetailPage />,
                    },
                ],
            },

            {
                path: '/roles',
                element: <RolesListPage />,
                handle: {
                    title: 'Roles',
                },
            },

            {
                path: '/objectives',
                element: <ObjectivesListPage />,
                handle: {
                    title: 'Objectives',
                },
            },

            {
                path: '/decisions',
                element: <DecisionsListPage />,
                handle: {
                    title: 'Decisions',
                },
            },

            {
                path: '/agreements',
                element: <AgreementsListPage />,
                handle: {
                    title: 'Agreements',
                },
            },

            {
                path: '/admin',
                element: <AdminPage />,
            },

            {
                path: '/settings',
                element: <SettingsPageElement />,
                children: [
                    {
                        path: '',
                        element: <SettingsPage />,
                        handle: {
                            title: 'Settings',
                        },
                    },
                    {
                        path: '/settings/templates/create',
                        element: <CreateEditTemplatePage />,
                        handle: {
                            title: 'Create Template',
                        },
                    },
                    {
                        path: '/settings/templates/:templateId',
                        element: <EditTemplatePage />,
                    },
                    {
                        path: 'create-meeting-type',
                        element: <CreateEditMeetingTypePage />,
                        handle: {
                            title: 'Create A New Meeting Type',
                        },
                    },
                    {
                        path: '/settings/meeting-types/:meetingTypeId',
                        element: <EditMeetingTypePage />,
                    },
                ],
            },

            {
                path: '*',
                element: <Navigate to={'/zoneChart'} replace />,
            },
        ],
    },
] as RouteObject[];

export default router;
