import { useOrgUnits } from 'hooks/orgUnits';
import { usePositions } from 'hooks/positions';
import OrgUnitTreeGraph from './OrgUnitTreeGraph';

const OrgUnitTreePage = () => {
    const positions = usePositions();
    const orgUnits = useOrgUnits();

    return positions !== null && orgUnits !== null ? (
        <OrgUnitTreeGraph positions={positions} orgUnits={orgUnits} />
    ) : null;
};

export default OrgUnitTreePage;
