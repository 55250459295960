import { Select } from 'antd';
import styles from './styles.module.scss';

const MarkerSelect = (props: { value?: string; onChange?: (value: string) => void }) => {
    const value = props.value ? props.value : 'none';
    return (
        <Select
            className={styles.marker}
            value={value}
            onChange={props.onChange}
            options={[
                { value: 'none', label: 'None' },
                { value: 'arrow', label: 'Arrow' },
                { value: 'dot', label: 'Dot' },
            ]}
        />
    );
};

export default MarkerSelect;
