import { Flex, Space, Typography } from 'antd';
import CreateOrgUnitButton from 'components/buttons/CreateOrgUnitButton';
import CreatePositionButton from 'components/buttons/CreatePositionButton';
import { useOrgUnits } from 'hooks/orgUnits';
import { usePositions } from 'hooks/positions';

const OrgUnitNoData = () => {
    const positions = usePositions();
    const org_units = useOrgUnits();

    return (
        <Flex style={{ height: '100%' }} vertical justify={'center'}>
            <Space direction={'vertical'} align={'center'} size={'large'}>
                <Flex vertical align={'center'}>
                    <Typography.Paragraph style={{ fontWeight: 'bold', fontSize: '1.5rem', margin: 0 }}>
                        Not Enough Data Yet!
                    </Typography.Paragraph>
                    {org_units !== null ? (
                        <Typography.Paragraph style={{ margin: 0 }}>
                            {org_units.length} Org Unit(s)
                        </Typography.Paragraph>
                    ) : null}
                    {positions !== null ? (
                        <Typography.Paragraph style={{ margin: 0 }}>
                            {positions.length} Position(s)
                        </Typography.Paragraph>
                    ) : null}
                </Flex>
                <Space>
                    <CreateOrgUnitButton />
                    <CreatePositionButton />
                </Space>
            </Space>
        </Flex>
    );
};

export default OrgUnitNoData;
